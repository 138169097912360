import React, { useMemo } from 'react'
import { Card, Typography } from 'antd'

import { ShippingIcon } from 'src/components/Icons/ShippingIcon'
import { ShipmentRate } from 'src/types/shipments'
import { formatMoney } from 'src/utils/common'

import './ShipmentRateCard.scss'

const { Text } = Typography

interface ShipmentFeeCardProps {
  item: ShipmentRate
  onClick?: () => void
  isSelected: boolean
}

export const ShipmentRateCard = ({
  onClick, item, isSelected
}: ShipmentFeeCardProps) => {
  const cardClass = useMemo(() => {
    const classes = ['shipment-rate-card']
    if (isSelected) {
      classes.push('shipment-rate-card-active')
    }
    return classes.join(' ')
  }, [isSelected])

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <Card className={cardClass} onClick={handleClick}>
      <div className="shipment-rate-card-body">
        <ShippingIcon className="shipment-rate-card-body__icon" />
        <div className="shipment-rate-card-body__text">
          <div>
            <Text className="shipment-rate-card-body__title">
              Door-to-door
            </Text>
          </div>
          <div>
            <Text className="shipment-rate-card-body__sub-title">
              {item.label}
            </Text>
          </div>
        </div>
        <div className="shipment-rate-card-body__fee">
          {formatMoney(item.price)}
        </div>
      </div>
    </Card>
  )
}
