export enum KycStatus {
  NOT_STARTED = 'not_started',
  PENDING = 'pending',
  COMPLETED = 'completed'
}

export type KycInfo = {
  stripeAccountId?: string
  status?: KycStatus
  verifiedDate?: Date
  bankAccNo?: string
  businessType?: string
}

export type UserProfile = {
  email: string
  fullName: string
  kyc: KycInfo
}
