import React from 'react'

type SpacerProps = {
    space: number
    className?: string
}

export default function Spacer({ space, className }: SpacerProps) {
  return (
    <div className={className} style={{ marginTop: space }} />
  )
}
