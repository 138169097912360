import { AppActions } from 'src/store/rootActions'

export type MiscState = {
  stripePublishableKey: string;
};

const initialMiscState: MiscState = {
  stripePublishableKey:
    'pk_test_51JYyA4DVTPtX4uLxToRAEoc5QpCwalAsH2vlFLpMAqpCJbG5EpPayVklYnJ1CidtlpdRiRcYL6xng5iaXLTxxHlv00HwNZp6e0'
}

const miscReducer = (prevState: MiscState, action: AppActions) => {
  switch (action.type) {
  case 'UPDATE_STRIPE_PUBLISHABLE_KEY':
    return {
      ...prevState,
      stripePublishableKey: action.stripePublishableKey
    }
  default:
    return prevState
  }
}

export { miscReducer, initialMiscState }
