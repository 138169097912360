import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from 'antd'

import { useDisplay } from 'src/hooks/useDisplay'
import { ShipmentInfo, ShipmentStatus } from 'src/types/shipments'
import { formatMoney } from 'src/utils/common'
import { getShipmentStatusDescription } from 'src/utils/shipment'

import './ShipmentCard.scss'

interface ShipmentCardProps {
  shipment: ShipmentInfo
}

export const ShipmentCard = ({ shipment }: ShipmentCardProps) => {
  const navigate = useNavigate()
  const { isMobileDevice } = useDisplay()

  const vehicleList = useMemo(() => shipment.vehicles.map((vehicle, idx) => (
    <li key={idx} className="shipment-card__vehicle-info-item">
      <div className="shipment-card__vehicle-info-container">
        <div className="shipment-card__vehicle-info__detail">
          {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
          &nbsp;
        </div>
        <div className="shipment-card__vehicle-info__vin">
          (
          {vehicle.vin}
          )
        </div>
      </div>
    </li>
  )), [shipment])

  const renderStatus = () => {
    switch (shipment.status) {
    case ShipmentStatus.PICKED_UP:
    case ShipmentStatus.COMPLETED:
    case ShipmentStatus.CANCELED:
      return (
        <div className="shipment-card__status-container">
          <div className="shipment-card__status-container__status">
            {getShipmentStatusDescription(shipment)}
          </div>
          <div className="shipment-card__status-container__sub-status">
            on
            {' '}
            {shipment.lastStatusUpdatedDate.toLocaleDateString()}
          </div>
        </div>
      )

    case ShipmentStatus.NOT_STARTED:
    case ShipmentStatus.CREATED:
    case ShipmentStatus.DRIVER_ASSIGNED:
    default:
      return (
        <div className="shipment-card__status-container">
          <div className="shipment-card__status-container__status">{getShipmentStatusDescription(shipment)}</div>
          <div className="shipment-card__status-container__sub-status">
            Estimated Pickup date:
            {' '}
            {shipment.shippingDate.toLocaleDateString()}
          </div>
        </div>
      )
    }
  }

  const cardClass = useMemo(() => {
    const classes = ['shipment-card']
    switch (shipment.status) {
    case ShipmentStatus.COMPLETED:
      classes.push('shipment-card--success')
      break
    case ShipmentStatus.CANCELED:
      classes.push('shipment-card--warning')
      break
    case ShipmentStatus.CREATED:
    case ShipmentStatus.DRIVER_ASSIGNED:
    case ShipmentStatus.PICKED_UP:
    default:
      classes.push('shipment-card--info')
      break
    }
    return classes.join(' ')
  }, [shipment.status])

  const handleClick = () => {
    navigate(`/shipments/${shipment.shipmentId}`)
  }

  return (
    <Card
      className={cardClass}
      title={(
        <>
          <div className="shipment-card__title">
            <div>
              <ul className="shipment-card__title__vehicle-list">

                { vehicleList }

              </ul>
            </div>
            <div className="shipment-card__title__shipping-cost">
              {
                isMobileDevice && (
                  <span className="shipment-card__title__shipping-cost__label">
                    Shipping cost:
                    {' '}
                  </span>
                )
              }
              { formatMoney(shipment.price) }
            </div>
          </div>
          {/* <div className="shipment-card__subtitle"> */}
          {/*  Requested date: */}
          {/*  {' '} */}
          {/*  { shipment.creationDate.toLocaleDateString() } */}
          {/* </div> */}
        </>
      )}
      onClick={handleClick}
    >
      <div className="shipment-card__body">
        { renderStatus() }
      </div>
    </Card>
  )
}
