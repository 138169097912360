import React, {
  useContext, useEffect, useState
} from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import {
  Col, Form, Row, Typography
} from 'antd'

import { Button } from 'src/components/Button'
import { CheckoutModal } from 'src/components/Modal'
import { Spacer } from 'src/components/Spacer'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useNewShipment } from 'src/hooks/useNewShipment'
import { EnumNewShipmentStep } from 'src/router/types'
import { store } from 'src/store/store'
import { USE_STRIPE } from 'src/utils/constants'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { fetchStripePublicKey } from 'src/utils/stripeHelpers'

import './NewShipmentPayment.scss'

export const NewShipmentPayment = () => {
  const { navigateToStep } = useNewShipment()
  const globalStore = useContext(store)
  const { openMessage } = useMessage()
  const [isLoading, setIsLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false)
  const [total, setTotal] = useState(0)
  const [clientSecret, setClientSecret] = useState('')

  const {
    shipmentId
  } = globalStore.state.createShipment

  useEffect(() => {
    initialize()
  }, [])

  const initialize = async () => {
    setIsLoading(true)
    const stripePublishableKey = await fetchStripePublicKey(true)
    if (stripePublishableKey) {
      globalStore.dispatch({
        type: 'UPDATE_STRIPE_PUBLISHABLE_KEY',
        stripePublishableKey
      })
      await initializePaymentSheet(stripePublishableKey)
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'No publishable stripe key found! Aborting! Payments WILL NOT WORK!'
      )
    }
    setIsLoading(false)
  }

  const initializePaymentSheet = async (stripePublishableKey: string) => {
    const {
      paymentIntent, ephemeralKey, customer, total
    } = await fetchPaymentSheetParams(true)
  }

  const fetchPaymentSheetParams = async (
    retry: boolean = false
  ): Promise<{
    paymentIntent: string;
    ephemeralKey: string;
    customer: string;
    total: number;
  }> => {
    let sheetResponse
    try {
      sheetResponse = await (
        await authApiAxiosWithRefresh()
      ).post(
        '/payment/shipment/payment-sheet',
        {
          shipmentId
        },
        {
          headers: { 'content-type': 'application/json' }
        }
      )
      const {
        paymentIntent, ephemeralKey, customer, total
      } = sheetResponse.data
      setClientSecret(paymentIntent)
      setTotal(total)

      return {
        paymentIntent,
        ephemeralKey,
        customer,
        total
      }
    } catch (err) {
      if (retry) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch payment sheet params! RETRYING!', err)
        return fetchPaymentSheetParams(false)
      }
      // eslint-disable-next-line no-console
      console.error('Failed to fetch payment sheet params!', err)
      return {
        paymentIntent: '',
        ephemeralKey: '',
        customer: '',
        total: 0
      }
    }
  }

  const renderFee = (fee: number) => (isLoading ? (
    <LoadingOutlined className="loading-icon" />
  ) : (
    `$${fee.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || 'Unknown'}`
  ))

  const handlePaymentSuccess = async () => {
    setCheckoutModalOpen(false)
    try {
      setSubmitting(true)
      await (
        await authApiAxiosWithRefresh()
      ).post('/shipment/store', { shipmentId })

      globalStore.dispatch({
        type: 'UPDATE_SHIPMENT_COMPLETED',
        complete: true
      })
      // Go to the confirmation page
      setSubmitting(false)
      navigateToStep({ step: EnumNewShipmentStep.OrderConfirm })
    } catch (err) {
      openMessage(
        {
          text1: 'Paperwork generation failed.',
          text2: 'Please try again soon',
          type: EnumMessageType.ERROR
        }
      )
    }
  }

  const handlePaymentFailed = () => {

  }

  return (
    <Row className="new-shipment-payment-page">
      <Col className="new-shipment-payment-page__content-wrapper" span={24} offset={0}>
        <CheckoutModal
          open={checkoutModalOpen}
          amount={total}
          billingDetail={{}}
          clientSecret={clientSecret}
          onClose={() => setCheckoutModalOpen(false)}
          onSuccess={handlePaymentSuccess}
          onFailed={handlePaymentFailed}
        />
        <Typography.Title level={4} className="new-shipment-payment-page__title">
          Checkout
        </Typography.Title>
        <Spacer space={32} />
        <Spacer space={44} />
        <div className="new-shipment-payment-page__fee-wrapper">
          <div className="total-fee__title">TOTAL</div>
          <div className="total-fee__amount">
            {
              USE_STRIPE
                ? renderFee(total)
                : '$0.00'
            }
          </div>
        </div>
        <Spacer space={32} />
        <div className="new-shipment-payment-page__actions">
          <Button
            type="primary"
            color="secondary"
            block
            onClick={() => setCheckoutModalOpen(true)}
            loading={isLoading || submitting}
            disabled={!!!clientSecret}
          >
            Pay with Credit Card
          </Button>

        </div>
        <Spacer space={44} />
      </Col>
    </Row>
  )
}
