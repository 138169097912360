import { Modal, Typography } from 'antd'

import { Button } from 'src/components/Button'
import { Spacer } from 'src/components/Spacer'

import './MigrateModal.scss'

interface MigrateModalProps {
  open: boolean
  onOk: () => void
  onCancel: () => void
}

export default function MigrateModal({
  open, onOk, onCancel
}: MigrateModalProps) {
  return (
    <Modal
      className="migrate-modal"
      centered
      width={400}
      footer={null}
      open={open}
      closable={false}
    >
      <div className="migrate-modal__content">
        <Typography.Title level={3} className="migrate-modal__title">Migrate?</Typography.Title>
        <div className="text-center">
          Would you like to migrate all available local transactions to this account?
        </div>
        <Spacer space={24} />
        <div className="migrate-modal__actions">
          <Button className="migrate-modal__cancel-btn" type="primary" color="secondary" onClick={onCancel}>
            Ignore
          </Button>
          <Button className="migrate-modal__ok-btn" type="primary" color="primary" onClick={onOk}>
            Migrate
          </Button>
        </div>
      </div>
    </Modal>
  )
}
