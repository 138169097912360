import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Col, Row
} from 'antd'

import { HeaderLayout } from 'src/components/HeaderLayout'
import { Spacer } from 'src/components/Spacer'
import TransactionDetailActions from 'src/components/Transaction/TransactionDetailActions'
import TransactionDetailInfo from 'src/components/Transaction/TransactionDetailInfo'
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumRoute } from 'src/router/types'
import { TransactionInfo, TransactionInfoRaw } from 'src/types/transactions'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { getUserInfoInTransaction, transactionRawToTransactionInfo } from 'src/utils/transaction'

import './TransactionDetail.scss'

export default function TransactionDetail() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { currentUser } = useAuthen()
  const [transaction, setTransaction] = useState<TransactionInfo|null>(null)

  useEffect(() => {
    loadTransaction()
  }, [])

  const loadTransaction = () => {
    authApiAxiosWithRefresh()
      .then((axiosInst) => axiosInst.get(
        `/transaction/retrieve/${id}`
      ))
      .then((res) => {
        setTransaction(transactionRawToTransactionInfo(res.data as TransactionInfoRaw))
      })
  }

  const role = useMemo(() => {
    if (transaction && currentUser?.uid) {
      // return transaction.mainUser.role === 'selfTransfer'
      //   ? 'Self-Transfer'
      //   : properCase(transaction.mainUser.role)
      return getUserInfoInTransaction(currentUser.uid, transaction)?.role
    }
    return undefined
  }, [transaction])

  const handleGoBack = () => {
    navigate(EnumRoute.Account)
  }

  return (
    <HeaderLayout hasGoBack title="Transaction" subtitle="Review" onGoBack={handleGoBack}>

      <Row className="transaction-detail-page">
        <Col span={20} offset={2}>
          {transaction && role && (
            <>
              <TransactionDetailInfo transaction={transaction} />
              <Spacer space={24} />
              <TransactionDetailActions
                transaction={transaction}
                onUpdateTransaction={(transaction) => setTransaction(transaction)}
                userRole={role}
                onRefresh={loadTransaction}
              />
            </>
          )}
        </Col>
      </Row>
    </HeaderLayout>
  )
}
