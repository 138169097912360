import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Typography } from 'antd'
import classNames from 'classnames'

import { EnumRoute } from 'src/router/types'

import { AnonymousTransactionCardProps, NoTransactionCardProps } from './types'

import './AnonymousTransactionCard.scss'

export default function AnonymousTransactionCard({ isPending, transactionCount }: AnonymousTransactionCardProps) {
  const [isFocus, setIsFocus] = useState(false)
  const navigate = useNavigate()
  const title = useMemo(() => (isPending || !transactionCount ? 'Sign In' : (
    <>
      <div className="anonymous-transaction-card__title">Sign In</div>
      <div className="anonymous-transaction-card__subtitle">To view your backed up transaction history.</div>
    </>
  )
  ), [isPending, transactionCount])
  const description = useMemo(() => (isPending ? (
    <>
      <div>To view your backed up</div>
      <div>pending transactions</div>
    </>
  ) : (
    !!transactionCount ? (
      <div>Tap here!</div>
    ) : (
      <>
        <div>To view your backed up</div>
        <div>transaction history</div>
      </>
    )
  )), [isPending, transactionCount])

  const handleMouseDown = () => {
    setIsFocus(true)
  }
  const handleMouseUp = () => {
    setIsFocus(false)
  }

  const handleClick = useCallback(() => {
    setTimeout(() => {
      if (navigate) {
        navigate(EnumRoute.SignIn)
      }
    }, 100)
  }, [navigate])

  return (
    <Card
      className={classNames('anonymous-transaction-card', { 'anonymous-transaction-card-v2': !isPending && !!transactionCount })}
      data-is-focus={isFocus}
      title={title}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onClick={handleClick}
    >
      <div>
        {description}
      </div>
    </Card>
  )
}
