/* eslint-disable */
// @ts-nocheck
import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged, setPersistence
} from 'firebase/auth';

import {
  apiKey,
  appId,
  authDomain,
  measurementId,
  messageSenderId,
  projectId,
  storageBucket,
} from 'src/utils/constants';

// Optionally import the services that you want to use
// import "firebase/auth";
// import "firebase/database";
// import "firebase/firestore";
// import "firebase/functions";
// import "firebase/storage";

// When we want to test this out using the local firebase:
// https://stackoverflow.com/questions/65512580/expo-does-not-work-with-firebase-emulator

// TODO: Move to env vars
// https://docs.expo.io/guides/environment-variables/#using-a-dotenv-file
// Initialize Firebase
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey,
  authDomain,
  //   databaseURL: databaseUrl,
  projectId,
  storageBucket,
  messagingSenderId: messageSenderId,
  appId,
  measurementId,
};
// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig, 'kuaay');
// TODO: Use expo-firebase-analytics
// firebase.analytics();


const firebaseAuth = getAuth(firebaseApp);
firebaseAuth.setPersistence(browserLocalPersistence)
// NOTE: We can use this to test email/password logins and whatnot
//       but the emulator does not support Facebook login :(
// connectAuthEmulator(firebaseAuth, "http://localhost:5002", { disableWarnings: true });
// setPersistence(firebaseAuth, browserLocalPersistence);

export const getFirebaseAuth = () => getAuth(firebaseApp);
