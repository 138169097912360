import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import classNames from 'classnames'

import { EnumRoute } from 'src/router/types'

import { HeaderLayoutProps } from './HeaderLayout.types'

import './headerLayout.scss'

function HeaderLayout({
  title,
  children,
  subtitle,
  hasGoBack,
  rightItems,
  onGoBack,
  style
}: HeaderLayoutProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack()
      return
    }
    if (location.key !== 'default') {
      navigate(-1)
    } else {
      navigate(EnumRoute.Home)
    }
  }

  return (
    <div style={style} className={classNames('header-layout')}>
      <div className="top-nav">
        <div className="top-nav__left-slot">
          {
            hasGoBack && (
              <ArrowLeftOutlined onClick={handleGoBack} />
            )
          }

        </div>
        <div className="top-nav__title-wrapper">
          <div className="top-nav__title">{title}</div>
          {
            subtitle && (
              <div className="top-nav__subtitle">{subtitle}</div>
            )
          }
        </div>
        <div className="top-nav__right-slot">
          {
            rightItems && (
              rightItems()
            )
          }
        </div>
      </div>
      {
        children
      }
    </div>
  )
}

export { HeaderLayout }
