import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Col,
  notification,
  Row,
  Typography
} from 'antd'

import { Button } from 'src/components/Button'
import { Spacer } from 'src/components/Spacer'
import { EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'
import {
  EnumUserType
} from 'src/utils/types'

import './styles.scss'

const { Title, Text } = Typography

interface NewTransactionShareConfirmationProps {
  userType: EnumUserType,
  hasBeenShared?: boolean
  shareTransactionId?: string | null
  shareUrl?: string | null
}

export default function NewTransactionShareConfirmation({
  shareUrl
}: NewTransactionShareConfirmationProps) {
  const globalStore = useContext(store)
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()

  const clearAndNavigate = () => {
    // Now reset the create transaction info
    globalStore.dispatch({
      type: 'RESET_TRANSACTION_STATE'
    })
    // Clear the navigation stack and then go
    navigate(`${EnumRoute.NewTransaction}?userType=${EnumUserType.Main}`)
  }

  const handleShareClick = () => {
    navigator.clipboard.writeText(shareUrl ?? '')
    api.info({
      message: 'Copied the shared URL to the clipboard.',
      placement: 'bottom'
    })
  }

  const handleDoneClick = () => {
    clearAndNavigate()
  }

  return (
    <Row className="share-confirmation-page">
      <Col span={20} offset={2}>
        {contextHolder}
        <Title className="share-confirmation-page__title" level={4}>
          Your transaction request has been sent
        </Title>
        <Spacer space={16} />
        <div className="share-confirmation-page__text-wrapper">
          <Text>
            Your recipient will receive notification of your request here soon!
          </Text>
          <Spacer space={16} />
          <Text>
            Or share the following link with them directly:
          </Text>
          <Spacer space={16} />
          <Text
            className="share-confirmation-page__text-wrapper__share-link"
          >
            {shareUrl}
          </Text>
        </div>
        <div className="share-confirmation-page__actions">
          <Button
            type="primary"
            color="primary"
            onClick={handleShareClick}
            className="share-confirmation-page__actions__share-btn"
          >
            Copy link
          </Button>
          <Button
            type="primary"
            color="primary"
            onClick={handleDoneClick}
            className="share-confirmation-page__actions__done-btn"
          >
            Done
          </Button>
        </div>
      </Col>
    </Row>
  )
}
