import { Component, MouseEvent, ReactNode } from 'react'
import { Stripe } from '@stripe/stripe-js'

import { Button } from 'src/components/Button'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'

interface VerifyButtonProps {
    stripePromise: Promise<Stripe | null>;
    onDataReceived: (data: any) => void;
    dealerId: string | null;
  }

  interface VerifyButtonState {
    stripe: Stripe | null;
    intervalId: number | null;
    isProcessing: boolean;
    isClicked: boolean;
  }

class VerifyIdentityButton extends Component<VerifyButtonProps, VerifyButtonState> {
  constructor(props: VerifyButtonProps) {
    super(props)
    this.state = {
      stripe: null, intervalId: null, isProcessing: false, isClicked: false
    }
    // Bind handleClick to the class instance
    this.handleClick = this.handleClick.bind(this)
    this.pingBackend = this.pingBackend.bind(this)
  }

  async componentDidMount() {
    this.setState({ stripe: await this.props.stripePromise })
  }

  async handleClick(event: MouseEvent<HTMLElement>): Promise<void> {
    // Block native event handling.
    event.preventDefault()
    const { stripe } = this.state

    if (!stripe || this.state.isProcessing) {
      // Stripe.js hasn't loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return
    }

    // Clear any previous data after 1 seconds
    setTimeout(() => {
      this.props.onDataReceived(null)
    }, 1000)

    // Disable the button to prevent multiple clicks
    this.setState({ isProcessing: true, isClicked: true })

    // get client secret
    const clientSecret = await (await authApiAxiosWithRefresh()).post(`stripe-identity/create_verification_session/${this.props.dealerId}`)

    // start pingBackend
    this.setState({ intervalId: window.setTimeout(this.pingBackend, 5000) as unknown as number })

    // Show the verification modal.
    const { error } = await stripe.verifyIdentity(clientSecret.data)

    if (error) {
      console.log('Verification failed')
      // Clear the interval if there's an error
      if (this.state.intervalId) {
        clearTimeout(this.state.intervalId)
      }
      // reshow the button
      this.setState({ isProcessing: false })
    }
  }

  async pingBackend() {
    const response = await (await authApiAxiosWithRefresh()).get('stripe-identity/poll_endpoint')

    if (response.data) {
      // If status is processing keep polling
      const obj = response.data.object
      this.props.onDataReceived(response.data)

      if (obj.status === 'processing') {
        this.setState({ intervalId: window.setTimeout(this.pingBackend, 2000) as unknown as number })
      } else if (obj.last_error && obj.last_error.code === 'consent_declined') {
        this.setState({ intervalId: window.setTimeout(this.pingBackend, 2000) as unknown as number })
      } else {
        this.setState({ isProcessing: false })
      }
    } else {
      // If no response is received keep polling
      this.setState({ intervalId: window.setTimeout(this.pingBackend, 2000) as unknown as number })
    }

    if (response.status === 404) {
      clearTimeout(this.state.intervalId!)
      console.log('Endpoint Not found!')
    }
  }

  render(): ReactNode {
    const { stripe, isProcessing, isClicked } = this.state
    return (
      <Button
        className="transaction-form__btn"
        color="primary"
        type="primary"
        disabled={!stripe || isProcessing}
        onClick={this.handleClick}
      >
        {isClicked ? 'Verify Another Customer Identity' : 'Verify Customer Identity'}
      </Button>
    )
  }
}

export default VerifyIdentityButton
