import React from 'react'
import {
  Input as AntInput, InputProps
} from 'antd'
import classNames from 'classnames'

import './PasswordInput.scss'

export default function PasswordInput(props: InputProps) {
  const { className, ...restProps } = props
  return (
    <AntInput.Password
      className={classNames('kuaay-password-input', className)}
      {...restProps}
    />
  )
}
