import React, { useContext, useMemo } from 'react'
import { Col, Row, Typography } from 'antd'

import { Button } from 'src/components/Button'
import { Spacer } from 'src/components/Spacer'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { KycStatus } from 'src/types/users'
import { kycStatusOfSeller, transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'

import './styles.scss'

interface NewTransactionPaymentProtectionDisclosureProps {
  userType: EnumUserType
}

const { Title, Text } = Typography

export default function NewTransactionPaymentProtectionDisclosure({ userType }: NewTransactionPaymentProtectionDisclosureProps) {
  const {
    navigateToStep, isSecondary, hasBeenShared, isBuyer, isSeller
  } = useNewTransaction()
  const globalStore = useContext(store)
  const { documentEnabled, paymentTransfer } = globalStore.state.createTransaction
  const { enabled: paymentTransferEnabled, protectionEnabled } = paymentTransfer

  const { role: userRole } = transactionUserInfo(userType, globalStore)

  const handleClickContinue = async () => {
    const kycStatus = await kycStatusOfSeller(globalStore)

    if (userRole === EnumUserRole.Buyer) {
      if (isSecondary && paymentTransferEnabled && protectionEnabled && hasBeenShared) {
        navigateToStep({ step: EnumNewTransactionParams.KnownIssuesAgreement })
      } else if (!documentEnabled) {
        navigateToStep({ step: EnumNewTransactionParams.Complete })
      } else {
        navigateToStep({ step: EnumNewTransactionParams.SignatureApplication })
      }
    } else if (paymentTransferEnabled && kycStatus !== KycStatus.COMPLETED) {
      navigateToStep({ step: EnumNewTransactionParams.Kyc })
    } else if (!documentEnabled) {
      navigateToStep({ step: EnumNewTransactionParams.KnownIssuesDisclosure })
    } else {
      navigateToStep({ step: EnumNewTransactionParams.SpecificVehicleInfo })
    }
  }

  const disclosureContent = useMemo(
    () => (userRole === EnumUserRole.Buyer
      ? 'Seller has selected Payment Protection. Pacer will collect the payment'
    + ' and release the funds to the seller after you have verified condition of the vehicle. Please make sure you'
    + ' inspect the vehicle condition upon receiving it.'
      : 'Buyer has selected Payment Protection. Pacer will'
    + ' collect the payment and release the funds after the buyer has verified condition of the vehicle. Please make'
    + ' sure you have disclosed all issues with vehicle.'),
    [userRole]
  )

  return (
    <Row className="payment-protection-disclosure">
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        <Spacer space={8} />
        <Title className="new-transaction-application-info__title" level={4}>Payment Protection Disclosure</Title>
        <Spacer space={16} />
        <div className="payment-protection-disclosure__content">
          <Typography.Text>
            {disclosureContent}
          </Typography.Text>
        </div>
        <Spacer className="flex-grow-1" space={16} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="primary"
          onClick={handleClickContinue}
        >
          Continue
        </Button>
      </Col>
    </Row>
  )
}
