import React, { useContext, useState } from 'react'
import {
  Col, Form, Modal, Row, Typography
} from 'antd'
import classNames from 'classnames'

import { Button } from 'src/components/Button'
import { TextArea } from 'src/components/Input'
import { Spacer } from 'src/components/Spacer'
import { useDisplay } from 'src/hooks/useDisplay'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { EnumUserType } from 'src/utils/types'

import './styles.scss'

const { Text } = Typography

export default function NewTransactionKnownIssuesAgreement() {
  const {
    navigateToStep, shareTransactionId, isSecondary, userType, isBuyer
  } = useNewTransaction()
  const globalStore = useContext(store)
  const [form] = Form.useForm()
  const { largeModalWidth } = useDisplay()
  const { openMessage } = useMessage()
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [declineReasons, setDeclineReasons] = useState('')
  const { createTransaction } = globalStore.state

  const {
    paymentTransfer,
    secondaryUser,
    documentEnabled,
    askBuyerPayForTheFees
  } = globalStore.state.createTransaction

  const {
    knownIssuesDisclosure
  } = paymentTransfer.seller

  const handleClickAccept = async () => {
    globalStore.dispatch({
      type: 'UPDATE_KNOWN_ISSUES_AGREEMENT',
      isAgreed: true
    })
    if (!documentEnabled) {
      if (!isSecondary || (isBuyer && askBuyerPayForTheFees)) {
        navigateToStep({ step: EnumNewTransactionParams.Complete })
      } else {
        try {
          const res = await (
            await authApiAxiosWithRefresh()
          )
            .post('/transaction/share/continue', {
              transactionInfo: {
                ...createTransaction,
                paymentTransfer: {
                  ...paymentTransfer,
                  buyer: {
                    ...paymentTransfer.buyer,
                    knownIssuesAgreement: {
                      isAgreed: true,
                      rejectReasons: ''
                    }
                  }
                }
              },
              shareTransactionId
            })

          navigateToStep({
            step: EnumNewTransactionParams.ShareConfirmation,
            userType,
            shareUrl: encodeURIComponent(res.data.shareUrl)
          })
        } catch (e) {
          openMessage(
            {
              type: EnumMessageType.ERROR,
              text1: 'There was an error while storing your transaction',
              text2: 'Please try again soon.'
            }
          )
        }
      }
    } else if (userType === EnumUserType.Secondary) {
      navigateToStep({ step: EnumNewTransactionParams.SignatureApplication })
    } else {
      navigateToStep({ step: EnumNewTransactionParams.FeeInfo })
    }
  }

  const handleClickDecline = () => {
    setDeclineReasons('')
    setShowDeclineModal(true)
  }

  const declineKnownIssues = async () => {
    try {
      const res = await (await authApiAxiosWithRefresh()).post('/transaction/share/decline-known-issues', {
        declineReasons,
        shareTransactionId,
        buyerInfo: isSecondary ? secondaryUser : undefined
      })

      openMessage({
        text1: 'We have sent a notify to the seller.',
        type: EnumMessageType.SUCCESS
      })
      globalStore.dispatch({
        type: 'RESET_TRANSACTION_STATE'
      })
      closeDeclineModal()
      setTimeout(() => {
        navigateToStep({
          step: EnumNewTransactionParams.ShareConfirmation,
          userType,
          shareUrl: encodeURIComponent(res.data.shareUrl)
        })
      }, 200)
    } catch (e) {
      openMessage({
        text1: 'There was an error while submitting decline reasons.',
        text2: 'Please try again later',
        type: EnumMessageType.ERROR
      })
    }
  }

  const handleConfirmDecline = () => {
    form.submit()
  }

  const closeDeclineModal = () => {
    setDeclineReasons('')
    setShowDeclineModal(false)
  }

  return (
    <Row className={classNames('known-issues-agreement')}>
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        <Modal
          open={showDeclineModal}
          closable={false}
          className="payment-protection-modal"
          width={largeModalWidth}
          centered
          footer={null}
        >
          <div className="payment-protection-modal__body">
            <div>
              If you decline this “Known issues disclosure”, you can not continue this transaction.
              In the mean time, we will ask the seller to update the known issues and you can review it again.

            </div>
            <Spacer space={16} />
            <div>
              Please let the seller know the decline reasons:
            </div>
            <Spacer space={16} />
            <Form
              form={form}
              onFinish={declineKnownIssues}
            >
              <Form.Item
                name="declineReasons"
                initialValue=""
                rules={[{ required: true, message: 'Please your decline reasons.' }]}
              >
                <TextArea
                  rows={5}
                  onChange={(e) => setDeclineReasons(e.target.value)}
                  placeholder="Enter your decline reasons here"
                />

              </Form.Item>

            </Form>
            <br />
          </div>
          <div className="payment-protection-modal__actions">
            <Button
              className="transaction-form__btn"
              type="primary"
              size="small"
              color="default"
              onClick={closeDeclineModal}
            >
              Cancel
            </Button>
            <Button
              className="transaction-form__btn"
              type="primary"
              size="small"
              color="primary"
              onClick={handleConfirmDecline}
            >
              Decline
            </Button>
          </div>
        </Modal>
        <Spacer space={24} />
        <div
          className="known-issues-agreement__form kuaay-form"
        >
          <Text>
            Below are the known issues disclosure from the seller:
          </Text>
          <Spacer space={24} />
          <ul className="known-issues-agreement__known-issues-list">
            {
              (knownIssuesDisclosure ?? '').split('\n').map((it) => (
                <li>
                  {it}
                </li>
              ))
            }
          </ul>
          <Spacer space={24} />
          <Text>
            By clicking on “Accept” button, you accept all the issues above and
            you can continue a payment transfer.
          </Text>
          <Spacer space={24} />

        </div>
        <Spacer className="flex-grow-1" space={24} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="secondary"
          onClick={handleClickDecline}
        >
          Decline
        </Button>
        <Spacer space={16} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="primary"
          onClick={handleClickAccept}
        >
          Accept
        </Button>
      </Col>
    </Row>
  )
}
