import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'antd'

import logo from 'src/assets/logo.png'
import logoWhite from 'src/assets/logo-white.png'
import { Button } from 'src/components/Button'
import { useAuthen } from 'src/hooks/useAuthen'
import { useTheme } from 'src/hooks/useTheme'
import { EnumRoute } from 'src/router/types'
import { EnumTheme } from 'src/utils/types'

import './styles.scss'

export default function Home() {
  const navigate = useNavigate()
  const { isAnonymous } = useAuthen()

  const { theme } = useTheme()

  const handleClickSignUp = () => {
    if (navigate) {
      navigate(EnumRoute.SignUp)
    }
  }

  const handleClickStart = () => {
    if (navigate) {
      navigate(EnumRoute.NewTransaction)
    }
  }

  const handleClickAccount = () => {
    if (navigate) {
      navigate(EnumRoute.Account)
    }
  }

  const handleGetStartedClick = () => {
    if (navigate) {
      navigate(EnumRoute.NewTransaction)
    }
  }

  return (
    <Row className="home-wrapper">
      <Col span={20} offset={2}>

        <Row>
          <Col className="kuaay-logo" sm={24}>
            <img alt="Pacer Logo" src={theme === EnumTheme.Dark ? logoWhite : logo} />
          </Col>
        </Row>

        <Row>
          <Col className="kuaay-description" sm={24}>
            Pacer will walk everyone involved in the transaction through the
            process of gathering information, filling documents, and collecting
            the necessary signatures needed for the transaction.
          </Col>
        </Row>
        <Row>
          <Col span={18} offset={3}>
            <Button
              type="primary"
              size="large"
              block
              onClick={handleClickStart}
            >
              Get Started
            </Button>
          </Col>
          <Col span={18} offset={3}>
            {
              isAnonymous ? (
                <Button
                  className="second-btn"
                  color="secondary"
                  size="large"
                  block
                  onClick={handleClickSignUp}
                >
                  Sign Up
                </Button>
              ) : (
                <Button
                  className="second-btn"
                  color="secondary"
                  size="large"
                  block
                  onClick={handleClickAccount}
                >
                  Account
                </Button>
              )
            }
          </Col>
        </Row>
        <Row>
          <Col className="pacer-update" sm={24}>
            <b>Kuaay is now Pacer.co!</b>
            <br />
            Our team is currently working to transition our branding identity. Please bear with us as we finalize the redesign.
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
