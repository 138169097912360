import React from 'react'
import {
  Select as ASelect
} from 'antd'
import classNames from 'classnames'

import './styles.scss'

const { Option: AOption } = ASelect

export default function Option(props: any) {
  const { className } = props
  return (
    <AOption className={classNames('kuaay-option', className)} {...props} />
  )
}
