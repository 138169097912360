import React from 'react'
import { Col, Modal, Row } from 'antd'

import logo from 'src/assets/logo.png'
import logoWhite from 'src/assets/logo-white.png'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { useTheme } from 'src/hooks/useTheme'
import { EnumTheme } from 'src/utils/types'

import { AboutModalProps } from './Modal.types'

import './AboutModal.scss'

export default function TermsAndConditionsModal({ open, onClose, ...rest }: AboutModalProps) {
  const { theme } = useTheme()
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }
  return (
    <Modal className="terms-and-conditions-modal" closable={false} open={open} footer={null} {...rest}>
      <HeaderLayout title="Terms and Conditions" hasGoBack onGoBack={handleClose}>
        <Row className="terms-and-conditions-modal__content">
          <Col span={22} offset={1}>
            <Row>
              <Col className="kuaay-logo" sm={24}>
                <img alt="Pacer Logo" src={theme === EnumTheme.Dark ? logoWhite : logo} />
              </Col>
            </Row>
            <Row className="iframe-container">
              <iframe
                title="Private Policy"
                id="terms-and-conditions"
                src={`/terms-and-conditions.html?theme=${theme}`}
              />
            </Row>
          </Col>
        </Row>
      </HeaderLayout>
    </Modal>
  )
}
