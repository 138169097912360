import React from 'react'

import { Option, Select } from 'src/components/Select'
import { ODOMETER_DISCLOSURE_TYPES } from 'src/utils/constants'

export default function OdometerDisclosureSelect(props: any) {
  const renderOdometerDisclosureTypes = () => ODOMETER_DISCLOSURE_TYPES.map((odometerDisclosure) => (
    <Option key={`odometerDisclosure.${odometerDisclosure}`} value={odometerDisclosure}>{odometerDisclosure}</Option>
  ))
  return (
    <Select {...props}>
      {renderOdometerDisclosureTypes()}
    </Select>
  )
}
