import { AppActions } from 'src/store/rootActions'
import {
  PersonalInfo, TransactionInfo, TxnShipment, VehicleInfo
} from 'src/types/transactions'

import { checkVerifyTimeout } from '../../utils/transaction'

export type PreviousTransactionsState = {
  transactions: TransactionInfo[];
  hasVerifyTimeout: boolean;
};

const initialPreviousTransactionsState: PreviousTransactionsState = {
  transactions: [],
  hasVerifyTimeout: false
}

const previousTransactionsReducer = (
  prevState: PreviousTransactionsState,
  action: AppActions
): PreviousTransactionsState => {
  switch (action.type) {
  case 'RESET_PREVIOUS_TRANSACTIONS_STATE':
    return initialPreviousTransactionsState
  case 'UPDATE_PREVIOUS_TRANSACTIONS':

    return {
      ...prevState,
      transactions: action.transactions || prevState.transactions,
      hasVerifyTimeout: !!action.transactions?.some((it) => it.paymentTransfer?.enabled && checkVerifyTimeout(it))
    }
  case 'UPDATE_PREVIOUS_TRANSACTIONS_HAS_VERIFY_TIMEOUT':
    return {
      ...prevState,
      hasVerifyTimeout: action.hasVerifyTimeout
    }

  default:
    return prevState
  }
}

export {
  previousTransactionsReducer,
  initialPreviousTransactionsState
}
