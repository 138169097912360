import React, {
  useCallback, useContext, useEffect, useMemo, useState
} from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'
import { Col, Row, Typography } from 'antd'

import { Button } from 'src/components/Button'
import { Spacer } from 'src/components/Spacer'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { ALLOW_TRANSACTION_SHARING } from 'src/utils/constants'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { properCase } from 'src/utils/stringHelpers'
import { EnumShareStep, EnumUserRole, EnumUserType } from 'src/utils/types'

import './styles.scss'

interface NewTransactionSignatureProps {
  userType: EnumUserType,
  hasBeenShared?: boolean,
  shareTransactionId?: string | null
}

export default function NewTransactionSignature({
  userType
}: NewTransactionSignatureProps) {
  const [canvasRef, setCanvasRef] = useState<ReactSignatureCanvas|null>(null)
  const globalStore = useContext(store)
  const { createTransaction } = globalStore.state
  const { openMessage } = useMessage()
  const {
    shareTransactionId, hasBeenShared, navigateToStep, shareStep, userRole
  } = useNewTransaction()

  const [showStoringModal, setShowStoringModal] = React.useState(false)

  const { role, isBusiness, signature } = transactionUserInfo(userType, globalStore)
  useEffect(() => {
    if (canvasRef && signature) {
      canvasRef.fromDataURL(signature)
    }
  }, [canvasRef])

  const isEmpty = useMemo(() => !signature?.length, [signature])

  const handleEnd = useCallback(() => {
    if (canvasRef) {
      const signature = canvasRef.toDataURL()
      globalStore.dispatch({
        type:
          userType === EnumUserType.Main
            ? 'UPDATE_MAIN_USER_SIGNATURE'
            : 'UPDATE_SECONDARY_USER_SIGNATURE',
        signature
      })
    }
  }, [canvasRef])

  const handleClear = useCallback(() => {
    globalStore.dispatch({
      type:
        userType === EnumUserType.Main
          ? 'UPDATE_MAIN_USER_SIGNATURE'
          : 'UPDATE_SECONDARY_USER_SIGNATURE',
      signature: undefined
    })
    if (canvasRef) {
      canvasRef.clear()
    }
  }, [canvasRef])

  const handleContinue = () => {
    if (canvasRef?.isEmpty()) {
      return
    }
    handleEnd()
    if (!hasBeenShared) {
      // const nextStep = userType === EnumUserType.Main && role !== EnumUserRole.SelfTransfer
      const nextStep = userType === EnumUserType.Main
        ? ALLOW_TRANSACTION_SHARING
          ? EnumNewTransactionParams.NextUser
          : EnumNewTransactionParams.NeededInfo
        : EnumNewTransactionParams.FeeInfo
      console.log('nextStep: ', nextStep)
      navigateToStep({
        step: nextStep,
        userType: ALLOW_TRANSACTION_SHARING
          ? undefined
          : userType === EnumUserType.Main
            ? EnumUserType.Secondary
            : EnumUserType.Main
      })
    } else {
      // We have hit the last step in this stage of the transaction sharing so pass it back
      handleUpdateShare()
    }
  }

  const handleUpdateShare = async () => {
    if (
      shareStep === EnumShareStep.NeedsPayment
      || (userRole === EnumUserRole.Buyer && shareStep === EnumShareStep.Shared && createTransaction.askBuyerPayForTheFees)
    ) {
      navigateToStep({ step: EnumNewTransactionParams.FeeInfo })
      return
    }

    // Make sure we have an email and then go
    setShowStoringModal(true)
    // Send the transaction share info to the backend
    await (
      await authApiAxiosWithRefresh()
    )
      .post('/transaction/share/continue', {
        transactionInfo: createTransaction,
        shareTransactionId
      })
      .then((e) => {
        setShowStoringModal(false)
        // We succeeded so send the user to the "share confirmation" screen
        navigateToStep({
          step: EnumNewTransactionParams.ShareConfirmation,
          userType,
          shareUrl: encodeURIComponent(e.data.shareUrl)
        })
      })
      .catch((e) => {
        setShowStoringModal(false)
        openMessage(
          {
            type: EnumMessageType.ERROR,
            text1: 'There was an error while storing your transaction. Please try again soon.'
          }
        )
      })
  }

  const renderTitle = () => (
    <Typography.Title
      className="new-transaction-signature__title"
      level={4}
    >
      {
        // isBusiness
        //   ? 'Authorized Representative'
        //   : role === EnumUserRole.SelfTransfer
        //     ? 'User'
        //     : properCase(role) || ''
        isBusiness
          ? 'Authorized Representative'
          : properCase(role) || ''
      }
      {' '}
      Signature
    </Typography.Title>
  )

  return (
    <Row className="new-transaction-signature">
      <Col className="transaction-form-wrapper flex-col" span={24}>
        <Row>
          <Col span={20} offset={2} className="new-transaction-signature__main-section">
            {
              renderTitle()
            }
            <div
              className="new-transaction-signature__description"
            >
              With your finger sign in the box below. If you’d like to redo your
              signature press the clear button below and sign again.
            </div>
          </Col>
        </Row>

        <ReactSignatureCanvas
          ref={(ref) => setCanvasRef(ref)}
          canvasProps={{ className: 'signature-pad' }}
          onEnd={handleEnd}
          penColor={'#021eb1'}
          minWidth={3}
          maxWidth={5}
        />
        <Spacer space={24} />
        <Button
          className="transaction-form__btn new-transaction-signature__actions__continue-btn"
          type="primary"
          color="primary"
          disabled={isEmpty}
          onClick={handleContinue}
        >
          Continue
        </Button>
        <Spacer className="flex-grow-1" space={8} />
        <Button
          type="primary"
          color="secondary"
          onClick={handleClear}
          className="transaction-form__btn new-transaction-signature__actions__clear-btn"
        >
          Clear Signature
        </Button>
      </Col>
    </Row>
  )
}
