import { EnumNewShipmentStep } from 'src/router/types'
import { AppActions } from 'src/store/rootActions'
import {
  CreateShipmentVehicle,
  OperableType, ShipmentAddress, ShipmentContact, ShipmentRate, ShipmentVehicle, ShipmentVehicleType
} from 'src/types/shipments'
import { TransactionInfo } from 'src/types/transactions'
import { STORAGE_KEY, ZIP_CODE_REGEX } from 'src/utils/constants'
import { getStorageItemAsObject } from 'src/utils/localStorage'
import { EnumUserRole } from 'src/utils/types'

export type NewShipmentParams = {
  step: EnumNewShipmentStep
  transactionId?: string | null
}

type CreateShipmentState = {
  shipmentId?: string
  shippingDate?: Date
  pickupAddress: ShipmentAddress
  pickupContact: ShipmentContact
  deliveryAddress: ShipmentAddress
  deliveryContact: ShipmentContact
  enclosedTrailer: boolean
  vehicles: CreateShipmentVehicle[]
  transaction?: TransactionInfo
  price?: number
  notesToDriver?: string
  complete: boolean
  rates: ShipmentRate[]
  selectedRateId?: string
}

const initialShipmentAddress = {
  address: '',
  zip: '',
  city: '',
  state: null
}

const initialShipmentContact = {
  name: '',
  phone: '',
  email: ''
}

const initialVehicle: CreateShipmentVehicle = {
  make: '',
  model: '',
  vin: '',
  year: null,
  bodyType: null,
  operable: OperableType.OPERABLE
}

const defaultCreateShipmentState: CreateShipmentState = {
  shippingDate: undefined,
  pickupAddress: { ...initialShipmentAddress },
  pickupContact: { ...initialShipmentContact },
  deliveryAddress: { ...initialShipmentAddress },
  deliveryContact: { ...initialShipmentContact },
  enclosedTrailer: false,
  vehicles: [
    {
      ...initialVehicle
    }
  ],
  complete: false,
  rates: []
}

const loadedShipment: CreateShipmentState = getStorageItemAsObject<CreateShipmentState>(
  STORAGE_KEY.KUAAY_CREATE_SHIPMENT,
  defaultCreateShipmentState
)

if (!!loadedShipment.shippingDate) {
  loadedShipment.shippingDate = new Date(loadedShipment.shippingDate as unknown as string)
}

const initialCreateShipmentState: CreateShipmentState = { ...defaultCreateShipmentState, ...loadedShipment }

const createShipmentReducer = (
  prevState: CreateShipmentState,
  action: AppActions
): CreateShipmentState => {
  let vehicleIdx = 0
  let vehicles: CreateShipmentVehicle[] = []
  switch (action.type) {
  case 'RESET_CREATE_SHIPMENT_STATE':
    return { ...defaultCreateShipmentState }

  case 'UPDATE_SHIPMENT_TRANSACTION':
    return {
      ...prevState,
      transaction: action.transaction
    }

  case 'PARSE_SHIPMENT_INFO_FROM_TRANSACTION':
    const { vehicle: txnVehicle, mainUser, secondaryUser } = action.transaction

    const vehicle: CreateShipmentVehicle = {
      vin: txnVehicle.vin,
      make: txnVehicle.make,
      model: txnVehicle.model,
      year: txnVehicle.year ?? null,
      bodyType: txnVehicle.bodyType as ShipmentVehicleType,
      operable: OperableType.OPERABLE
    }
    let pickupContact = { ...prevState.pickupContact }
    let pickupAddress = { ...prevState.pickupAddress }
    let deliveryContact = { ...prevState.deliveryContact }
    let deliveryAddress = { ...prevState.deliveryAddress }
    let pickupUser = null
    let deliveryUser = null
    // if (mainUser.role === EnumUserRole.Seller || action.transaction.mainUser.role === EnumUserRole.SelfTransfer) {
    if (mainUser.role === EnumUserRole.Seller) {
      pickupUser = mainUser
      if (secondaryUser?.role === EnumUserRole.Buyer) {
        deliveryUser = secondaryUser
      }
    }
    if (mainUser.role === EnumUserRole.Buyer) {
      deliveryUser = mainUser
      pickupUser = secondaryUser
    }

    if (pickupUser?.zip && !ZIP_CODE_REGEX.test(`${pickupUser?.zip}`)) {
      pickupUser = null
    }

    pickupAddress = {
      zip: pickupUser?.zip ?? pickupAddress.zip,
      address: pickupUser?.address ?? pickupAddress.address,
      city: pickupUser?.city ?? pickupAddress.city,
      state: pickupUser?.state ?? pickupAddress.state
    }
    pickupContact = {
      email: pickupUser?.email ?? pickupContact.email,
      phone: pickupUser?.phone ?? pickupContact.phone,
      name: pickupUser ? `${pickupUser?.firstName} ${pickupUser?.lastName}` : pickupContact.name
    }

    deliveryAddress = {
      zip: deliveryUser?.zip ?? deliveryAddress.zip,
      address: deliveryUser?.address ?? deliveryAddress.address,
      city: deliveryUser?.city ?? deliveryAddress.city,
      state: deliveryUser?.state ?? deliveryAddress.state
    }
    deliveryContact = {
      email: deliveryUser?.email ?? deliveryContact.email,
      phone: deliveryUser?.phone ?? deliveryContact.phone,
      name: deliveryUser ? `${deliveryUser?.firstName} ${deliveryUser?.lastName}` : deliveryContact.name
    }

    return {
      ...prevState,
      transaction: action.transaction,
      vehicles: [vehicle],
      pickupAddress,
      pickupContact,
      deliveryAddress,
      deliveryContact
    }

  case 'UPDATE_SHIPMENT_ID':
    return {
      ...prevState,
      shipmentId: action.shipmentId
    }

  case 'UPDATE_SHIPMENT_PRICE':
    return {
      ...prevState,
      price: action.price
    }

  case 'RESET_QUOTE':
    return {
      ...prevState,
      rates: []
    }
  case 'UPDATE_SHIPMENT_COMPLETED':
    return {
      ...prevState,
      complete: action.complete
    }

  case 'UPDATE_SHIPMENT_VEHICLE_VIN':
    vehicleIdx = action.idx
    vehicles = [...prevState.vehicles]
    vehicles[vehicleIdx] = {
      ...vehicles[vehicleIdx],
      vin: action.vin
    }
    return {
      ...prevState,
      vehicles
    }
  case 'UPDATE_SHIPMENT_VEHICLE_YEAR':
    vehicleIdx = action.idx
    vehicles = [...prevState.vehicles]
    vehicles[vehicleIdx] = {
      ...vehicles[vehicleIdx],
      year: action.year
    }
    return {
      ...prevState,
      vehicles
    }
  case 'UPDATE_SHIPMENT_VEHICLE_MAKE':
    vehicleIdx = action.idx
    vehicles = [...prevState.vehicles]
    vehicles[vehicleIdx] = {
      ...vehicles[vehicleIdx],
      make: action.make
    }
    return {
      ...prevState,
      vehicles
    }
  case 'UPDATE_SHIPMENT_VEHICLE_MODEL':
    vehicleIdx = action.idx
    vehicles = [...prevState.vehicles]
    vehicles[vehicleIdx] = {
      ...vehicles[vehicleIdx],
      model: action.model
    }
    return {
      ...prevState,
      vehicles
    }
  case 'UPDATE_SHIPMENT_VEHICLE_BODY_TYPE':
    vehicleIdx = action.idx
    vehicles = [...prevState.vehicles]
    vehicles[vehicleIdx] = {
      ...vehicles[vehicleIdx],
      bodyType: action.bodyType
    }
    return {
      ...prevState,
      vehicles
    }

  case 'UPDATE_SHIPMENT_VEHICLE_OPERABLE':
    vehicleIdx = action.idx
    vehicles = [...prevState.vehicles]
    vehicles[vehicleIdx] = {
      ...vehicles[vehicleIdx],
      operable: action.operable
    }
    return {
      ...prevState,
      vehicles
    }
  case 'UPDATE_SHIPMENT_VEHICLE_DATA_FROM_LICENSE':
    vehicleIdx = action.idx
    vehicles = [...prevState.vehicles]
    vehicles[vehicleIdx] = {
      vin: vehicles[vehicleIdx].vin,
      operable: vehicles[vehicleIdx].operable,
      year: action.year,
      make: action.make,
      model: action.model,
      bodyType: action.bodyType
    }
    return {
      ...prevState,
      vehicles
    }

  case 'UPDATE_SHIPMENT_ADD_VEHICLE':
    return {
      ...prevState,
      vehicles: [
        ...prevState.vehicles,
        {
          ...initialVehicle
        }
      ],
      rates: []
    }

  case 'UPDATE_SHIPMENT_REMOVE_VEHICLE':
    vehicleIdx = action.idx
    vehicles = [...prevState.vehicles]
    vehicles.splice(vehicleIdx, 1)
    return {
      ...prevState,
      vehicles
    }

  case 'UPDATE_SHIPMENT_RESET_VEHICLE':
    vehicles = [{ ...initialVehicle }]
    return {
      ...prevState,
      vehicles
    }

  case 'UPDATE_SHIPMENT_DATA_FROM_QUERY':
    return {
      shippingDate: action.shippingDate,
      pickupAddress: action.pickupAddress,
      deliveryAddress: action.deliveryAddress,
      enclosedTrailer: action.enclosedTrailer,
      vehicles: action.vehicles,
      rates: action.rates,
      selectedRateId: action.selectedRateId,
      shipmentId: action.shipmentId,
      complete: false,
      deliveryContact: { ...initialShipmentContact },
      pickupContact: { ...initialShipmentContact, ...action.pickupContact },
      notesToDriver: ''
    }

  case 'UPDATE_SHIPMENT_VEHICLE':
    vehicleIdx = action.idx
    vehicles = [...prevState.vehicles]
    vehicles[vehicleIdx] = action.vehicle
    return {
      ...prevState,
      vehicles,
      rates: []
    }

  case 'UPDATE_SHIPMENT_ENCLOSED_TRAILER':
    return {
      ...prevState,
      enclosedTrailer: action.enclosedTrailer
    }
  case 'UPDATE_SHIPMENT_SHIPPING_DATE':
    return {
      ...prevState,
      shippingDate: action.shippingDate
    }
  case 'UPDATE_SHIPMENT_PICKUP_ADDRESS':
    return {
      ...prevState,
      pickupAddress: {
        ...prevState.pickupAddress,
        address: action.address
      }
    }
  case 'UPDATE_SHIPMENT_PICKUP_ZIP':
    return {
      ...prevState,
      pickupAddress: {
        ...prevState.pickupAddress,
        zip: action.zip
      }
    }
  case 'UPDATE_SHIPMENT_PICKUP_CITY':
    return {
      ...prevState,
      pickupAddress: {
        ...prevState.pickupAddress,
        city: action.city
      }
    }
  case 'UPDATE_SHIPMENT_PICKUP_STATE':
    return {
      ...prevState,
      pickupAddress: {
        ...prevState.pickupAddress,
        state: action.state
      }
    }
  case 'UPDATE_SHIPMENT_PICKUP_NAME':
    return {
      ...prevState,
      pickupContact: {
        ...prevState.pickupContact,
        name: action.name
      }
    }
  case 'UPDATE_SHIPMENT_PICKUP_PHONE':
    return {
      ...prevState,
      pickupContact: {
        ...prevState.pickupContact,
        phone: action.phone
      }
    }
  case 'UPDATE_SHIPMENT_PICKUP_EMAIL':
    return {
      ...prevState,
      pickupContact: {
        ...prevState.pickupContact,
        email: action.email
      }
    }
  case 'UPDATE_SHIPMENT_DELIVERY_ADDRESS':
    return {
      ...prevState,
      deliveryAddress: {
        ...prevState.deliveryAddress,
        address: action.address
      }
    }
  case 'UPDATE_SHIPMENT_DELIVERY_ZIP':
    return {
      ...prevState,
      deliveryAddress: {
        ...prevState.deliveryAddress,
        zip: action.zip
      }
    }
  case 'UPDATE_SHIPMENT_DELIVERY_CITY':
    return {
      ...prevState,
      deliveryAddress: {
        ...prevState.deliveryAddress,
        city: action.city
      }
    }
  case 'UPDATE_SHIPMENT_DELIVERY_STATE':
    return {
      ...prevState,
      deliveryAddress: {
        ...prevState.deliveryAddress,
        state: action.state
      }
    }
  case 'UPDATE_SHIPMENT_DELIVERY_NAME':
    return {
      ...prevState,
      deliveryContact: {
        ...prevState.deliveryContact,
        name: action.name
      }
    }
  case 'UPDATE_SHIPMENT_DELIVERY_PHONE':
    return {
      ...prevState,
      deliveryContact: {
        ...prevState.deliveryContact,
        phone: action.phone
      }
    }
  case 'UPDATE_SHIPMENT_DELIVERY_EMAIL':
    return {
      ...prevState,
      deliveryContact: {
        ...prevState.deliveryContact,
        email: action.email
      }
    }
  case 'UPDATE_SHIPMENT_NOTES_TO_DRIVER':
    return {
      ...prevState,
      notesToDriver: action.notesToDriver
    }

  case 'UPDATE_SHIPMENT_SELECTED_RATE_ID':
    return {
      ...prevState,
      selectedRateId: action.selectedRateId
    }

  case 'UPDATE_SHIPMENT_RATES':
    return {
      ...prevState,
      rates: action.rates
    }
  default:
    return prevState
  }
}

export type { CreateShipmentState }

export {
  createShipmentReducer,
  initialCreateShipmentState,
  defaultCreateShipmentState
}
