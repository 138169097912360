import React from 'react'
import {
  Checkbox as ACheckbox,
  CheckboxProps
} from 'antd'
import classNames from 'classnames'

import './styles.scss'

export default function Checkbox(props: CheckboxProps) {
  const { className, ...restProps } = props
  return (
    <ACheckbox
      className={classNames('kuaay-checkbox', className)}
      {...restProps}
    />
  )
}
