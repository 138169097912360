import mapValues from 'lodash-es/mapValues'
import omitBy from 'lodash-es/omitBy'

export const closeModalIframe = () => {
  window.parent.postMessage('close-kuaay-app-from-iframe', '*')
}

export const decodeStyles = (base64: string) => {
  try {
    return JSON.parse(atob(base64 || '{}'))
  } catch (e) {
    return {}
  }
}
export const configStyles = (styles: { [key: string]: any }) => {
  const appWrapper = window.document.getElementsByClassName('app-wrapper')
  const newStyles = window.document.createElement('style')
  if (styles?.form) {
    matchStyles(styles.form, appWrapper)
    if (styles.form.backgroundColor) {
      window.document.body.style.backgroundColor = styles.form.backgroundColor
    }
  }
  if (styles.switch) {
    updateCssProps(styles.switch, '.kuaay-switch.ant-switch.ant-switch-checked', newStyles)
  }

  if (styles?.input) {
    updateCssProps(styles.input, '.kuaay-input', newStyles)
    updateCssProps(styles.input, '.kuaay-datepicker:not(.ant-picker-disabled)', newStyles)
    if (styles?.input.borderColor) {
      newStyles.append(`.ant-picker-clear,
      .kuaay-datepicker:not(.ant-picker-disabled) .ant-picker-suffix,
      .kuaay-datepicker:not(.ant-picker-disabled) .ant-picker-dropdown,
      .ant-input-affix-wrapper-focused .anticon svg {
      color: ${styles?.input.borderColor} !important; 
      }`)
    }
  }
  // shipment-rate-card.shipment-rate-card-active
  if (styles?.quoteOptions?.default) {
    updateCssProps(styles?.quoteOptions?.default, '.shipment-rate-card', newStyles)
  }
  // shipment-rate-card.shipment-rate-card-active
  if (styles?.quoteOptions?.active) {
    updateCssProps(styles?.quoteOptions?.active, '.shipment-rate-card.shipment-rate-card-active', newStyles)
    appendSingleProperty(
      '.shipment-rate-card.shipment-rate-card-active .shipment-rate-card-body .shipment-rate-card-body__title'
      + ', .shipment-rate-card.shipment-rate-card-active .shipment-rate-card-body .shipment-rate-card-body__sub-title,'
      + ' .shipment-rate-card.shipment-rate-card-active .shipment-rate-card-body svg.shipment-rate-card-body__icon',
      newStyles,
      'color',
      styles?.quoteOptions?.active?.color
    )
  }
  if (styles?.quoteOptions?.button) {
    updateCssProps(styles?.quoteOptions?.button, '.shipment-rate-card .shipment-rate-card-body .shipment-rate-card-body__fee', newStyles)
  }
  if (styles.dropdownMenu) {
    updateCssProps(styles.dropdownMenu, '.kuaay-select:not(.ant-select-disabled) .ant-select-selector', newStyles)
  }

  if (styles.button) {
    updateCssProps(styles.button, '.kuaay-btn', newStyles)
    if (styles?.button?.borderWidth) {
      newStyles.append(`.kuaay-btn.ant-btn[disabled] {
      border-width: 0 !important; 
      }`)
    }
  }
  appWrapper[0].appendChild(newStyles)
}
const matchStyles = (styles: { [key: string]: string }, elements: HTMLCollection) => {
  for (let i = 0; i < elements.length; i += 1) {
    Object.keys(styles).forEach((key: string) => {
      // @ts-ignore
      elements[i].style[key] = styles[key]
    })
  }
}

const appendSingleProperty = (className: string, element: HTMLElement, propName: string, value?: string) => {
  if (value) {
    // @ts-ignore
    element.append(`${className} {
      ${propName}: ${value} !important; 
    }`)
  }
}
const updateCssProps = (styles: { [key: string]: string }, className: string, styleElement: any) => {
  const cssProps = mapValues(omitBy({
    'border-color': styles?.borderColor,
    'border-width': styles?.borderWidth,
    'border-style': styles?.borderStyle,
    'border-radius': styles?.borderRadius,
    'background-color': styles?.backgroundColor,
    'font-size': styles?.fontSize,
    color: styles?.color
  }, (val: string) => !val), (obj: string) => `${obj} !important`)

  styleElement.append(`${className} ${JSON.stringify(cssProps)
    .replaceAll('\"', '')
    .replaceAll(',', ';')}
    `)
}
