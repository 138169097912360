import { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { EnumNewShipmentStep, EnumRoute } from 'src/router/types'
import { NewShipmentParams } from 'src/store/reducers/createShipmentReducer'
import { store } from 'src/store/store'

import { useQuery } from './useQuery'

interface ShipmentStepArg {
  step: EnumNewShipmentStep
  transactionId?: string | null
}
export function useNewShipment() {
  const globalStore = useContext(store)
  const query = useQuery()
  const navigate = useNavigate()
  const step = useMemo(() => query.get('step') as EnumNewShipmentStep ?? EnumNewShipmentStep.MainInfo, [query])
  const transactionId = useMemo(() => query.get('transactionId'), [query])
  const navigateToStep = (arg: ShipmentStepArg) => {
    let queryString = `?step=${arg.step}`
    const lastParams: NewShipmentParams = {
      step: arg.step
    }

    if (arg.transactionId || (arg.transactionId === undefined && transactionId)) {
      queryString = `${queryString}&transactionId=${arg.transactionId ?? transactionId}`
      lastParams.transactionId = arg.transactionId ?? transactionId
    }

    globalStore.dispatch({
      type: 'UPDATE_LAST_SHIPMENT_PARAMS',
      lastParams
    })
    navigate(`${EnumRoute.NewShipping}${queryString}`)
  }

  const goPrevStep = () => {
    switch (step) {
    case EnumNewShipmentStep.Payment:
      navigateToStep({ step: EnumNewShipmentStep.MainInfo })
      break
    case EnumNewShipmentStep.OrderConfirm:
      resetNewShipmentState()
      navigate(EnumRoute.ShippingAndTracking)
      break

    case EnumNewShipmentStep.MainInfo:
    default:
      navigate(EnumRoute.ShippingAndTracking)
      break
    }
  }

  const resetNewShipmentState = () => {
    globalStore.dispatch({
      type: 'RESET_CREATE_SHIPMENT_STATE'
    })
  }

  /**
   * @TODO Keenan
   * Add a parser here for direct vehicle info and return that as part of the hook!
   */

  return {
    step,
    transactionId,
    navigateToStep,
    goPrevStep,
    resetNewShipmentState
  }
}
