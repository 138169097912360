import dayjs from 'dayjs'

import { TransactionInfo, TransactionInfoRaw } from 'src/types/transactions'

import { PaymentTransferStatus } from '../types/paymentTransfer'

import { EnumUserRole } from './types'

export function transactionRawToTransactionInfo(
  transactionRaw: TransactionInfoRaw
): TransactionInfo {
  const creationDate = transactionRaw.creationDate
    ? new Date(transactionRaw.creationDate)
    : new Date()
  const res: TransactionInfo = {
    ...transactionRaw,
    creationDate
  }
  if (res.mainUser.dateOfBirth) {
    res.mainUser.dateOfBirth = new Date(res.mainUser.dateOfBirth as unknown as string)
  }
  if (res.secondaryUser?.dateOfBirth) {
    res.secondaryUser.dateOfBirth = new Date(res.secondaryUser.dateOfBirth as unknown as string)
  }
  if (res.vehicle.purchaseDate) {
    res.vehicle.purchaseDate = new Date(res.vehicle.purchaseDate as unknown as string)
  }
  if (res.vehicle.vtExpirationDate) {
    res.vehicle.vtExpirationDate = new Date(res.vehicle.vtExpirationDate as unknown as string)
  }
  return res
}

interface UserInfo {
  email?: string
  fullName?: string
  phone?: string
  role?: EnumUserRole
}

export const getUserInfoInTransaction = (userId: string, transaction: TransactionInfo): UserInfo => {
  if (transaction.userId === userId) {
    const { mainUser } = transaction
    return {
      email: mainUser.email,
      fullName: [mainUser.firstName, mainUser.lastName].join(' '),
      phone: mainUser.phone,
      role: mainUser.role
    }
  }
  if (transaction.sharedWith?.includes(userId)) {
    const { secondaryUser } = transaction
    const nameParts = [secondaryUser?.firstName, secondaryUser?.lastName].filter((it) => !!it)
    return {
      email: secondaryUser?.email,
      fullName: nameParts.join(' '),
      phone: secondaryUser?.phone,
      role: secondaryUser?.role
    }
  }
  return {
  }
}

export const vehicleVerifiable = (transaction: TransactionInfo | TransactionInfoRaw) => transaction.paymentTransfer?.protectionEnabled
    && !transaction.paymentTransfer?.buyer?.isVerified
    && ![
      PaymentTransferStatus.COMPLETED,
      PaymentTransferStatus.FAILED]
      .includes(transaction.paymentTransfer?.status as unknown as PaymentTransferStatus)

export const checkVerifyTimeout = (transaction: TransactionInfo | TransactionInfoRaw) => {
  if (vehicleVerifiable(transaction) && transaction?.shipment?.shipmentId && transaction?.paymentTransfer?.autoVerifyDate) {
    const targetDate = dayjs(transaction?.paymentTransfer?.autoVerifyDate)
    return targetDate.toDate().getTime() > (new Date()).getTime()
  }
  return false
}
