import { AppActions } from 'src/store/rootActions'
import { ShipmentInfo } from 'src/types/shipments'

export type PrevShipmentsState = {
  shipments: ShipmentInfo[];
};

const initialPrevShipmentsState: PrevShipmentsState = {
  shipments: []
}

const prevShipmentsReducer = (
  prevState: PrevShipmentsState,
  action: AppActions
): PrevShipmentsState => {
  switch (action.type) {
  case 'RESET_SHIPMENTS_STATE':
    return initialPrevShipmentsState
  case 'UPDATE_SHIPMENTS':
    return {
      ...prevState,
      shipments: action.shipments || prevState.shipments
    }

  default:
    return prevState
  }
}

export {
  prevShipmentsReducer,
  initialPrevShipmentsState
}
