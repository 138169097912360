import React, { useEffect, useState } from 'react'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'
import { Typography } from 'antd'

import './BarcodeScanner.scss'

const { Text } = Typography

interface BarcodeScannerProps {
  handleBarcodeScanned: (err: any, result: any) => void
  stopStream?: boolean
  hideOverlay?: boolean
}

export default function BarcodeScanner(
  { handleBarcodeScanned, hideOverlay, stopStream }: BarcodeScannerProps
) {
  const [hasPermission, setHasPermission] = useState(false)

  // Function to check camera permission status
  const checkPermissionStatus = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })
      stream.getTracks().forEach((track) => track.stop()) // stop the video stream
      setHasPermission(true)
    } catch (error) {
      setHasPermission(false)
    }
  }

  useEffect(() => {
    checkPermissionStatus()
  }, [])

  if (hasPermission === null) {
    return (
      <div className="barcode-scanner">
        <Text className="barcode-scanner__info-text">Requesting camera permissions.</Text>
      </div>
    )
  }
  if (hasPermission === false) {
    return (
      <div className="barcode-scanner">
        <Text className="barcode-scanner__info-text">No access granted for camera usage.</Text>
      </div>
    )
  }

  return (
    <div className="barcode-scanner">
      <BarcodeScannerComponent
        delay={100}
        stopStream={stopStream}
        onUpdate={(err, result) => handleBarcodeScanned(err, result)}
      />
      {!hideOverlay && (
        <div className="barcode-scanner__overlay-wrapper">
          <div className="barcode-scanner__overlay-top-left" />
          <div className="barcode-scanner__overlay-bottom-left" />
          <div className="barcode-scanner__overlay-top-right" />
          <div className="barcode-scanner__overlay-bottom-right" />
        </div>
      )}
    </div>
  )
}
