import React, { useContext, useEffect, useMemo } from 'react'
import { CameraOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Col, Form, Row, Typography
} from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'

import { Button } from 'src/components/Button'
import { Checkbox } from 'src/components/Checkbox'
import { DatePicker } from 'src/components/DatePicker'
import { FuelType } from 'src/components/FuelType'
import { Input } from 'src/components/Input'
import { OdometerDisclosureSelect } from 'src/components/OdometerDisclosureSelect'
import { OdometerUnit } from 'src/components/OdometerUnit'
import { Spacer } from 'src/components/Spacer'
import { Switch } from 'src/components/Switch'
import { VehicleBodyTypeSelect } from 'src/components/VehicleBodyTypeSelect'
import { VehicleConditionSelect } from 'src/components/VehicleConditionSelect'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import {
  CONDITION_TYPES,
  FUEL_TYPES,
  NUMBER_REGEX,
  VEHICLE_BODY_TYPES,
  VEHICLE_TYPE,
  YEAR_REGEX
} from 'src/utils/constants'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { properCase } from 'src/utils/stringHelpers'
import {
  AdditionalInputProps, EnumUserRole, EnumUserType, VINInfo, VINResultsInfo
} from 'src/utils/types'
import { decodeVinWithRetry, determineBodyType, determineFuelType } from 'src/utils/vinHelpers'

import NadaSearch from './NadaSearch'

import './styles.scss'

const { Title, Text } = Typography

interface NewTransactionSpecificVehicleInfoProps {
  userType: EnumUserType
}

export default function NewTransactionSpecificVehicleInfo(
  { userType }: NewTransactionSpecificVehicleInfoProps
) {
  const [displayKeiNotice, setDisplayKeiNotice] = React.useState(false)
  const globalStore = useContext(store)
  const { navigateToStep } = useNewTransaction()
  const { documentEnabled } = globalStore.state.createTransaction
  const { enabled: paymentTansferEnabled, protectionEnabled } = globalStore.state.createTransaction.paymentTransfer
  const userRole = transactionUserInfo(userType, globalStore).role
  const [noticeCheckError, setNoticeCheckError] = React.useState(false)
  const [nadaCheckError, setNadaCheckError] = React.useState(false)
  const [nadaModalVisible, setNadaModalVisible] = React.useState(false)
  const [purchaseDateAdditionalProps, setPurchaseDateAdditionalProps] = React.useState<AdditionalInputProps>({})
  const [localVinResultsInfo, setLocalVinResultsInfo] = React.useState<VINResultsInfo>()

  const [form] = Form.useForm()

  const {
    vin,
    make,
    model,
    color,
    fuelType,
    condition,
    bodyType,
    numCylinders,
    odometerReading,
    odometerDisclosure,
    salePrice,
    purchaseDate,
    odometerUnit,
    emissionExtension,
    year,
    type,
    originallyInternational,
    nadaValue,
    registrationState,
    vtRegNumber,
    vtTitleNumber,
    vtPlate,
    vtExpirationDate
  } = globalStore.state.createTransaction.vehicle

  React.useEffect(() => {
    if (userType === EnumUserType.Main) {
      setDisplayKeiNotice(vin.length > 0 && vin.length < 17)
    }
  }, [])

  React.useEffect(() => {
    form.setFieldsValue({
      vin,
      make,
      model,
      color,
      fuelType,
      bodyType,
      numCylinders,
      year,
      odometerReading
    })
  }, [
    vin,
    make,
    model,
    color,
    fuelType,
    bodyType,
    numCylinders,
    odometerReading
  ])

  useEffect(() => {
    form.setFieldValue('nadaValue', nadaValue)
  }, [nadaValue])

  const fuelTypeCustomValidationRule = useMemo(() => ({
    validator(_: any, value: any) {
      if (!value || `${value}`.trim() === '') {
        return Promise.reject(new Error('Fuel Type is required'))
      }
      if (FUEL_TYPES.indexOf(value) === -1) {
        return Promise.reject(new Error('Fuel Type is incorrect'))
      }
      return Promise.resolve()
    }
  }), [])

  const conditionTypeCustomValidationRule = useMemo(() => ({
    validator(_: any, value: any) {
      if (!value || `${value}`.trim() === '') {
        return Promise.reject(new Error('Condition is required'))
      }
      if (CONDITION_TYPES.indexOf(condition) === -1) {
        return Promise.reject(new Error('Condition is incorrect'))
      }
      return Promise.resolve()
    }
  }), [])

  const vehicleBodyTypeCustomValidationRule = useMemo(() => ({
    validator(_: any, value: any) {
      if (!value || `${value}`.trim() === '') {
        return Promise.reject(new Error('Body Style is required'))
      }
      if ([VEHICLE_TYPE.Motorcycle, VEHICLE_TYPE.MotorHome].indexOf(type || '') === -1
      && VEHICLE_BODY_TYPES.indexOf(value) === -1) {
        return Promise.reject(new Error('Body Style is incorrect'))
      }
      return Promise.resolve()
    }
  }), [])

  const handleNadaNoticeCheckChange = (e: any) => {
    form.setFieldValue('nadaNoticeChecked', e.target.checked)
    form.validateFields(['nadaNoticeChecked'])
  }

  const validStateFormCheck = () => {
    if (purchaseDateAdditionalProps?.validateStatus === 'error') {
      return false
    }
    if (noticeCheckError) {
      return false
    }
    if (nadaCheckError) {
      return false
    }
    return true
  }

  const handleSubmitForm = (data: any) => {
    if (validStateFormCheck()) {
      if (userType === EnumUserType.Main) {
        if (userRole === EnumUserRole.Buyer) { // Buyer
          if (!documentEnabled) {
            navigateToStep({ step: EnumNewTransactionParams.NextUser })
          } else {
            navigateToStep({ step: EnumNewTransactionParams.SignatureApplication })
          }
        } else { // Seller
          if (paymentTansferEnabled && protectionEnabled) {
            navigateToStep({ step: EnumNewTransactionParams.KnownIssuesDisclosure })
          } else if (!documentEnabled) {
            navigateToStep({ step: EnumNewTransactionParams.NextUser })
          } else {
            navigateToStep({ step: EnumNewTransactionParams.SignatureOdometer })
          }
        }
      } else { // Secondary
        if (userRole === EnumUserRole.Buyer) { // Buyer
          if (!documentEnabled) {
            navigateToStep({ step: EnumNewTransactionParams.Complete })
          } else {
            navigateToStep({ step: EnumNewTransactionParams.FeeInfo })
          }
        } else { // Seller
          if (!documentEnabled) {
            navigateToStep({ step: EnumNewTransactionParams.Complete })
          } else if (paymentTansferEnabled && protectionEnabled) {
            navigateToStep({ step: EnumNewTransactionParams.KnownIssuesDisclosure })
          } else {
            navigateToStep({ step: EnumNewTransactionParams.SignatureOdometer })
          }
        }
      }

      // if (userRole !== EnumUserRole.Seller) {
      //   if (!documentEnabled) {
      //     navigateToStep({ step: EnumNewTransactionParams.SignatureApplication })
      //   } else {
      //     navigateToStep({ step: EnumNewTransactionParams.SignatureApplication })
      //   }
      // } else {
      //   if (paymentTansferEnabled && protectionEnabled) {
      //     navigateToStep({ step: EnumNewTransactionParams.KnownIssuesDisclosure })
      //   } else {
      //     navigateToStep({ step: EnumNewTransactionParams.SignatureOdometer })
      //   }
      // }
    }
  }

  const updateVIN = async (e: any) => {
    let text = e.target.value
    if (text.slice(0, 1).toLowerCase() === 'i') {
      text = text.slice(1)
    }

    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_VIN',
      vin: text.slice(0, 17).toUpperCase()
    })

    // Now if the VIN is long enough we can try to snag the information about the car
    if (text.length === 17) {
      const vinVehicleInfo = await decodeVinWithRetry(text, globalStore.state.createTransaction.vehicle.year)
      if (vinVehicleInfo) {
        setLocalVinResultsInfo(vinVehicleInfo)
        globalStore.dispatch({
          type: 'UPDATE_VEHICLE_DATA_FROM_LICENSE',
          make: properCase(vinVehicleInfo.Make),
          model: vinVehicleInfo.Model,
          year:
            parseInt(vinVehicleInfo.ModelYear, 10)
            || globalStore.state.createTransaction.vehicle.year,
          fuelType: determineFuelType(
            vinVehicleInfo.FuelTypePrimary,
            vinVehicleInfo.FuelTypeSecondary
          ),
          numCylinders: vinVehicleInfo.EngineCylinders
            ? parseInt(vinVehicleInfo.EngineCylinders, 10)
            : undefined,
          bodyType: determineBodyType(vinVehicleInfo.BodyClass) || ''
        })
      }
    }
  }

  const extensionWaiverInputChange = () => {
    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_EMISSION_EXTENSION',
      emissionExtension: !emissionExtension
    })
  }

  const onBlurVIN = () => {
    setDisplayKeiNotice(vin.length > 0 && vin.length < 17)
  }

  const initPurchaseDate = useMemo(() => {
    if (!!purchaseDate) {
      return dayjs(purchaseDate.toLocaleDateString('en-GB'), 'DD/MM/YYYY')
    }
    return null
  }, [purchaseDate])

  const noticeCheckCustomValidationRule = useMemo(() => ({
    validator(_: any, value: any) {
      if (!displayKeiNotice) {
        setNadaCheckError(false)
        return Promise.resolve()
      }

      if (userType !== EnumUserType.Main
        || value === true
      ) {
        setNoticeCheckError(false)
        return Promise.resolve()
      }

      setNoticeCheckError(true)
      return Promise.reject()
    }
  }), [userType, displayKeiNotice])

  const nadaNoticeCheckCustomValidationRule = useMemo(() => ({
    validator(_: any, value: any) {
      if (originallyInternational) {
        setNadaCheckError(false)
        return Promise.resolve()
      }

      if (userType !== EnumUserType.Main) {
        setNadaCheckError(false)
        return Promise.resolve()
      }

      if (value === true) {
        setNadaCheckError(false)
        return Promise.resolve()
      }
      setNadaCheckError(true)
      return Promise.reject()
    }
  }), [userType, originallyInternational])

  const salePriceRules = useMemo(() => {
    // const fieldLabel = userRole === EnumUserRole.SelfTransfer
    //   ? 'Car Value'
    //   : 'Sale Price'
    const fieldLabel = 'Sale Price'
    return [
      { required: true, message: `${fieldLabel} is required` },
      { pattern: NUMBER_REGEX, message: `${fieldLabel} must be positive number` }
    ]
  }, [userRole])

  const handleNoticeCheckChange = (e: any) => {
    form.setFieldValue('vinLengthNoticeChecked', e.target.checked)
    form.validateFields(['vinLengthNoticeChecked'])
  }

  const renderKeiNotice = () => {
    if (displayKeiNotice) {
      return (
        <>
          <Spacer space={8} />
          <Row className="specific-vehicle-information-page__form__kei-notice-info">
            <div className="specific-vehicle-information-page__form__kei-notice-check">
              <Form.Item
                name="vinLengthNoticeChecked"
                valuePropName="checked"
                initialValue={false}
                rules={[
                  noticeCheckCustomValidationRule
                ]}
              >
                <Checkbox onChange={handleNoticeCheckChange}>
                  <div className={noticeCheckError
                    ? 'specific-vehicle-information-page__form__kei-notice-check--error'
                    : ''}
                  >
                    <Text className="kei-notice-check__label">
                      The VIN listed above is less than 17 characters
                      long. I hereby confirm that the VIN value entered
                      above is correct and that if it is incorrect my
                      application will be
                      {' '}
                      <b>rejected.</b>
                    </Text>
                  </div>
                </Checkbox>
              </Form.Item>
            </div>
          </Row>
          <Spacer space={16} />
          <Row className="specific-vehicle-information-page__form__kei-notice-info">
            <Text>
              If this vehicle is imported and a Kei class vehicle with
              a motor not larger than 660cc, it is not eligible for
              Vermont registration.
            </Text>
          </Row>
        </>
      )
    }
    return null
  }

  const handleOdometerChange = (e: any) => {
    if (Number(e.target.value) >= 0) {
      globalStore.dispatch({
        type: 'UPDATE_VEHICLE_ODOMETER_READING',
        odometerReading: Number(e.target.value)
      })
    }
  }

  const handleNumberOfCylindersChange = (e: any) => {
    if (Number(e.target.value) > 0) {
      globalStore.dispatch({
        type: 'UPDATE_VEHICLE_NUM_CYLINDERS',
        numCylinders: Number(e.target.value)
      })
    }
  }

  const handleSalePriceChange = (e: any) => {
    if (Number(e.target.value) > 0) {
      globalStore.dispatch({
        type: 'UPDATE_VEHICLE_SALE_PRICE',
        salePrice: Number(e.target.value)
      })
    }
  }

  const handlePurchaseDateChange = (d: any) => {
    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_PURCHASE_DATE',
      purchaseDate: d?.toDate()
    })
    // if (d?.toDate()?.getTime() < new Date().setDate(new Date().getDate() + 2)) {
    //   setPurchaseDateAdditionalProps({})
    //   globalStore.dispatch({
    //     type: 'UPDATE_VEHICLE_PURCHASE_DATE',
    //     purchaseDate: d?.toDate()
    //   })
    // } else {
    //   setPurchaseDateAdditionalProps({ help: 'Please choose another day', validateStatus: 'error' })
    // }
    // form.validateFields(['purchaseDate'])
  }

  const yearCustomValidationRule = () => ({
    validator(_: any, value: any) {
      if (!value || `${value}`.trim() === '') {
        return Promise.reject(new Error('Year is required'))
      }
      if (
        YEAR_REGEX.test(value)
        && value <= new Date().getUTCFullYear()
        && value > 1886
      ) {
        globalStore.dispatch({
          type: 'UPDATE_VEHICLE_YEAR',
          year: value
        })
        return Promise.resolve()
      }
      return Promise.reject(new Error('Year is incorrect'))
    }
  })

  const handleNADAValueChange = (e: any) => {
    if (Number(e.target.value) > 0) {
      globalStore.dispatch({
        type: 'UPDATE_VEHICLE_NADA_VALUE',
        nadaValue: Number(e.target.value)
      })
    }
  }

  const handleRegistrationNumberChange = (e: any) => {
    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_VT_REG_NUMBER',
      vtRegNumber: e.target.value.toUpperCase()
    })
  }

  const handleTitleNumberChange = (e: any) => {
    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_VT_TITLE_NUMBER',
      vtTitleNumber: e.target.value.toUpperCase()
    })
  }

  const handlePlateChange = (e: any) => {
    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_VT_PLATE',
      vtPlate: e.target.value.toUpperCase()
    })
  }

  const renderOdometerDisclosure = () => {
    if (userRole === EnumUserRole.Seller) {
      return (
        <Form.Item
          name="odometerDisclosure"
          initialValue={odometerDisclosure}
          rules={[
            { required: true, message: 'Odometer Disclosure is required' }
          ]}
        >
          <OdometerDisclosureSelect
            placeholder="Odometer Disclosure (Select)"
            onChange={(d: string) => {
              globalStore.dispatch({
                type: 'UPDATE_VEHICLE_ODOMETER_DISCLOSURE',
                odometerDisclosure: d
              })
            }}
          />
        </Form.Item>
      )
    }
    return null
  }

  const renderNADA = () => {
    if (!originallyInternational) {
      return (
        <>
          <Row className="specific-vehicle-information-page__form__nada-text">
            <Text className="kuaay-form__text-under-input">
              If the car has been imported or no NADA value can be found,
              use the
              {' '}
              <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                {/* {userRole === EnumUserRole.SelfTransfer ? 'car value' : 'sale price'} */}
                sale price
              </Text>
              {' '}
              from above.
            </Text>
          </Row>
          <Spacer space={16} />
          <Form.Item
            name="nadaValue"
            initialValue={nadaValue}
            rules={[
              { required: true, message: 'NADA Value is required' },
              { pattern: NUMBER_REGEX, message: 'NADA Value must be a positive number' }
            ]}
          >
            <Input
              type="number"
              min="1"
              autoCapitalize="none"
              inputMode="numeric"
              prefix={
                <span>$</span>
              }
              suffix={
                <SearchOutlined className="kuaay-input-icon" onClick={() => setNadaModalVisible(true)} />
              }
              placeholder="NADA Value"
              onChange={handleNADAValueChange}
            />
          </Form.Item>
          <Form.Item
            className="specific-vehicle-information-page__form__nada-check"
            name="nadaNoticeChecked"
            valuePropName="checked"
            initialValue={false}
            rules={[
              nadaNoticeCheckCustomValidationRule
            ]}
            help={null}
          >
            <Checkbox
              onChange={handleNadaNoticeCheckChange}
            >
              <div className={nadaCheckError
                ? 'specific-vehicle-information-page__form__nada-check--error'
                : ''}
              >
                <Text className="">
                  I hereby confirm that the NADA value entered above is correct and that if it is incorrect my application will be
                  {' '}
                  <b>rejected.</b>
                </Text>
              </div>
            </Checkbox>
          </Form.Item>
          <Spacer space={24} />
          <NadaSearch
            open={nadaModalVisible}
            vehicleSeries={localVinResultsInfo?.Series}
            onClose={() => setNadaModalVisible(false)}
          />
        </>
      )
    }
    return null
  }

  const renderSalePrice = () => {
    if (userType !== EnumUserType.Secondary
      || userRole !== EnumUserRole.Seller) {
      return (
        <Form.Item
          name="salePrice"
          initialValue={salePrice}
          rules={salePriceRules}
        >
          <Input
            type="number"
            min="1"
            autoCapitalize="none"
            inputMode="numeric"
            prefix={
              <span>$</span>
            }
            // placeholder={
            //   userRole === EnumUserRole.SelfTransfer
            //     ? 'Car Value'
            //     : 'Sale Price'
            // }
            placeholder="Sale Price"
            onChange={handleSalePriceChange}
          />
        </Form.Item>
      )
    }
    return null
  }

  const renderVermontInformation = () => {
    if (registrationState === 'VT'
      && (userType !== EnumUserType.Secondary
        || userRole !== EnumUserRole.Seller)) {
      return (
        <>
          <Row>
            <Text className="kuaay-form__text-under-input">
              Vermont Information:
            </Text>
          </Row>
          <Spacer space={16} />
          <Form.Item
            name="vtRegNumber"
            initialValue={vtRegNumber}
            rules={[
              { required: true, message: 'Registration Number is required' }
            ]}
          >
            <Input
              placeholder="Registration Number"
              onChange={handleRegistrationNumberChange}
            />
          </Form.Item>
          <Form.Item
            name="vtTitleNumber"
            initialValue={vtTitleNumber}
            rules={[
              { required: true, message: 'Title Number is required' }
            ]}
          >
            <Input
              placeholder="Title Number"
              onChange={handleTitleNumberChange}
            />
          </Form.Item>
          <Form.Item
            name="vtPlate"
            initialValue={vtPlate}
            rules={[
              { required: true, message: 'Plate is required' }
            ]}
          >
            <Input
              placeholder="Plate"
              onChange={handlePlateChange}
            />
          </Form.Item>
          <Form.Item
            name="vtExpirationDate"
            initialValue={vtExpirationDate}
            rules={[
              { required: true, message: 'Expiration Date is required' }
            ]}
          >
            <DatePicker placeholder="Expiration Date" />
          </Form.Item>
        </>
      )
    }
    return null
  }

  const renderVehicleBodyType = () => {
    // TODO Update VehicleBodyType Value
    if (userType !== EnumUserType.Secondary
      || userRole !== EnumUserRole.Seller
    ) {
      return (
        <Form.Item
          name="bodyType"
          initialValue={bodyType}
          rules={[
            vehicleBodyTypeCustomValidationRule
          ]}
        >
          <VehicleBodyTypeSelect
            disabled={[VEHICLE_TYPE.Motorcycle, VEHICLE_TYPE.MotorHome].indexOf(type ?? '') > -1}
            placeholder="Body Style"
            onChange={(d: string) => {
              globalStore.dispatch({
                type: 'UPDATE_VEHICLE_BODY_TYPE',
                bodyType: d
              })
            }}
          />
        </Form.Item>
      )
    }
    return null
  }

  const renderNumCylinders = () => {
    if (userType !== EnumUserType.Secondary
        || userRole !== EnumUserRole.Seller
    ) {
      return (
        <Form.Item
          name="numCylinders"
          initialValue={numCylinders}
          rules={[
            { required: true, message: 'Number of Cylinders is required' },
            { pattern: NUMBER_REGEX, message: 'Number of Cylinder must be a positive number' }
          ]}
        >
          <Input
            type="number"
            min="1"
            autoCapitalize="none"
            inputMode="numeric"
            placeholder="Number of Cylinders"
            onChange={handleNumberOfCylindersChange}
          />
        </Form.Item>
      )
    }
    return null
  }

  // const placeholderForPurchaseDate: string = useMemo(() => {
  //   if (userRole === EnumUserRole.SelfTransfer) {
  //     return 'Transfer Date'
  //   }
  //   return 'Sale Date'
  // }, [userRole])

  const placeholderForPurchaseDate = 'Sale Date'

  const saleDateRules = useMemo(() => (
    [
      { required: true, message: `${placeholderForPurchaseDate} is required` },
      {
        validator(_: any, value: any) {
          const nextThreeDate = dayjs(new Date()).add(3, 'days').startOf('day')

          if (value?.toDate()?.getTime() >= nextThreeDate.toDate().getTime()) {
            return Promise.reject(new Error(`${placeholderForPurchaseDate} must be before ${nextThreeDate.format('MM/DD/YYYY')}`))
          }

          return Promise.resolve()
        }
      }
    ]
  ), [placeholderForPurchaseDate])

  return (
    <Row className="specific-vehicle-information-page">
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        <Title className="specific-vehicle-information-page__title" level={4}>
          Specific Vehicle Information
        </Title>
        <Form
          form={form}
          className="kuaay-form specific-vehicle-information-page__form"
          onFinish={handleSubmitForm}
        >
          {
            (userType !== EnumUserType.Secondary || userRole !== EnumUserRole.Seller) && (
              <>
                <Text className="specific-vehicle-information-page__form__vin-description">
                  Enter the Vehicle Identification Number (VIN). It can be
                  found, stamped on a metal plate, on the bottom or left side
                  of the front windshield.
                </Text>
                <Spacer space={24} />
                <Form.Item
                  name="vin"
                  initialValue={vin}
                  rules={[
                    { required: true, message: 'VIN/HIN is required' }
                  ]}
                >
                  <Input
                    placeholder="VIN/HIN"
                    suffix={
                      <CameraOutlined className="kuaay-input-icon" />
                    }
                    onChange={updateVIN}
                    onBlur={onBlurVIN}
                  />
                </Form.Item>
                <Input
                  className="specific-vehicle-information-page__form__vin-lookup"
                  value="Tap here to access a VIN Lookup service"
                  placeholder="VIN/HIN"
                  onClick={() => window.open('https://www.nicb.org/vincheck')}
                />
                { renderKeiNotice() }
                <Spacer space={10} />
                <Row>
                  <Text className="kuaay-form__text-under-input">
                    This number should match the VIN on the vehicle title.
                  </Text>
                </Row>
                <Spacer space={16} />
                {
                  !documentEnabled && (
                    <Form.Item
                      name="year"
                      initialValue={make}
                      rules={[yearCustomValidationRule()]}
                    >
                      <Input
                        placeholder="Year"
                        onChange={(e) => {
                          globalStore.dispatch({
                            type: 'UPDATE_VEHICLE_YEAR',
                            make: e.target.value
                          })
                        }}
                      />
                    </Form.Item>
                  )
                }
                <Form.Item
                  name="make"
                  initialValue={make}
                  rules={[
                    { required: true, message: 'Make is required' }
                  ]}
                >
                  <Input
                    placeholder="Make"
                    onChange={(e) => {
                      globalStore.dispatch({
                        type: 'UPDATE_VEHICLE_MAKE',
                        make: e.target.value
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="model"
                  initialValue={model}
                  rules={[
                    { required: true, message: 'Model is required' }
                  ]}
                >
                  <Input
                    placeholder="Model"
                    onChange={(e) => {
                      globalStore.dispatch({
                        type: 'UPDATE_VEHICLE_MODEL',
                        model: e.target.value
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="color"
                  initialValue={color}
                  rules={[
                    { required: true, message: 'Color is required' }
                  ]}
                >
                  <Input
                    placeholder="Color"
                    onChange={(e) => {
                      globalStore.dispatch({
                        type: 'UPDATE_VEHICLE_COLOR',
                        color: e.target.value
                      })
                    }}
                  />
                </Form.Item>
                {
                  documentEnabled && (
                    <>
                      <Form.Item
                        name="fuelType"
                        initialValue={fuelType}
                        rules={[
                          fuelTypeCustomValidationRule
                        ]}
                      >
                        <FuelType
                          placeholder="Fuel Type"
                          onChange={(d: string) => {
                            globalStore.dispatch({
                              type: 'UPDATE_VEHICLE_FUEL_TYPE',
                              fuelType: d
                            })
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="condition"
                        initialValue={condition}
                        rules={[
                          conditionTypeCustomValidationRule
                        ]}
                      >
                        <VehicleConditionSelect
                          placeholder="Condition"
                          onChange={(d: string) => {
                            globalStore.dispatch({
                              type: 'UPDATE_VEHICLE_CONDITION',
                              condition: d
                            })
                          }}
                        />
                      </Form.Item>
                    </>
                  )
                }
                {
                  documentEnabled && renderVehicleBodyType()
                }
                {
                  documentEnabled && renderNumCylinders()
                }
              </>
            )
          }
          <Form.Item
            name="odometerReading"
            initialValue={odometerReading}
            rules={[
              { required: true, message: 'Odometer Reading is required' },
              { pattern: NUMBER_REGEX, message: 'Odometer Reading must be a positive number' }
            ]}
          >
            <Input
              type="number"
              min="0"
              autoCapitalize="none"
              inputMode="numeric"
              placeholder="Odometer Reading"
              onChange={handleOdometerChange}
              suffix={(
                <OdometerUnit
                  initialValue={odometerUnit}
                  onChange={(d: any) => {
                    globalStore.dispatch({
                      type: 'UPDATE_VEHICLE_ODOMETER_UNIT',
                      odometerUnit: d.value
                    })
                  }}
                />
              )}
            />
          </Form.Item>

          {
            documentEnabled && renderOdometerDisclosure()
          }
          {
            renderSalePrice()
          }
          {
            documentEnabled && userType !== EnumUserType.Secondary && (
              <>
                <Form.Item
                  name="purchaseDate"
                  rules={saleDateRules}
                  initialValue={initPurchaseDate}
                  {...purchaseDateAdditionalProps}
                >
                  <DatePicker
                    placeholder={placeholderForPurchaseDate}
                    onChange={handlePurchaseDateChange}
                  />
                </Form.Item>
                {
                  renderNADA()
                }
                <Row className="specific-vehicle-information-page__form__extension-waiver-text">
                  <Text className="kuaay-form__text-under-input">
                    Has this vehicle failed an
                    {' '}
                    <b>emissions test</b>
                    {' '}
                    and been granted a time extension waiver in the past 12
                    months? See
                    {' '}
                    <a target="__blank" href="https://dmv.vermont.gov/enforcement-and-safety/vehicle-inspections">avip.vermont.gov</a>
                    {' '}
                    for more information.
                  </Text>
                </Row>
                <Spacer space={16} />
                <Input
                  className="specific-vehicle-information-page__form__extension-waiver-input"
                  value="Emission extension waiver?"
                  onClick={() => { extensionWaiverInputChange() }}
                  suffix={(
                    <Switch
                      checked={emissionExtension}
                      onChange={() => extensionWaiverInputChange()}
                    />
                  )}
                />
                <Spacer space={16} />
                {
                  renderVermontInformation()
                }
              </>
            )
          }

        </Form>
        <Spacer className="flex-grow-1" space={16} />
        <Button
          className="transaction-form__btn specific-vehicle-information-page__continue-button"
          type="primary"
          color="primary"
          onClick={() => form.submit()}
        >
          Continue
        </Button>
      </Col>
    </Row>
  )
}
