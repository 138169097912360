import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleFilled, QrcodeOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import classNames from 'classnames'

import { EnumRoute } from 'src/router/types'
import { PendingTransactionInfo } from 'src/types/transactions'
import { checkVerifyTimeout } from 'src/utils/transaction'
import { EnumShareRole, EnumShareStep } from 'src/utils/types'

import TransactionQrModal from './TransactionQrCode'
import { TransactionCardProps } from './types'

import './TransactionCard.scss'

export default function TransactionCard({ transaction, isPending }: TransactionCardProps) {
  const [isFocus, setIsFocus] = useState(false)
  const [qrModalVisible, setQrModalVisible] = useState(false)
  const navigate = useNavigate()

  const sharingTxn = useMemo(() => transaction as PendingTransactionInfo, [transaction])
  const canSeeTxn = useMemo(
    () => !isPending || (sharingTxn.step === EnumShareStep.Complete
      || (sharingTxn.step === EnumShareStep.Shared && sharingTxn.shareRole === EnumShareRole.Receiver)
      || (sharingTxn.step === EnumShareStep.UpdateKnownIssues && sharingTxn.shareRole === EnumShareRole.Receiver)
      || (sharingTxn.step === EnumShareStep.NeedsPayment && sharingTxn.shareRole === EnumShareRole.Receiver)),
    [isPending, sharingTxn]
  )

  const handleMouseDown = () => {
    setIsFocus(true)
  }
  const handleMouseUp = () => {
    setIsFocus(false)
  }

  const handleClick = useCallback(() => {
    setTimeout(() => {
      if (navigate) {
        if (!isPending) {
          navigate(`${EnumRoute.Transactions}/${transaction.transactionId}`)
        } else if (sharingTxn.sharingId) {
          if (canSeeTxn) {
            navigate(`/shared-transactions/${sharingTxn.sharingId}`)
          }
        }
      }
    }, 100)
  }, [navigate, transaction])

  const transactionClasses = useMemo(() => {
    let statusClass = ''
    if (!isPending) {
      if (transaction.stripeTransactionId) {
        statusClass = 'transaction-card--primary'
      } else {
        statusClass = 'transaction-card--warning'
      }
    } else if (sharingTxn.step === EnumShareStep.Complete) {
      statusClass = 'transaction-card--success'
    } else if (canSeeTxn) {
      statusClass = 'transaction-card--warning'
    } else {
      statusClass = 'transaction-card--info'
    }

    return classNames('transaction-card', statusClass)
  }, [isPending, transaction, sharingTxn])

  const title = useMemo(() => {
    if (
      !transaction.vehicle.year
      && !transaction.vehicle.make
      && !transaction.vehicle.model
    ) {
      return (transaction as PendingTransactionInfo).step
        ? 'No information provided'
        : 'Open for more information'
    }
    return (
      <>
        {transaction.vehicle.year ? `${transaction.vehicle.year} ` : ''}
        {
          transaction.vehicle.make ? `${transaction.vehicle.make} ` : ''
        }
        {transaction.vehicle.model ? `${transaction.vehicle.model} ` : ''}
        {!isPending && transaction.paymentTransfer?.enabled && checkVerifyTimeout(transaction) && (
          <ExclamationCircleFilled className="alert-icon" />
        )}
      </>
    )
  }, [transaction])

  const subtitle = useMemo(() => {
    if (isPending) {
      const pTransInfo = transaction as PendingTransactionInfo
      switch (pTransInfo.step) {
      case EnumShareStep.Shared:
        if (pTransInfo.shareRole === 'receiver') {
          return 'Form information requested'
        }
        return 'Waiting on receiver'

      case EnumShareStep.NeedsPayment:
        if (pTransInfo.shareRole === 'receiver') {
          return 'Waiting on payment'
        }
        return 'Payment required'

      case EnumShareStep.UpdateKnownIssues:
        if (pTransInfo.shareRole === 'receiver') {
          return 'Update Known Issues disclosure'
        }
        return 'Waiting for Known Issues disclosure'

      case EnumShareStep.Complete:
        return 'Transaction complete'

      default:
        return 'Unknown Step'
      }
    } else {
      return `Completion date: ${transaction.creationDate.toLocaleDateString()}`
    }
  }, [isPending, transaction])

  const handleClickQrIcon = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    setQrModalVisible(true)
  }

  return (
    <>
      {
        sharingTxn?.sharingId && (
          <TransactionQrModal
            open={qrModalVisible}
            sharingId={sharingTxn.sharingId}
            onClose={() => setQrModalVisible(false)}
          />
        )
      }
      <Card
        className={transactionClasses}
        data-is-focus={isFocus}
        bordered
        title={(
          <>
            <div className="transaction-card__title">
              {title}
            </div>
            <div className="transaction-card__subtitle">
              {subtitle}
            </div>
          </>
        )}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        onClick={handleClick}
      >
        {
          isPending ? (
            <div className="transaction-card__pending-detail-wrapper">
              <div className="transaction-card__pending-detail-info">
                <div className="transaction-card__initial-date">
                  Initiated on:&nbsp;
                  {sharingTxn.creationDate
                    ? sharingTxn.creationDate.toLocaleDateString()
                    : 'Unknown'}
                </div>
                <div className="transaction-card__status">
                  {sharingTxn.stripeTransactionId
                    ? 'Completed transaction'
                    : 'Transaction awaiting verification'}
                </div>
              </div>
              <QrcodeOutlined
                className="transaction-card__qr-icon"
                onClick={handleClickQrIcon}
              />
            </div>
          ) : (
            <>
              <div className="transaction-card__price">
                Sale price:&nbsp;
                {transaction.vehicle.salePrice
                  ? `$${transaction.vehicle.salePrice.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                  : 'Unknown'}
              </div>
              <div className="transaction-card__status">
                {transaction.stripeTransactionId
                  ? 'Completed transaction'
                  : 'Transaction awaiting verification'}
              </div>
            </>
          )
        }
      </Card>
    </>
  )
}
