import React from 'react'
import {
  Modal, Typography
} from 'antd'

import { Button } from 'src/components/Button'

import { InfoModalProps } from './Modal.types'

import './InfoModal.scss'

const { Text } = Typography

export default function InfoModal({
  open, onClose, text, width, title, ...rest
}: InfoModalProps) {
  const handleClose = async () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Modal
      className="info-modal"
      width={width}
      centered
      open={open}
      closable={false}
      footer={null}
      {...rest}
    >
      {
        title && (
          <Text className="info-modal__title">
            {title}
          </Text>
        )
      }
      <Text className="info-modal__text">
        {text}
      </Text>
      <Button
        className="info-modal__btn"
        color="primary"
        size="small"
        onClick={handleClose}
      >
        Dismiss
      </Button>
    </Modal>
  )
}
