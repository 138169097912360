import { useContext, useMemo, useState } from 'react'
import OtpInput from 'react-otp-input'
import { CheckCircleOutlined, LockFilled, RedoOutlined } from '@ant-design/icons'
import {
  Elements, PaymentElement, useElements, useStripe
} from '@stripe/react-stripe-js'
import { loadStripe, StripePaymentElementChangeEvent, VerifyMicrodepositsForPaymentData } from '@stripe/stripe-js'
import { Col, Modal, ModalProps } from 'antd'

import { Button } from 'src/components/Button'
import { Spacer } from 'src/components/Spacer'
import { useAuthen } from 'src/hooks/useAuthen'
import { useDisplay } from 'src/hooks/useDisplay'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useTheme } from 'src/hooks/useTheme'
import { store } from 'src/store/store'
import { DEFAULT_CHECKOUT_ERROR_MESSAGE, STRIPE_APPEARANCE_DARK, STRIPE_APPEARANCE_LIGHT } from 'src/utils/constants'
import { EnumTheme } from 'src/utils/types'

import { StripeTxnStatus } from '../../types/paymentTransfer'

import './CheckoutModal.scss'

interface CheckoutFormProps {
  loading: boolean
  amount: number
  billingDetail: { email?: string, name?: string }
  clientSecret?: string
  onSuccess: () => void
  onFailed: (msg: string) => void
  onProcessing: (isProcessing: boolean) => void
}

const CheckoutForm = ({
  loading, amount, billingDetail, clientSecret, onSuccess, onFailed, onProcessing
}: CheckoutFormProps) => {
  // const [zipCode, setZipCode] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const { currentUser, email } = useAuthen()
  const [isValid, setIsValid] = useState(false)
  const [paying, setPaying] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { openMessage } = useMessage()
  const isStripeReady = useMemo(
    () => !!stripe && !!elements && !loading,
    [stripe, elements, loading]
  )
  const [isVerifyMode, setIsVerifyMode] = useState(false)
  const [otp, setOtp] = useState('')

  const handleChange = (evt: StripePaymentElementChangeEvent) => {
    setIsValid(evt.complete)
  }

  const handleCheckout = async () => {
    if (!isStripeReady || paying) {
      return
    }
    if (isValid && elements && stripe && clientSecret) {
      onProcessing(true)
      setPaying(true)

      const payload = await stripe.confirmPayment(
        {
          elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: '',
            payment_method_data: {
              billing_details: {
                name: billingDetail.name ?? '',
                email: billingDetail.email ?? ''
              }
            }
          }
        }
      )
      console.log('payload: ', payload)
      if (payload.error) {
        setErrorMessage(payload.error?.message ?? DEFAULT_CHECKOUT_ERROR_MESSAGE)
        onFailed(payload.error?.message ?? DEFAULT_CHECKOUT_ERROR_MESSAGE)
      } else {
        const { paymentIntent } = payload
        if (paymentIntent.status === 'requires_action' && paymentIntent.next_action?.type === 'verify_with_microdeposits') {
          console.log('Require action', paymentIntent.next_action)
          setIsVerifyMode(true)
        } else {
          setIsSuccess(true)
          setTimeout(() => {
            onSuccess()
          }, 2500)
        }
      }
      setPaying(false)
      onProcessing(false)
    }
  }

  const handleVerifyOtp = async () => {
    if (clientSecret && stripe) {
      // const verifyRes = await stripe?.verifyMicrodepositsForPayment(clientSecret, {
      //   amounts: [24161, 28]
      // })
      const verifyRes = await stripe.verifyMicrodepositsForPayment(clientSecret, {
        descriptor_code: otp
      } as unknown as VerifyMicrodepositsForPaymentData)
      if (verifyRes.error) {
        openMessage({
          type: EnumMessageType.ERROR,
          text1: verifyRes.error.message ?? 'Failed to verify OTP code.'
        })
        return
      }
      if ([StripeTxnStatus.SUCCEEDED, StripeTxnStatus.PROCESSING].includes(verifyRes.paymentIntent.status as unknown as StripeTxnStatus)) {
        // openMessage({
        //   type: EnumMessageType.SUCCESS
        // })
        if (onSuccess) {
          onSuccess()
        }
      }
    }
  }

  const renderMicroDepositVerification = () => (
    <>
      <div>
        Enter the 6-digit code from your bank statement to complete payment
      </div>
      <Spacer space={24} />
      <div className="checkout-form__otp-input-container">
        <OtpInput
          value={otp}
          containerStyle={{
            justifyContent: 'center'
          }}
          numInputs={6}
          onChange={setOtp}
          renderInput={
            (props) => <input {...props} className="checkout-form__otp-input" />
          }
        />
      </div>
      <Spacer space={24} />
      <div className="checkout-form__actions">
        <Button
          className="checkout-form__pay-btn"
          type="primary"
          color={isSuccess ? 'success' : 'primary'}
          block
          disabled={!isValid}
          onClick={handleVerifyOtp}
        >
          <div>
            Verify and pay
          </div>
        </Button>
        <Spacer space={24} />
        <div className={'checkout-form__stripe-powered'}>
          <a href="https://stripe.com" target="_blank" rel="noreferrer">
            Powered by
            {' '}
            <b>Stripe</b>
          </a>
        </div>
      </div>
    </>
  )

  const renderPaymentForm = () => (
    <>
      <PaymentElement
        options={{
          fields: {
            billingDetails: {
              email: billingDetail.email ? 'never' : undefined,
              name: billingDetail.name ? 'never' : undefined
            }
          }
        }}
        onChange={handleChange}
      />
      {
        errorMessage && (
          <>
            <Spacer space={16} />
            <div className="checkout-form__error-message">{errorMessage}</div>
          </>
        )
      }

      <Spacer space={24} />
      <div className="checkout-form__actions">
        <Button
          className="checkout-form__pay-btn"
          type="primary"
          color={isSuccess ? 'success' : 'primary'}
          block
          disabled={!isValid}
          onClick={handleCheckout}
        >
          <div className="checkout-form__pay-btn__content">
            <span style={{ width: '14px' }}>&nbsp;</span>
            {
              isSuccess ? (
                <>
                  <CheckCircleOutlined style={{ fontSize: '18px' }} />
                  <span style={{ width: '14px' }}>&nbsp;</span>
                </>
              ) : (
                !isStripeReady || paying ? (
                  <>
                    <span>Processing...</span>
                    <RedoOutlined spin />
                  </>
                ) : (
                  <>
                    <span>
                      Pay $
                      {amount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || 'Unknown'}
                    </span>
                    <LockFilled />
                  </>
                )
              )
            }
          </div>
        </Button>
      </div>
    </>
  )

  return (
    <Col className="checkout-form" span={24} offset={0}>
      {
        isVerifyMode ? renderMicroDepositVerification() : renderPaymentForm()
      }
    </Col>
  )
}

interface CheckoutModalProps extends ModalProps {
  amount: number
  clientSecret?: string
  billingDetail: { email?: string, name?: string }
  onClose?: () => void
  onSuccess: () => void
  onFailed: (msg: string) => void
}

const CheckoutModal = ({
  amount, clientSecret, billingDetail, open, onClose, onSuccess, onFailed, ...rest
}: CheckoutModalProps) => {
  const globalStore = useContext(store)
  const { theme } = useTheme()
  const { isPhoneDevice } = useDisplay()
  const [isProcessing, setIsProcessing] = useState(false)
  const appearance = useMemo(() => (theme === EnumTheme.Dark ? STRIPE_APPEARANCE_DARK : STRIPE_APPEARANCE_LIGHT), [theme])

  const stripePromise = useMemo(() => {
    if (globalStore.state.misc.stripePublishableKey) {
      return loadStripe(globalStore.state.misc.stripePublishableKey)
    }
    return null
  }, [globalStore.state.misc.stripePublishableKey])

  const handleProcessing = (val: boolean) => {
    setIsProcessing(val)
  }

  return (
    <Modal
      open={open}
      centered={!isPhoneDevice}
      rootClassName="checkout-modal-root"
      className="checkout-modal"
      wrapClassName="checkout-modal-wrap"
      closable={!isProcessing}
      footer={null}
      width={400}
      onCancel={onClose}
      {...rest}
    >
      {
        stripePromise && (
          <Elements options={{ clientSecret, appearance }} stripe={stripePromise}>
            <CheckoutForm
              loading={false}
              amount={amount}
              billingDetail={billingDetail}
              clientSecret={clientSecret}
              onSuccess={onSuccess}
              onFailed={onFailed}
              onProcessing={handleProcessing}
            />
          </Elements>
        )
      }
    </Modal>
  )
}

export default CheckoutModal
