import React, {
  useCallback, useEffect, useState
} from 'react'

import { NavItem } from './types'

import './Navigation.scss'

interface Navigation {
  items: NavItem[];
  selected?: string;
  onItemClick?: Function;
  noActiveBg?: boolean;
  activeBgColor?: string;
  activeTextColor?: string;
  className?: string;
  slidePosition: 'top' | 'bottom'
  hideSlide?: boolean
  hideIcon?: boolean
}

const BottomNavigation:React.FC<Navigation> = ({
  items,
  slidePosition,
  selected = undefined,
  onItemClick,
  noActiveBg,
  activeBgColor,
  activeTextColor,
  className,
  hideSlide,
  hideIcon
}) => {
  const [current, setCurrent] = useState<string|undefined>()

  useEffect(() => {
    setCurrent(selected)
  }, [selected])

  function getItemStyle() {
    const style: React.CSSProperties = {}
    if (noActiveBg && !activeBgColor) {
      style.backgroundColor = 'transparent'
    }
    if (activeBgColor) {
      style.backgroundColor = activeBgColor
    }
    return style
  }

  const isActive = useCallback(
    (route: string) => current === route || current?.split('/')?.filter(Boolean)?.some((v) => v === route.split('/')?.[1]),
    [current]
  )

  const navItems = items.map((item, idx) => (
    <div
      key={`nav-item-${idx}`}
      id={`nav-item-${idx}`}
      className={`navigation-item ${isActive(item.route) && 'active'}`}
      style={current === item.route ? getItemStyle() : {}}
      onClick={() => {
        setCurrent(item.route)

        if (onItemClick) {
          onItemClick({ id: idx, ...item })
        }
      }}
    >
      <div className="nav-item__icon-container">
        { !hideIcon && current !== item.route && item.icon ? item.icon : ''}
        { !hideIcon && current === item.route && item.activeIcon ? item.activeIcon : ''}
        {item.alertIcon}
      </div>
      {item.title && (
        <div className="navigation-item__title-container">
          <p
            className="navigation-item__title"
            style={current === item.route ? { color: activeTextColor } : {}}
          >
            {item.title}
          </p>
          {hideIcon && item.alertIcon}
        </div>
      )}
    </div>
  ))
  return (
    <div
      data-slide-position={slidePosition}
      data-slide-visible={hideSlide !== true}
      className={`navigation ${className}`}
    >
      {navItems}
    </div>
  )
}

export default BottomNavigation
