import React, { useContext, useMemo } from 'react'
import {
  Radio, RadioChangeEvent
} from 'antd'

import { Spacer } from 'src/components/Spacer'
import { store } from 'src/store/store'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'
import { EnumMailingAddress } from 'src/views/new-transaction/types'

interface MailingOptionProps {
  userType: EnumUserType
}

export default function MailingOption({ userType }: MailingOptionProps) {
  const globalStore = useContext(store)
  const { diffMailingAddress, role: userRole } = transactionUserInfo(userType, globalStore)

  const mailingType = useMemo(
    () => (diffMailingAddress ? EnumMailingAddress.DifferenceMailing : EnumMailingAddress.SameMailing),
    [diffMailingAddress]
  )

  const onChangeMailing = (e: RadioChangeEvent) => {
    globalStore.dispatch({
      type:
        userType === EnumUserType.Main
          ? 'UPDATE_MAIN_USER_DIFFERENT_MAILING_ADDRESS'
          : 'UPDATE_SECONDARY_USER_DIFFERENT_MAILING_ADDRESS',
      diffMailingAddress: e.target.value === EnumMailingAddress.DifferenceMailing
    })
  }

  const renderMailingOption = () => {
    if (userRole !== EnumUserRole.Seller) {
      return (
        <>
          <Radio.Group
            defaultValue={mailingType}
            className="new-transaction-page__entity-type"
            onChange={onChangeMailing}
          >
            <Radio value={EnumMailingAddress.SameMailing}>Mailing address same as above</Radio>
            <Spacer space={20} />
            <Radio value={EnumMailingAddress.DifferenceMailing}>Different mailing address</Radio>
          </Radio.Group>
          <Spacer space={25} />
        </>
      )
    }
    return null
  }
  return (
    <>
      {renderMailingOption()}
    </>
  )
}
