import React, {
  useCallback, useContext, useEffect, useMemo, useState
} from 'react'
import { Col, Row, Typography } from 'antd'

import { Button } from 'src/components/Button'
import PrivatePolicyModal from 'src/components/Modal/PrivatePolicyModal'
import { Spacer } from 'src/components/Spacer'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumKycContext, EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { KycStatus } from 'src/types/users'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'
import { fetchKycStatus } from 'src/utils/userHelpers'

import './styles.scss'

const { Text } = Typography

interface NewTransactionKycProps {
  userType: EnumUserType
}

export default function NewTransactionKyc({
  userType
}: NewTransactionKycProps) {
  const globalStore = useContext(store)
  const { openMessage } = useMessage()
  const {
    navigateToStep, hasBeenShared, shareTransactionId, userRole, goPrevStep
  } = useNewTransaction()
  const [kycStatus, setKycStatus] = useState(KycStatus.NOT_STARTED)
  const [privatePolicyVisible, setPrivatePolicyVisible] = useState(false)

  const { mainUser, documentEnabled, paymentTransfer } = globalStore.state.createTransaction
  const { protectionEnabled } = paymentTransfer

  useEffect(() => {
    initialize()
  }, [])

  const initialize = async () => {
    const { email: sellerEmail } = transactionUserInfo(userType, globalStore)
    if (sellerEmail) {
      const kycStatusRes = await fetchKycStatus(sellerEmail)
      setKycStatus(kycStatusRes?.status)
    }
  }

  const neededVerifyIdentity = useMemo(
    () => (kycStatus !== KycStatus.COMPLETED
      && (mainUser.role === EnumUserRole.Seller || (hasBeenShared && shareTransactionId))),
    [kycStatus, hasBeenShared, shareTransactionId, mainUser]
  )

  const renderDescription = useCallback(() => {
    if ((hasBeenShared && shareTransactionId)
      || mainUser.role === EnumUserRole.Seller) {
      return kycStatus === KycStatus.COMPLETED ? (
        <div className="new-transaction-kyc__first-text">
          <Text>
            Your identity has been verified.
          </Text>
        </div>
      ) : (
        <>
          <div className="new-transaction-kyc__first-text">
            <Text>
              To continue, we need to verify your identity
            </Text>
          </div>
          <Spacer space={48} />
          <Text>
            In order to provide a secure and safe transaction experience, we need to confirm
            your identity. This process serves to safeguard your transactions, ensuring everyone
            involved is exactly who they claim to be and that all bank accounts are genuine.
          </Text>
          <Spacer space={48} />
          <Text>
            These verification steps are necessary for protecting all parties involved.
          </Text>
        </>
      )
    }
    return kycStatus === KycStatus.COMPLETED ? (
      <div className="new-transaction-kyc__first-text">
        <Text>
          The Seller Identity has been verified.
        </Text>
      </div>
    ) : (
      <>
        <div className="new-transaction-kyc__first-text">
          <Text>
            The Seller hasn&apos;t started verifying Identity.
          </Text>
        </div>
        <Spacer space={48} />
        <Text>
          This process is required for the Seller to be able to claim the funds. You can
          continue to create the transaction. In the mean time, we will send a notification to
          the Seller to complete this process.
        </Text>
      </>
    )
  }, [kycStatus, hasBeenShared, shareTransactionId, mainUser])

  const handleClickContinue = async () => {
    if (neededVerifyIdentity) {
      try {
        const onboardRes: any = await (await authApiAxiosWithRefresh()).post('kyc/onboard', {
          context: EnumKycContext.NewTransaction
        })
        window.location.href = onboardRes.data.url
      } catch (error: any) {
        openMessage({
          text1: 'There was an error while verifying your identity.',
          text2: 'Please try again soon.',
          type: EnumMessageType.ERROR
        })
      }
    } else {
      handleClickSkip()
    }
  }

  const handleClickSkip = () => {
    let stepToNavigate
    if (userType === EnumUserType.Main) {
      if (!documentEnabled) {
        stepToNavigate = EnumNewTransactionParams.SpecificVehicleInfo
      } else {
        stepToNavigate = EnumNewTransactionParams.MainVehicleInfo
      }
    } else {
      if (!documentEnabled) {
        if (userRole === EnumUserRole.Seller && protectionEnabled) {
          stepToNavigate = EnumNewTransactionParams.KnownIssuesDisclosure
        } else if (userRole === EnumUserRole.Buyer && hasBeenShared) {
          stepToNavigate = EnumNewTransactionParams.KnownIssuesAgreement
        } else {
          stepToNavigate = EnumNewTransactionParams.Complete
        }
      } else {
        stepToNavigate = EnumNewTransactionParams.SpecificVehicleInfo
      }
    }
    navigateToStep({ step: stepToNavigate })
  }

  return (
    <>
      <Row className="new-transaction-kyc">
        <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
          <Spacer space={24} />
          <div className="new-transaction-kyc__text-wrapper">
            {renderDescription()}
          </div>
          <Spacer className="flex-grow-1" space={16} />

          {neededVerifyIdentity && (
            <div className="new-transaction-kyc__text-policy">
              <div>
                <Text>
                  By select &quot;Continue&quot;, you agree to the
                </Text>
              </div>
              <div className="new-transaction-kyc__text-policy-link" onClick={() => setPrivatePolicyVisible(true)}>
                <Text>
                  Ascend Private Policy.
                </Text>
              </div>
            </div>
          )}

          <Button
            className="transaction-form__btn new-transaction-kyc__continue-btn"
            color="primary"
            type="primary"
            onClick={handleClickContinue}
          >
            Continue
          </Button>

          {neededVerifyIdentity && (
            <>
              <Spacer space={18} />
              <Button
                className="transaction-form__btn new-transaction-kyc__skip-btn"
                color="default"
                type="primary"
                onClick={handleClickSkip}
              >
                Skip
              </Button>
            </>
          )}
        </Col>
      </Row>
      <PrivatePolicyModal
        open={privatePolicyVisible}
        onClose={() => setPrivatePolicyVisible(false)}
      />
    </>
  )
}
