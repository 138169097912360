import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { LoadingModal } from 'src/components/Modal'
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams, EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'
import { TransactionInfoRaw } from 'src/types/transactions'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { transactionRawToTransactionInfo } from 'src/utils/transaction'
import {
  EnumSharePrivacy, EnumShareStep, EnumUserRole, EnumUserType, IShareTransactionInfo
} from 'src/utils/types'

import './style.scss'

export default function SharedTransaction() {
  const { id } = useParams()
  const globalStore = useContext(store)
  const { openMessage } = useMessage()
  const navigate = useNavigate()
  const { navigateToStep } = useNewTransaction()
  const { currentUser, isAuthenLoaded } = useAuthen()
  useEffect(() => {
    if (id && isAuthenLoaded) {
      handleShareLogic()
    }
  }, [id, isAuthenLoaded])

  const handleShareStepLogic = async (
    shareTransactionInfo: IShareTransactionInfo
  ) => {
    if (shareTransactionInfo.step !== EnumShareStep.Shared) {
      openMessage({
        type: EnumMessageType.ERROR,
        text1: 'This transaction is no longer available.',
        text2: 'Please reach out to the creator for the status.',
        duration: 6000
      })

      navigate(EnumRoute.Account)
    }
    // If the transaction is private then make sure the ids match and send them away if not
    if (
      shareTransactionInfo.privacy === EnumSharePrivacy.Private
        && shareTransactionInfo.receiverUserId !== currentUser?.uid
    ) {
      openMessage({
        type: EnumMessageType.ERROR,
        text1: 'You do not have access to this transaction.',
        text2: 'Please reach out to the creator for access.',
        duration: 6000
      })
      navigate(EnumRoute.Account)
      return
    }

    // If we're here then we are good to go and can get the transaction and get the ball rolling
    // First get the actual transaction
    const transactionInfo = await getTransactionInfo()
    if (transactionInfo) {
      // Now store it
      globalStore.dispatch({
        type: 'RESET_TRANSACTION_STATE_MANUALLY',
        transactionId: transactionInfo.transactionId,
        mainUser: transactionInfo.mainUser,
        secondaryUser: transactionInfo.secondaryUser,
        vehicle: transactionInfo.vehicle,
        shipment: transactionInfo.shipment,
        paymentTransfer: transactionInfo.paymentTransfer,
        askBuyerPayForTheFees: transactionInfo.askBuyerPayForTheFees,
        documentEnabled: transactionInfo.documentEnabled
      })
    }
    // First update the state to point back to whatever was before so android users can user their back button

    navigateToStep(
      {
        step: EnumNewTransactionParams.NeededInfo,
        userType: EnumUserType.Secondary,
        hasBeenShared: true,
        shareStep: EnumShareStep.Shared,
        shareTransactionId: shareTransactionInfo.sharingId
      }
    )
  }

  const handleNeedsPaymentStepLogic = async (
    shareTransactionInfo: IShareTransactionInfo
  ) => {
    if (
      shareTransactionInfo.privacy === EnumSharePrivacy.Private
      && shareTransactionInfo.receiverUserId !== currentUser?.uid
    ) {
      openMessage({
        type: EnumMessageType.ERROR,
        text1: 'You do not have access to this transaction.',
        text2: 'Please reach out to the creator for access.',
        duration: 6000
      })
      navigate(EnumRoute.Account)
      return
    }

    // If we're here then we are good to go and can get the transaction and get the ball rolling
    // First get the actual transaction
    const transactionInfo = await getTransactionInfo()
    if (transactionInfo) {
      // Now store it
      globalStore.dispatch({
        type: 'RESET_TRANSACTION_STATE_MANUALLY',
        transactionId: transactionInfo.transactionId,
        mainUser: transactionInfo.mainUser,
        secondaryUser: transactionInfo.secondaryUser,
        vehicle: transactionInfo.vehicle,
        shipment: transactionInfo.shipment,
        paymentTransfer: transactionInfo.paymentTransfer,
        askBuyerPayForTheFees: transactionInfo.askBuyerPayForTheFees,
        documentEnabled: transactionInfo.documentEnabled
      })
    }
    // First update the state to point back to whatever was before so android users can user their back button

    const userType = transactionInfo?.userId === currentUser?.uid ? EnumUserType.Main : EnumUserType.Secondary
    let step = null
    let personalInfo = null
    if (userType === EnumUserType.Main) {
      personalInfo = transactionInfo?.mainUser
    } else {
      personalInfo = transactionInfo?.secondaryUser
    }

    if (personalInfo?.role === EnumUserRole.Buyer && transactionInfo?.paymentTransfer?.protectionEnabled) {
      step = EnumNewTransactionParams.KnownIssuesAgreement
    } else if (!transactionInfo?.documentEnabled) {
      step = EnumNewTransactionParams.Complete
    } else {
      step = EnumNewTransactionParams.FeeInfo
    }

    // Now navigate to the create transaction process and get things going
    navigateToStep(
      {
        step,
        userType,
        hasBeenShared: true,
        shareStep: EnumShareStep.NeedsPayment,
        shareTransactionId: shareTransactionInfo.sharingId
      }
    )
  }

  const handleKnownIssuesDisclosureStepLogic = async (
    shareTransactionInfo: IShareTransactionInfo
  ) => {
    if (
      shareTransactionInfo.privacy === EnumSharePrivacy.Private
      && shareTransactionInfo.receiverUserId !== currentUser?.uid
    ) {
      openMessage({
        type: EnumMessageType.ERROR,
        text1: 'You do not have access to this transaction.',
        text2: 'Please reach out to the creator for access.',
        duration: 6000
      })
      navigate(EnumRoute.Account)
      return
    }

    // If we're here then we are good to go and can get the transaction and get the ball rolling
    // First get the actual transaction
    const transactionInfo = await getTransactionInfo()
    if (transactionInfo) {
      // Now store it
      globalStore.dispatch({
        type: 'RESET_TRANSACTION_STATE_MANUALLY',
        transactionId: transactionInfo.transactionId,
        mainUser: transactionInfo.mainUser,
        secondaryUser: transactionInfo.secondaryUser,
        vehicle: transactionInfo.vehicle,
        shipment: transactionInfo.shipment,
        paymentTransfer: transactionInfo.paymentTransfer,
        askBuyerPayForTheFees: transactionInfo.askBuyerPayForTheFees,
        documentEnabled: transactionInfo.documentEnabled
      })
    }
    // First update the state to point back to whatever was before so android users can user their back button

    const userType = transactionInfo?.userId === currentUser?.uid ? EnumUserType.Main : EnumUserType.Secondary

    // Now navigate to the create transaction process and get things going
    navigateToStep(
      {
        step: EnumNewTransactionParams.KnownIssuesDisclosure,
        userType,
        hasBeenShared: true,
        shareStep: EnumShareStep.UpdateKnownIssues,
        shareTransactionId: shareTransactionInfo.sharingId
      }
    )
  }

  const handleCompletedStepLogic = async (
    shareTransactionInfo: IShareTransactionInfo
  ) => {
    if (
      shareTransactionInfo.privacy === EnumSharePrivacy.Private
      && shareTransactionInfo.senderUserId !== currentUser?.uid
      && shareTransactionInfo.receiverUserId !== currentUser?.uid
    ) {
      openMessage({
        type: EnumMessageType.ERROR,
        text1: 'You do not have access to this transaction.',
        text2: 'Please reach out to the creator for access.',
        duration: 6000
      })
      navigate(EnumRoute.Account)
      return
    }

    // If we're here then we are good to go and can get the transaction and get the ball rolling
    // First get the actual transaction
    const transactionInfo = await getTransactionInfo()

    if (!transactionInfo) {
      openMessage({
        type: EnumMessageType.ERROR,
        text1: 'Transaction not found.',
        text2: 'Please reach out to the creator for more information.',
        duration: 6000
      })
      navigate(EnumRoute.Account)
      return
    }

    // First update the state to point back to whatever was before so android users can user their back button
    navigate(`${EnumRoute.Transactions}/${transactionInfo.transactionId}`)
  }

  const handleShareLogic = async () => {
    const shareTransactionInfo = await getShareTransactionInfo()
    if (shareTransactionInfo) {
      if (shareTransactionInfo.step === EnumShareStep.Shared) {
        handleShareStepLogic(shareTransactionInfo)
      } else if (shareTransactionInfo.step === EnumShareStep.UpdateKnownIssues) {
        handleKnownIssuesDisclosureStepLogic(shareTransactionInfo)
      } else if (shareTransactionInfo.step === EnumShareStep.NeedsPayment) {
        handleNeedsPaymentStepLogic(shareTransactionInfo)
      } else if (shareTransactionInfo.step === EnumShareStep.Complete) {
        if (
          // Nuisance logic to say that we report the share transaction is complete if it's public,
          // or if the user accessing it is involved in the private shared transaction
          shareTransactionInfo.privacy === EnumSharePrivacy.Private
          && currentUser?.uid
          !== shareTransactionInfo.receiverUserId
          && currentUser?.uid !== shareTransactionInfo.senderUserId
        ) {
          openMessage({
            type: EnumMessageType.ERROR,
            text1: 'Transaction not available.',
            text2: 'Please reach out to the creator for more information.',
            duration: 6000
          })
          navigate(EnumRoute.NewTransaction)
          return
        }
        // If we made it here then just go to the transaction page
        handleCompletedStepLogic(shareTransactionInfo)
      } else {
        openMessage({
          type: EnumMessageType.ERROR,
          text1: 'Transaction not available.',
          text2: 'Please reach out to the creator for more information.',
          duration: 6000
        })
        navigate(EnumRoute.NewTransaction)
      }
    }
  }

  const getShareTransactionInfo = async () => {
    try {
      const shareTransactionResponse = await (
        await authApiAxiosWithRefresh()
      ).get(`/transaction/share/${id}`)
      if (!shareTransactionResponse.data) {
        throw new Error('Share transaction not found!')
      }
      return shareTransactionResponse.data as IShareTransactionInfo
    } catch (err) {
      openMessage({
        type: EnumMessageType.ERROR,
        text1: 'Failed to retrieve transaction information.',
        text2: 'Please try again soon.',
        duration: 6000
      })
      navigate(EnumRoute.NewTransaction)
    }
    return null
  }

  const getTransactionInfo = async () => {
    try {
      const shareTransactionResponse = await (
        await authApiAxiosWithRefresh()
      ).get(`/transaction/share/retrieve/${id}`)
      return transactionRawToTransactionInfo(shareTransactionResponse.data as TransactionInfoRaw)
    } catch (err) {
      openMessage({
        type: EnumMessageType.ERROR,
        text1: 'Failed to retrieve transaction information.',
        text2: 'Please try again soon.',
        duration: 6000
      })
      navigate(EnumRoute.NewTransaction)
    }
    return null
  }

  return (
    <div className="shared-transaction-page">
      <LoadingModal title="Loading..." />
    </div>
  )
}
