import React from 'react'

import { Option, Select } from 'src/components/Select'
import { VEHICLE_TYPES } from 'src/utils/constants'

export default function VehicleType(props: any) {
  const renderVehicleTypes = () => VEHICLE_TYPES.map((bodyType) => (
    <Option key={`bodyType.${bodyType}`} value={bodyType}>{bodyType}</Option>
  ))
  return (
    <Select {...props}>
      {renderVehicleTypes()}
    </Select>
  )
}
