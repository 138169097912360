import React, { useCallback, useContext } from 'react'
import { Col, Row, Typography } from 'antd'
import classNames from 'classnames'

import { Button } from 'src/components/Button'
import { Spacer } from 'src/components/Spacer'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { ALLOW_TRANSACTION_SHARING } from 'src/utils/constants'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'

import './styles.scss'

const { Title, Text } = Typography

interface NewTransactionSignatureOdometerProps {
  userType: EnumUserType,
  hasBeenShared?: boolean,
  shareTransactionId?: string | null
}

export default function NewTransactionSignatureOdometerInfo({ userType }: NewTransactionSignatureOdometerProps) {
  const globalStore = useContext(store)
  const { navigateToStep, userRole, buyer } = useNewTransaction()

  const { state: userState } = transactionUserInfo(userType, globalStore)
  const handleContinueClick = () => {
    if (userRole === EnumUserRole.Seller && globalStore.state.createTransaction.isInternationalAddress) {
      if (userType === EnumUserType.Main) {
        navigateToStep({
          step: ALLOW_TRANSACTION_SHARING ? EnumNewTransactionParams.NextUser : EnumNewTransactionParams.NeededInfo,
          userType: ALLOW_TRANSACTION_SHARING
            ? undefined
            : userType === EnumUserType.Main
              ? EnumUserType.Secondary
              : EnumUserType.Main
        })
      } else {
        navigateToStep({ step: EnumNewTransactionParams.FeeInfo })
      }
    } else {
      navigateToStep({ step: EnumNewTransactionParams.Signature })
    }
  }

  const renderContent = () => {
    if (buyer?.state && buyer.state !== 'VT' && buyer.state !== 'Vermont') {
      return (
        <div style={{ textAlign: 'left' }}>
          <Text>
            Federal and state law require the mileage of the vehicle to be provided
            upon transfer of ownership. Failure to provide, or providing a false statement
            may result in fines and/or imprisonment.
            <br />
            <br />
            The Seller certifies to the best of their own knowledge
            that the odometer reading and condition is as described in this document.
            <br />
            <br />
            The Seller certifies to the Buyer that the Seller is the legal and rightful owner
            of the Vehicle and as full right and authority to convey the same.
            The Seller declares under penalties of perjury that the statements contained are true and correct
            to the best of their knowledge, information, and belief.
            <br />
            <br />
            The Buyer accepts receipt of this vehicle as described.
          </Text>
        </div>
      )
    }
    return (
      <div style={{ textAlign: 'left' }}>
        <Text>
          Federal and State laws require that the Seller/Lessee disclose
          the mileage to the Buyer/Lessor in connection with the
          transfer of ownership.
          <br />
          <br />
          An inaccurate statement, or failure to complete the statement,
          may result in fines and/or imprisonment, pursuant to Section
          409(a) of the Federal Motor Vehicle Information and Cost
          Savings Act of Public Law 92-513, and pursuant of Vermont&apos;s
          Consumer Fraud Law.
          <br />
          <br />
          Statements and warrants made herein are certified under
          penalty of 23 V.S.A. Sections 202, 203, 2082 & 3829 (4). If
          vehicle is jointly owned, only one (1) seller signature is
          required.
        </Text>
      </div>
    )
  }

  return (
    <Row className={classNames('new-transaction-signature-odometer')}>
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        <Title className="new-transaction-signature-odometer__title" level={4}>Odometer Disclosure</Title>
        {renderContent()}
        <Spacer className="flex-grow-1" space={16} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="primary"
          onClick={handleContinueClick}
        >
          Agree and Continue
        </Button>
      </Col>
    </Row>
  )
}
