import React, { useContext, useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Col, Row, Typography } from 'antd'

import { Button } from 'src/components/Button'
import { CheckoutModal } from 'src/components/Modal'
import { Spacer } from 'src/components/Spacer'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { USE_STRIPE, VEHICLE_VIN_PAY_SKIP_MAGIC_STRING } from 'src/utils/constants'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { fetchStripePublicKey } from 'src/utils/stripeHelpers'
import { EnumUserType } from 'src/utils/types'

import { FeeLine } from '../../../types/payment'

import './style.scss'

interface NewTransactionCompleteProps {
  userType: EnumUserType
}

export default function NewTransactionComplete({ userType }: NewTransactionCompleteProps) {
  const globalStore = useContext(store)
  const { role: userRole } = transactionUserInfo(userType, globalStore)

  const {
    vehicle,
    mainUser,
    secondaryUser,
    transactionId,
    shipment,
    paymentTransfer,
    documentEnabled,
    askBuyerPayForTheFees
  } = globalStore.state.createTransaction
  const { vin } = vehicle

  const { navigateToStep, hasBeenShared, shareTransactionId } = useNewTransaction()
  const { openMessage } = useMessage()
  const [clientSecret, setClientSecret] = useState('')
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false)
  // const [transactionFee, setTransactionFee] = useState(0)
  // const [shippingFee, setShippingFee] = useState(0)
  const [total, setTotal] = useState(0)
  const [allFees, setAllFees] = useState<FeeLine[]>([])

  useEffect(() => {
    initialize()
  }, [])

  const initialize = async () => {
    setLoading(true)
    const stripePublishableKey = await fetchStripePublicKey(true)
    if (stripePublishableKey) {
      globalStore.dispatch({
        type: 'UPDATE_STRIPE_PUBLISHABLE_KEY',
        stripePublishableKey
      })
      await initializePaymentSheet(stripePublishableKey)
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'No publishable stripe key found! Aborting! Payments WILL NOT WORK!'
      )
    }
    setLoading(false)
  }

  const fetchPaymentSheetParams = async (
    retry: boolean = false
  ): Promise<{
    paymentIntent: string;
    ephemeralKey: string;
    customer: string;
    fees: FeeLine[];
    total: number;
  }> => {
    let sheetResponse
    try {
      sheetResponse = await (
        await authApiAxiosWithRefresh()
      ).post(
        '/payment/payment-sheet',
        {
          transactionId,
          mainUser,
          secondaryUser,
          vehicle,
          shipment,
          paymentTransfer,
          documentEnabled,
          askBuyerPayForTheFees
        },
        {
          headers: { 'content-type': 'application/json' }
        }
      )
      const {
        paymentIntent, ephemeralKey, customer, transactionId: newTransactionId, fees, shippingFee, total
      } = sheetResponse.data
      setClientSecret(paymentIntent)
      globalStore.dispatch({
        type: 'UPDATE_TRANSACTION_ID',
        transactionId: newTransactionId
      })
      setTotal(total)
      setAllFees(fees)

      return {
        paymentIntent,
        ephemeralKey,
        customer,
        fees,
        total
      }
    } catch (err) {
      if (retry) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch payment sheet params! RETRYING!', err)
        return fetchPaymentSheetParams(false)
      }
      // eslint-disable-next-line no-console
      console.error('Failed to fetch payment sheet params!', err)
      return {
        paymentIntent: '',
        ephemeralKey: '',
        customer: '',
        fees: [],
        total: 0
      }
    }
  }

  const initializePaymentSheet = async (stripePublishableKey: string) => {
    await fetchPaymentSheetParams(true)
  }

  const handlePaymentSuccess = async () => {
    setCheckoutModalOpen(false)
    const passedInfo = {
      transactionId,
      mainUser,
      secondaryUser,
      vehicle,
      shipment,
      paymentTransfer,
      documentEnabled,
      askBuyerPayForTheFees
    }
    try {
      setSubmitting(true)
      await (
        await authApiAxiosWithRefresh()
      ).post('/transaction/store', passedInfo)
      // If needed then we also update the share transaction info
      if (hasBeenShared && shareTransactionId) {
        (await authApiAxiosWithRefresh())
          .post('/transaction/share/complete', {
            shareTransactionId
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.warn(
              `Failed to update share transaction (${shareTransactionId}). Error:`,
              err
            )
            setSubmitting(false)
          })
      }
      globalStore.dispatch({
        type: 'UPDATE_TRANSACTION_COMPLETED',
        complete: true
      })
      // Go to the confirmation page
      setSubmitting(false)
      navigateToStep({ step: EnumNewTransactionParams.OrderConfirm })
    } catch (err) {
      openMessage(
        {
          text1: 'Paperwork generation failed.',
          text2: 'Please try again soon',
          type: EnumMessageType.ERROR
        }
      )
    }
  }

  const handlePaymentFailed = () => {

  }

  const renderFee = (fee: number) => (loading ? (
    <LoadingOutlined className="loading-icon" />
  ) : (
    `$${fee.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || 'Unknown'}`
  ))

  return (
    <Row className="new-transaction-complete-page">
      <Col className="new-transaction-complete-page__content-wrapper" span={24} offset={0}>
        <CheckoutModal
          open={checkoutModalOpen}
          amount={total}
          billingDetail={{}}
          clientSecret={clientSecret}
          onClose={() => setCheckoutModalOpen(false)}
          onSuccess={handlePaymentSuccess}
          onFailed={handlePaymentFailed}
        />
        {
          documentEnabled ? (
            <>
              {
                paymentTransfer.enabled ? (
                  <Typography.Title level={4} className="new-transaction-complete-page__title">
                    The documents will be processed after we go through the vehicle payment flow.
                  </Typography.Title>
                ) : (
                  <Typography.Title level={4} className="new-transaction-complete-page__title">
                    Your documents are being prepared.
                  </Typography.Title>
                )
              }
              <Spacer space={32} />
              <ul className="new-transaction-complete-page__document-list">
                <li>Registration application</li>
                <li>Odometer disclosure</li>
                <li>Bill of sale</li>
              </ul>
            </>
          ) : (
            <Typography.Title level={4} className="new-transaction-complete-page__title">
              Checkout
            </Typography.Title>
          )
        }
        <Spacer space={44} />
        {
          allFees.map((it, idx) => {
            return (
              <div key={idx} className="new-transaction-complete-page__fee-wrapper">
                <div className="fee__title">
                  <div>
                    { it.title }
                  </div>
                  {
                    !!it.subtitle && (
                      <div>
                        {it.subtitle}
                      </div>
                    )
                  }
                </div>
                <div className="fee__amount">
                  {
                    renderFee(it.amount)
                  }
                </div>
              </div>
            )
          })
        }
        {
          allFees.length && (
            <>
              <Spacer space={16} />
              <hr className="new-transaction-complete-page__divider" />
              <Spacer space={16} />
            </>
          )
        }
        <div className="new-transaction-complete-page__fee-wrapper">
          <div className="total-fee__title">TOTAL</div>
          <div className="total-fee__amount">
            {
              USE_STRIPE && vin !== VEHICLE_VIN_PAY_SKIP_MAGIC_STRING
                ? renderFee(total)
                : '$0.00'
            }
          </div>
        </div>
        <Spacer space={32} />
        <div className="new-transaction-complete-page__actions">
          <Button
            type="primary"
            color="secondary"
            block
            onClick={() => setCheckoutModalOpen(true)}
            loading={loading || submitting}
            disabled={!!!clientSecret}
          >
            Pay with Credit Card
          </Button>
          {
            !!vehicle.salePrice && paymentTransfer.enabled && (
              <>
                <Spacer space={24} />
                <div>
                  * Vehicle price:
                  {' '}
                  {renderFee(vehicle.salePrice)}
                </div>
              </>
            )
          }
        </div>

        <Spacer space={44} />
      </Col>
    </Row>
  )
}
