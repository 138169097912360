import React, { ReactNode, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { BottomNavigation, TopBar } from 'src/components/Navigation'
import { HIDDEN_BOTTOM_NAV_PAGES, HIDDEN_TOP_NAV_PAGES } from 'src/router/const'
import { EnumRoute } from 'src/router/types'

import './styles.scss'

interface IWrapper {
  children: ReactNode
  style: React.CSSProperties
}

export default function Wrapper({ children, style }: IWrapper) {
  const {
    pathname
  } = useLocation()
  const params = useParams()
  const { id } = params
  const bottomNavVisible = useMemo(
    () => !HIDDEN_BOTTOM_NAV_PAGES.includes(pathname as EnumRoute) && !(id && EnumRoute.TransactionDetail.replace(':id', id) === pathname),
    [pathname, id]
  )

  const topNavVisible = useMemo(() => !HIDDEN_TOP_NAV_PAGES.includes(pathname as EnumRoute), [pathname])

  return (
    <div className="app-wrapper" style={style}>
      {
        topNavVisible && (
          <TopBar />
        )
      }
      {children}
      {
        bottomNavVisible && (
          <BottomNavigation />
        )
      }
    </div>
  )
}
