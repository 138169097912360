import React, { useContext, useMemo, useState } from 'react'
import { CameraOutlined } from '@ant-design/icons'
import {
  Col, Form, Row, Typography
} from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'

import { Button } from 'src/components/Button'
import { Checkbox } from 'src/components/Checkbox'
import { Input } from 'src/components/Input'
import { BarcodeModal } from 'src/components/Modal'
import { Option, Select } from 'src/components/Select'
import { Spacer } from 'src/components/Spacer'
import { USStateSelect } from 'src/components/USStateSelect'
import { store } from 'src/store/store'
import { US_STATES, ZIP_CODE_REGEX } from 'src/utils/constants'
import {
  LicenseBarcodeInfo,
  licenseDateOfBirthParser,
  licenseSexParser,
  parseDriversLicense
} from 'src/utils/licenseHelpers'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { properCase } from 'src/utils/stringHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'
import { EntityType, EnumMailingAddress } from 'src/views/new-transaction/types'

import ContactMailing from './MailingContact'
import MailingDifference from './MailingDifference'
import MailingOption from './MailingOption'
import { useHandleSubmit } from './NewTransactionUserInformation.hooks'

interface IndividualProps {
  userType: EnumUserType
}

const { Text } = Typography

export default function Individual({ userType }: IndividualProps) {
  const globalStore = useContext(store)
  const { diffMailingAddress, role: userRole } = transactionUserInfo(userType, globalStore)
  const { handleSubmitForm, handleZipUpdate } = useHandleSubmit(userType)
  const [showBarcodeModal, setShowBarcodeModal] = useState(false)
  const [form] = Form.useForm()

  const {
    isInternationalAddress,
    mainUser,
    secondaryUser
  } = globalStore.state.createTransaction

  const mailingType = useMemo(
    () => (diffMailingAddress ? EnumMailingAddress.DifferenceMailing : EnumMailingAddress.SameMailing),
    [diffMailingAddress]
  )

  const onCloseBarcodeModal = () => {
    setShowBarcodeModal(false)
  }

  const renderDifferenceMailingInfo = () => {
    if (userRole !== EnumUserRole.Seller
      && mailingType === EnumMailingAddress.DifferenceMailing) {
      return <MailingDifference entityType={EntityType.Individual} form={form} />
    }
    return null
  }

  const handleBarcodeScanned = (err: any, result: any) => {
    if (result) {
      setShowBarcodeModal(false)
      const licenseInfo = parseDriversLicense(result.getText())
      setValuesFromLicense(licenseInfo)
    }
  }

  const setValuesFromLicense = (licenseInfo: LicenseBarcodeInfo) => {
    const fieldsValue: { [key: string]: any } = {}
    Object.keys(licenseInfo).forEach((key) => {
      switch (key) {
      case 'DAC':
      case 'DAD':
      case 'DCS':
        fieldsValue[licenseInfo[key].name] = licenseInfo[key].content
          ? properCase(licenseInfo[key].content)
          : undefined
        break
      case 'DBC':
        fieldsValue[licenseInfo[key].name] = licenseInfo[key].content
          ? licenseSexParser(licenseInfo[key].content)
          : undefined
        break
      case 'DBA':
      case 'DBB':
        if (licenseInfo[key].content) {
          fieldsValue[licenseInfo[key].name] = dayjs(licenseDateOfBirthParser(licenseInfo[key].content)
            ?.toLocaleDateString('en-GB'), 'DD/MM/YYYY')
        }
        break
      default:
        fieldsValue[licenseInfo[key].name] = licenseInfo[key].content
      }
    })
    form.setFieldsValue(fieldsValue)

    const stateAbbrevRaw = licenseInfo.DAJ?.content.toUpperCase()
    const statesFiltered = US_STATES.filter((s) => s[1] === stateAbbrevRaw)
    // Update state
    globalStore.dispatch({
      type:
        userType === EnumUserType.Main
          ? 'UPDATE_MAIN_USER_DATA_FROM_LICENSE'
          : 'UPDATE_SECONDARY_USER_DATA_FROM_LICENSE',
      license: licenseInfo.DAQ?.content,
      firstName: properCase(licenseInfo.DAC?.content),
      middleName: properCase(licenseInfo.DAD?.content),
      lastName: properCase(licenseInfo.DCS?.content),
      address: licenseInfo.DAG?.content,
      address2: licenseInfo.DAH?.content,
      zip: licenseInfo.DAK?.content && !Number.isNaN(
        parseInt(licenseInfo.DAK?.content.slice(0, 5), 10)
      )
        ? licenseInfo.DAK?.content.slice(0, 5)
        : undefined,
      city: properCase(licenseInfo.DAI?.content),
      state: statesFiltered.length > 0 ? statesFiltered[0][0] : undefined,
      sex: licenseInfo.DBC?.content
        ? licenseSexParser(licenseInfo.DBC.content)
        : undefined,
      dateOfBirth: licenseInfo.DBB?.content
        ? licenseDateOfBirthParser(licenseInfo.DBB.content) // DBL might be old
        : undefined
    })
  }

  const toggleIsInternationalAddress = () => {
    // Always clear the zip and state so that we have it ready for proper info
    globalStore.dispatch({
      type:
        userType === 'main'
          ? 'UPDATE_MAIN_USER_STATE'
          : 'UPDATE_SECONDARY_USER_STATE',
      state: null
    })

    globalStore.dispatch({
      type:
        userType === 'main'
          ? 'UPDATE_MAIN_USER_ZIP'
          : 'UPDATE_SECONDARY_USER_ZIP',
      zip: ''
    })

    globalStore.dispatch({
      type: 'UPDATE_IS_INTERNATIONAL_ADDRESS',
      isInternationalAddress: !isInternationalAddress
    })

    form.setFieldsValue({
      zip: '',
      state: null
    })
  }

  const handleCountryUpdate = (e: any) => {
    const { value } = e.target
    globalStore.dispatch({
      type:
        userType === EnumUserType.Main
          ? 'UPDATE_MAIN_USER_ZIP'
          : 'UPDATE_SECONDARY_USER_ZIP',
      zip: value
    })
  }

  const zipOrCountry = useMemo(() => {
    if (isInternationalAddress && userRole === EnumUserRole.Seller) {
      return (
        <Form.Item
          name="zip"
          rules={[
            { required: true, message: 'Country is required' }
          ]}
          initialValue={
            userType === EnumUserType.Main
              ? mainUser.zip
              : secondaryUser.zip
          }
          shouldUpdate={(prevValues, curValues) => prevValues.zip !== curValues.zip}
        >
          <Input
            placeholder={'Country'}
            onChange={handleCountryUpdate}
          />
        </Form.Item>
      )
    }
    return (
      <Form.Item
        name="zip"
        rules={[
          { required: true, message: 'Zip Code is required' },
          { pattern: ZIP_CODE_REGEX, message: 'Incorrect Zip Code' }
        ]}
        initialValue={
          userType === EnumUserType.Main
            ? mainUser.zip
            : secondaryUser.zip
        }
        shouldUpdate={(prevValues, curValues) => prevValues.zip !== curValues.zip}
      >
        <Input
          maxLength={5}
          placeholder="Zip"
          inputMode={'numeric'}
          onChange={(e) => handleZipUpdate(e.target.value, form)}
        />
      </Form.Item>
    )
  }, [isInternationalAddress, userRole])

  return (
    <>
      <Form
        className="kuaay-form new-transaction-page__buyer-infor-form transaction-form-wrapper"
        onFinish={handleSubmitForm}
        form={form}
      >
        <Form.Item
          name="license"
          initialValue={
            userType === EnumUserType.Main
              ? globalStore.state.createTransaction.mainUser.license
              : globalStore.state.createTransaction.secondaryUser.license
          }
          rules={[
            { required: true, message: 'Driver\'s License Number is required' }
          ]}
          shouldUpdate={(prevValues, curValues) => prevValues.license !== curValues.license}
        >
          <Input
            placeholder="Driver's License Number"
            suffix={(
              <div onClick={() => setShowBarcodeModal(true)}>
                <CameraOutlined className={classNames('kuaay-input-icon')} />
              </div>
            )}
            onChange={(e) => {
              globalStore.dispatch({
                type:
                  userType === EnumUserType.Main
                    ? 'UPDATE_MAIN_USER_LICENSE'
                    : 'UPDATE_SECONDARY_USER_LICENSE',
                license: e.target.value
              })
            }}
          />
        </Form.Item>
        <Spacer space={24} />
        <Row>
          <Col xs={11}>
            <Form.Item
              name="firstName"
              initialValue={
                userType === EnumUserType.Main
                  ? globalStore.state.createTransaction.mainUser.firstName
                  : globalStore.state.createTransaction.secondaryUser.firstName
              }
              rules={[{ required: true, message: 'First Name is required' }]}
              shouldUpdate={(prevValues, curValues) => prevValues.firstName !== curValues.firstName}
            >
              <Input
                placeholder="First Name"
                onChange={(e) => {
                  globalStore.dispatch({
                    type:
                      userType === EnumUserType.Main
                        ? 'UPDATE_MAIN_USER_FIRST_NAME'
                        : 'UPDATE_SECONDARY_USER_FIRST_NAME',
                    firstName: e.target.value
                  })
                }}
              />
            </Form.Item>
          </Col>
          <Col offset={1} xs={12}>
            <Form.Item
              name="middleName"
              initialValue={
                userType === EnumUserType.Main
                  ? globalStore.state.createTransaction.mainUser.middleName
                  : globalStore.state.createTransaction.secondaryUser.middleName
              }
              shouldUpdate={(
                prevValues,
                curValues
              ) => prevValues.middleName !== curValues.middleName}
            >
              <Input
                placeholder="Middle Name"
                onChange={(e) => {
                  globalStore.dispatch({
                    type:
                      userType === EnumUserType.Main
                        ? 'UPDATE_MAIN_USER_MIDDLE_NAME'
                        : 'UPDATE_SECONDARY_USER_MIDDLE_NAME',
                    middleName: e.target.value
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="lastName"
          rules={[{ required: true, message: 'Last Name is required' }]}
          initialValue={
            userType === EnumUserType.Main
              ? globalStore.state.createTransaction.mainUser.lastName
              : globalStore.state.createTransaction.secondaryUser.lastName
          }
          shouldUpdate={(prevValues, curValues) => prevValues.lastName !== curValues.lastName}
        >
          <Input
            placeholder="Last Name"
            onChange={(e) => {
              globalStore.dispatch({
                type:
                  userType === EnumUserType.Main
                    ? 'UPDATE_MAIN_USER_LAST_NAME'
                    : 'UPDATE_SECONDARY_USER_LAST_NAME',
                lastName: e.target.value
              })
            }}
          />
        </Form.Item>
        <Form.Item
          name="address"
          rules={[{ required: true, message: 'Address is required' }]}
          initialValue={
            userType === EnumUserType.Main
              ? globalStore.state.createTransaction.mainUser.address
              : globalStore.state.createTransaction.secondaryUser.address
          }
          shouldUpdate={(prevValues, curValues) => prevValues.address !== curValues.address}
        >
          <Input
            placeholder="Address"
            onChange={(e) => {
              globalStore.dispatch({
                type:
                  userType === EnumUserType.Main
                    ? 'UPDATE_MAIN_USER_ADDRESS'
                    : 'UPDATE_SECONDARY_USER_ADDRESS',
                address: e.target.value
              })
            }}
          />
        </Form.Item>
        <Form.Item
          name="address2"
          initialValue={
            userType === EnumUserType.Main
              ? globalStore.state.createTransaction.mainUser.address2
              : globalStore.state.createTransaction.secondaryUser.address2
          }
          shouldUpdate={(prevValues, curValues) => prevValues.address2 !== curValues.address2}
        >
          <Input
            placeholder="Apartment, suite, unit, etc."
            onChange={(e) => {
              globalStore.dispatch({
                type:
                  userType === EnumUserType.Main
                    ? 'UPDATE_MAIN_USER_ADDRESS2'
                    : 'UPDATE_SECONDARY_USER_ADDRESS2',
                address2: e.target.value
              })
            }}
          />
        </Form.Item>
        <Row>
          <Col xs={8}>
            {
              zipOrCountry
            }
          </Col>
          <Col offset={1} xs={15}>
            <Form.Item
              name="city"
              rules={[{ required: true, message: 'City is required' }]}
              initialValue={
                userType === EnumUserType.Main
                  ? globalStore.state.createTransaction.mainUser.city
                  : globalStore.state.createTransaction.secondaryUser.city
              }
              shouldUpdate={(prevValues, curValues) => prevValues.city !== curValues.city}
            >
              <Input
                placeholder={isInternationalAddress && userRole === EnumUserRole.Seller ? 'City, State/Province' : 'City'}
                onChange={(e) => {
                  globalStore.dispatch({
                    type:
                      userType === EnumUserType.Main
                        ? 'UPDATE_MAIN_USER_CITY'
                        : 'UPDATE_SECONDARY_USER_CITY',
                    city: e.target.value
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {
          (!isInternationalAddress || userRole !== EnumUserRole.Seller) && (
            <Form.Item
              name="state"
              rules={[{ required: true, message: 'State is required' }]}
              initialValue={
                userType === EnumUserType.Main
                  ? globalStore.state.createTransaction.mainUser.state
                  : globalStore.state.createTransaction.secondaryUser.state
              }
              shouldUpdate={(prevValues, curValues) => prevValues.state !== curValues.state}
            >
              <USStateSelect
                placeholder="State"
                onChange={(data) => {
                  globalStore.dispatch({
                    type:
                      userType === EnumUserType.Main
                        ? 'UPDATE_MAIN_USER_STATE'
                        : 'UPDATE_SECONDARY_USER_STATE',
                    state: data
                  })
                }}
              />
            </Form.Item>
          )
        }
        {
          userRole === EnumUserRole.Seller && (
            <>
              <Row>
                <Checkbox checked={isInternationalAddress} onChange={toggleIsInternationalAddress}>
                  This is an international address
                </Checkbox>
              </Row>
              <Spacer space={40} />
            </>
          )
        }
        {
          <MailingOption userType={userType} />
        }
        {
          renderDifferenceMailingInfo()
        }
        <ContactMailing
          userRole={userRole}
          userType={userType}
          entityType={EntityType.Individual}
          form={form}
        />
        <Spacer space={16} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="primary"
          htmlType="submit"
        >
          Continue
        </Button>
      </Form>
      <BarcodeModal
        open={showBarcodeModal}
        title="Scan the barcode from the back of the license using the viewfinder below."
        handleBarcodeScanned={handleBarcodeScanned}
        onClose={onCloseBarcodeModal}
      />
    </>
  )
}
