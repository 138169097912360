import React, { useContext, useEffect, useState } from 'react'
import {
  Link,
  useLocation,
  useNavigate
} from 'react-router-dom'
import {
  ExclamationCircleFilled,
  FileDoneOutlined,
  HomeOutlined,
  MailOutlined,
  // PhoneFilled,
  PlusSquareOutlined,
  UserOutlined
} from '@ant-design/icons'

import logo from 'src/assets/logo.png'
import logoWhite from 'src/assets/logo-white.png'
import { Button } from 'src/components/Button'
import { Navigation } from 'src/components/Navigation'
import { useAuthen } from 'src/hooks/useAuthen'
import { useTheme } from 'src/hooks/useTheme'
import { EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { EnumTheme } from 'src/utils/types'

import './TopBar.scss'

export default function TopBar() {
  const { theme } = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const globalStore = useContext(store)
  const { currentUser } = useAuthen()

  const [isDealer, setIsDealer] = useState<boolean | null>(false)

  useEffect(() => {
    const isOrg = async () => {
      if (!currentUser) {
        return
      }

      try {
        const response = await (await authApiAxiosWithRefresh()).get('user/isOrg')
        setIsDealer(response.data)
      } catch (error) {
        setIsDealer(false)
      }
    }

    isOrg()
  }, [currentUser])

  const topNavItems = [
    {
      title: 'Home',
      route: EnumRoute.Home,
      icon: <HomeOutlined className="nav-item-icon" />,
      activeIcon: <HomeOutlined className="nav-item-icon" style={{ fontSize: '18px', color: '#fff' }} />
    },
    {
      title: 'Checkout',
      route: EnumRoute.NewTransaction,
      icon: <PlusSquareOutlined className="nav-item-icon" />,
      activeIcon: <PlusSquareOutlined className="nav-item-icon" />
    },
    {
      title: 'Shipping',
      route: EnumRoute.ShippingAndTracking,
      icon: <PlusSquareOutlined className="nav-item-icon" />,
      activeIcon: <PlusSquareOutlined className="nav-item-icon" />
    },
    {
      title: 'Account',
      route: EnumRoute.Account,
      icon: <UserOutlined className="nav-item-icon" />,
      activeIcon: <UserOutlined className="nav-item-icon" />,
      alertIcon: globalStore.state.previousTransactions.hasVerifyTimeout ? <ExclamationCircleFilled className="alert-icon" /> : undefined
    }
  ]
  const topNavItemsDealer = [
    {
      title: 'Home',
      route: EnumRoute.Home,
      icon: <HomeOutlined className="nav-item-icon" />,
      activeIcon: <HomeOutlined className="nav-item-icon" style={{ fontSize: '18px', color: '#fff' }} />
    },
    {
      title: 'Checkout',
      route: EnumRoute.NewTransaction,
      icon: <PlusSquareOutlined className="nav-item-icon" />,
      activeIcon: <PlusSquareOutlined className="nav-item-icon" />
    },
    {
      title: 'Shipping',
      route: EnumRoute.ShippingAndTracking,
      icon: <PlusSquareOutlined className="nav-item-icon" />,
      activeIcon: <PlusSquareOutlined className="nav-item-icon" />
    },
    {
      title: 'Account',
      route: EnumRoute.Account,
      icon: <UserOutlined className="nav-item-icon" />,
      activeIcon: <UserOutlined className="nav-item-icon" />,
      alertIcon: globalStore.state.previousTransactions.hasVerifyTimeout ? <ExclamationCircleFilled className="alert-icon" /> : undefined
    },
    {
      title: 'Identity',
      route: EnumRoute.VerifyIdentity,
      icon: <FileDoneOutlined className="nav-item-icon" />,
      activeIcon: <FileDoneOutlined className="nav-item-icon" />
    }
  ]
  return (
    <div className="top-bar">
      <div className="top-bar__content">
        <div className="top-bar__header">
          <Link to={EnumRoute.Home} className="top-bar__logo">
            <img alt="Pacer Logo" src={theme === EnumTheme.Dark ? logoWhite : logo} />
          </Link>
          <div className="top-bar__header__items">
            <Button type="link" href="mailto:info@pacer.co">
              <MailOutlined />
              <span>
                INFO@PACER.CO
              </span>
            </Button>
            {/* <Button type="link" href="tel:0123456789"> */}
            {/*  <PhoneFilled /> */}
            {/*  <span> */}
            {/*    (0123) 456 789 */}
            {/*  </span> */}
            {/* </Button> */}
          </div>
        </div>
        <Navigation
          className="top-bar__navigation"
          slidePosition="bottom"
          items={isDealer ? topNavItemsDealer : topNavItems}
          selected={location.pathname}
          hideSlide
          hideIcon
          onItemClick={(item: any) => navigate(item.route)}
        />
      </div>
    </div>
  )
}
