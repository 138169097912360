import React from 'react'

import { AppState } from 'src/store/store'

import { EnumUserRole, EnumUserType } from './types'
import { fetchKycStatus } from './userHelpers'

export const transactionUserInfo = (
  userType: EnumUserType,
  globalStore: {
    state: AppState;
    dispatch: React.Dispatch<any>;
  }
) => (userType === EnumUserType.Main
  ? globalStore.state.createTransaction.mainUser
  : globalStore.state.createTransaction.secondaryUser)

export const kycStatusOfSeller = async (
  globalStore: {
    state: AppState;
    dispatch: React.Dispatch<any>;
  }
) => {
  const sellerEmail = globalStore.state.createTransaction.mainUser.role === EnumUserRole.Seller
    ? globalStore.state.createTransaction.mainUser.email
    : globalStore.state.createTransaction.secondaryUser.email

  const kycStatusRes = await fetchKycStatus(sellerEmail)
  return kycStatusRes?.status
}
