import React, {
  useContext, useEffect, useState
} from 'react'
import secureLocalStorage from 'react-secure-storage'
import {
  Button as AntButton, Col, Form, Row, Typography
} from 'antd'
import dayjs from 'dayjs'

import { Button } from 'src/components/Button'
import { DatePicker } from 'src/components/DatePicker'
import { Input } from 'src/components/Input'
import { ShipmentRateCard } from 'src/components/Shipment/ShipmentRateCard'
import { Spacer } from 'src/components/Spacer'
import { Switch } from 'src/components/Switch'
import { useDisplay } from 'src/hooks/useDisplay'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useQuery } from 'src/hooks/useQuery'
import {
  CreateShipmentVehicle,
  OperableType, ShipmentAddress,
  ShipmentRate,
  ShipmentVehicleType
} from 'src/types/shipments'
import { DEEP_LINK_URL, ZIP_CODE_REGEX } from 'src/utils/constants'
import { apiAxios } from 'src/utils/customAxios'
import { getStorageItemAsObject } from 'src/utils/localStorage'
import { resolveAddressFromZip } from 'src/utils/locationHelper'
import { getQuoteById } from 'src/utils/shipment'
import { configStyles, decodeStyles } from 'src/utils/shipmentQuoteHelper'

import { VehicleForm } from './VehicleForm'

import './ShipmentQuote.scss'

const { Text } = Typography

const initialShipmentAddress = {
  address: '',
  zip: '',
  city: '',
  state: null
}

const getStorageKey = (vin: string) => `PUB_QUOTE_${vin}`

enum PublicQuoteStep {
  QuoteForm = 'QuoteForm',
  Review = 'Review'
}

interface PublicQuote {
  id: string | null
  step: PublicQuoteStep
  reqId: string
}

interface AdditionalPickupInfo {
  address?: string
  phone?: string
  name?: string
  email?: string
}

const storeQuote = (vin: string, quote: PublicQuote) => {
  secureLocalStorage.setItem(getStorageKey(vin), quote)
}

export const PublicShipment = () => {
  const { isPhoneDevice } = useDisplay()
  const [isLoading, setIsLoading] = useState(true)
  const [isRedirect, setIsRedirect] = useState(false)
  const [requestId, setRequestId] = useState('')
  const [shipmentId, setShipmentId] = useState<string | null>(null)
  const [vehicleInfo, setVehicleInfo] = useState<CreateShipmentVehicle | null>(null)
  const [pickupAddress, setPickupAddress] = useState<ShipmentAddress>({ ...initialShipmentAddress })
  const [deliveryAddress, setDeliveryAddress] = useState<ShipmentAddress>({ ...initialShipmentAddress })
  const [enclosedTrailer, setEnclosedTrailer] = useState<boolean>(true)
  const [shippingDate, setShippingDate] = useState<Date | undefined>(undefined)
  const [rates, setRates] = useState<ShipmentRate[]>([])
  const [step, setStep] = useState(PublicQuoteStep.QuoteForm)
  const [site, setSite] = useState('unknown')
  const [pickupInfo, setPickupInfo] = useState<AdditionalPickupInfo>()

  const [isQuoting, setIsQuoting] = useState(false)
  const { openMessage } = useMessage()
  const [form] = Form.useForm()
  const [editMode, setEditMode] = useState(true)
  const [selectedRateId, setSelectedRateId] = useState<string | null>(null)
  const query = useQuery()

  useEffect(() => {
    const vin = query.get('vin') ?? ''
    const siteQ = query.get('site') ?? 'unknown'
    setSite(siteQ)

    const stylesParams = query.get('styles')
    const style = decodeStyles(stylesParams || '')
    configStyles(style)

    const parseDataFromQuery = () => {
      const yearStr = query.get('year')
      let year: number | null = null
      if (!!yearStr) {
        year = parseInt(yearStr, 10)
      }
      const make = query.get('make') ?? ''
      const model = query.get('model') ?? ''
      const bodyType = query.get('bodyType') as ShipmentVehicleType
      const operable = (query.get('operable') ?? OperableType.OPERABLE) as OperableType
      const pickupZipQ = query.get('pickupZip') as string
      const pickupName = query.get('pickupName') as string
      const pickupPhone = query.get('pickupPhone') as string
      const pickupAddress = query.get('pickupAddress') as string
      const pickupEmail = query.get('pickupEmail') as string
      setPickupInfo({
        email: pickupEmail,
        phone: pickupPhone,
        name: pickupName,
        address: pickupAddress
      })
      if (pickupZipQ) {
        form.setFieldValue('pickupZip', pickupZipQ)
        handleZipUpdate(pickupZipQ)
      }
      setVehicleInfo({
        vin,
        year,
        make,
        model,
        bodyType,
        operable
      })
    }

    const reqId = `${new Date().getTime()}.${Math.random()}`

    let savedQuote: PublicQuote = { id: null, step: PublicQuoteStep.QuoteForm, reqId }

    if (vin) {
      savedQuote = getStorageItemAsObject<PublicQuote>(getStorageKey(vin), savedQuote)
    }

    if (!savedQuote.reqId) {
      savedQuote.reqId = reqId
    }

    if (savedQuote.id) {
      const loadQuoteById = async (id: string) => {
        const shipment = await getQuoteById(id, siteQ, savedQuote.reqId)
        if (shipment) {
          setVehicleInfo(shipment.vehicles[0])
          setPickupAddress(shipment.pickupAddress)
          setDeliveryAddress(shipment.deliveryAddress)
          setShippingDate(shipment.shippingDate)
          setRates(shipment.rates as ShipmentRate[])
          setEnclosedTrailer(shipment.enclosedTrailer)
          setShipmentId(savedQuote.id)
          setSelectedRateId(shipment.selectedRateId as string)
          form.setFieldsValue({
            pickupZip: shipment.pickupAddress.zip,
            deliveryZip: shipment.deliveryAddress.zip,
            enclosedTrailer: shipment.enclosedTrailer,
            shippingDate: dayjs(shipment.shippingDate?.toLocaleDateString('en-GB'), 'DD/MM/YYYY')
          })
          setStep(savedQuote.step)
        } else {
          setStep(PublicQuoteStep.QuoteForm)
          parseDataFromQuery()
        }
      }

      loadQuoteById(savedQuote.id)
    } else {
      parseDataFromQuery()
    }
    setRequestId(savedQuote.reqId)
  }, [])

  useEffect(() => {
    const retrieveAndUpdateZip = async () => {
      const res = await apiAxios.post('shipment/public/ip', {})
      const zipCode = res?.data?.zip_code
      if (zipCode) {
        await handleZipUpdate(zipCode, false)
        await form.validateFields(['deliveryZip'])
      }
    }
    retrieveAndUpdateZip()
  }, [])

  const handleChangeVehicle = (val: CreateShipmentVehicle) => {
    setVehicleInfo(val)
  }

  const handleGetQuote = async () => {
    try {
      setIsQuoting(true)
      const res: any = await apiAxios.post(
        '/shipment/public/quote',
        {
          vehicles: [vehicleInfo],
          pickupAddress: {
            ...pickupAddress,
            address: pickupInfo?.address
          },
          pickupContact: {
            name: pickupInfo?.name,
            phone: pickupInfo?.phone,
            email: pickupInfo?.email
          },
          deliveryAddress,
          enclosedTrailer,
          shippingDate,
          shipmentId,
          userSource: site,
          requestId
        },
        {
          headers: { 'content-type': 'application/json' }
        }
      )

      setRates(res.data.rates)
      setShipmentId(res.data.shipmentId)
      storeQuote(vehicleInfo?.vin ?? '', { id: res.data.shipmentId, step: PublicQuoteStep.Review, reqId: requestId })

      setStep(PublicQuoteStep.Review)
      // setEditMode(false)
    } catch (err: any) {
      openMessage({
        duration: 15,
        type: EnumMessageType.ERROR,
        text1: err?.response?.data?.messages?.join('\n') ?? 'Failed to get quote. Please try again later.'
      })
    }
    setIsQuoting(false)
  }

  const validateShippingDate = (rule: any, value: any) => {
    if (value) {
      const selectedDate = dayjs(value?.toDate())
      const next3Days = dayjs(new Date()).add(3, 'days')

      if (selectedDate && next3Days.diff(selectedDate, 'days') > 0) {
        return Promise.reject(new Error('Shipping Date must be at least three days in advance'))
      }
    }
    return Promise.resolve()
  }

  const validatePickupZip = async (rule: any, value: any) => {
    if (pickupAddress.zip && value.length === 5) {
      const zipResponse = await resolveAddressFromZip(value)
      if (!zipResponse) {
        return Promise.reject(new Error('Invalid Zip Code'))
      }
    }
    return Promise.resolve()
  }

  const validateDeliveryZip = async (rule: any, value: any) => {
    if (deliveryAddress.zip && value.length === 5) {
      const zipResponse = await resolveAddressFromZip(value)
      if (!zipResponse) {
        return Promise.reject(new Error('Invalid Zip Code'))
      }
    }
    return Promise.resolve()
  }

  const handleZipUpdate = async (text: string, pickup: boolean = true) => {
    form.setFieldValue(pickup ? 'pickupZip' : 'deliveryZip', text)
    const zipResponse = await resolveAddressFromZip(text)
    const addr = {
      zip: text,
      city: zipResponse?.city ?? '',
      state: zipResponse?.state ?? '',
      address: ''
    }
    if (pickup) {
      setPickupAddress(addr)
    } else {
      setDeliveryAddress(addr)
    }
  }

  const handleSelectRate = async (rate: ShipmentRate) => {
    setSelectedRateId(rate.identity)
    try {
      await apiAxios.post(
        '/shipment/public/select-rate',
        {
          shipmentId,
          selectedRateId: rate.identity,
          requestId,
          site
        },
        {
          headers: { 'content-type': 'application/json' }
        }
      )
    } catch (err: any) {
      openMessage({
        duration: 15,
        type: EnumMessageType.ERROR,
        text1: err?.response?.data?.messages?.join('\n') ?? 'Failed to update selected rate. Please try again later.'
      })
    }
  }

  const handleContinueBtnClick = async () => {
    if (selectedRateId) {
      setIsRedirect(true)
      setTimeout(() => {
        window.open(`${DEEP_LINK_URL}/shipments/new?shipmentId=${shipmentId}`, '_blank')
        setTimeout(() => {
          setIsRedirect(false)
        }, 2000)
      }, 1000)
    }
  }

  const onEnclosedTrailerSwitchChange = () => {
    if (!isQuoting) {
      setEnclosedTrailer(!enclosedTrailer)
    }
  }

  const onClickEditBtn = () => {
    setSelectedRateId(null)
    storeQuote(vehicleInfo?.vin ?? '', { id: shipmentId, step: PublicQuoteStep.QuoteForm, reqId: requestId })
    setRates([])
    setStep(PublicQuoteStep.QuoteForm)

    // setEditMode(true)
  }

  const renderEditBtn = () => (
    <AntButton type="link" onClick={() => onClickEditBtn()}>
      Edit
    </AntButton>
  )

  const renderShipmentFeeCard = (item: ShipmentRate, index: number) => {
    const className = isPhoneDevice
      ? ''
      : index % 2 === 0
        ? 'shipment-page-quote__shipment-card-left'
        : 'shipment-page-quote__shipment-card-right'
    return (
      <Col sm={12} xs={16} className={className}>
        <ShipmentRateCard
          item={item}
          isSelected={item.identity === selectedRateId}
          onClick={() => {
            handleSelectRate(item)
          }}
        />
      </Col>
    )
  }

  const renderShipmentFeeCards = () => {
    return rates?.map((item: ShipmentRate, index: number) => {
      if (isPhoneDevice) {
        return (
          <Row key={index} className="shipment-page-quote__shipment-card">
            { renderShipmentFeeCard(item, index) }
          </Row>
        )
      }
      if (index % 2 === 0) {
        const nextItem = rates[index + 1]
        return (
          <Row key={index} className="shipment-page-quote__shipment-card">
            { renderShipmentFeeCard(item, index) }
            { nextItem && (
              renderShipmentFeeCard(nextItem, index + 1)
            ) }
          </Row>
        )
      }
      return null
    })
  }

  return (
    <Row className="shipment-quote-page">
      <Col className="shipment-quote-page__form-wrapper flex-col" span={20} offset={2}>
        <Form
          className="shipment-quote-page__form kuaay-form"
          onFinish={handleGetQuote}
          form={form}
        >
          { (
            <>
              <h2 style={{ margin: 0 }}>Vehicle shipping</h2>
              <Spacer className="flex-grow-1" space={16} />
              <div className="shipment-page-quote__form__car-info">
                <Text className="shipment-page-quote__form__car-info-label">
                  Vehicle information:
                </Text>
                { step === PublicQuoteStep.Review && (
                  renderEditBtn()
                ) }
              </div>
              <Spacer className="flex-grow-1" space={12} />

              { step === PublicQuoteStep.QuoteForm ? (
                <>
                  <VehicleForm
                    form={form}
                    vehicleInfo={vehicleInfo}
                    idx={0}
                    loading={isQuoting}
                    onChange={handleChangeVehicle}
                  />
                  <Spacer className="flex-grow-1" space={16} />
                  <Text className="shipment-page-quote__form__car-info-label">
                    Shipping information:
                  </Text>
                  <Spacer className="flex-grow-1" space={8} />
                  <Row>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="pickupZip"
                        initialValue={
                          pickupAddress.zip
                        }
                        rules={[
                          { required: true, message: 'Zip Code is required' },
                          { pattern: ZIP_CODE_REGEX, message: 'Incorrect Zip Code' },
                          { validator: validatePickupZip }
                        ]}
                        shouldUpdate={(prevValues, curValues) => prevValues.deliveryZip !== curValues.deliveryZip}
                      >
                        <Input
                          className="shipment-page-quote__form__zip-input"
                          maxLength={5}
                          placeholder="Pickup Zip code"
                          inputMode={'numeric'}
                          disabled={isQuoting}
                          suffix={pickupAddress.city && pickupAddress.state && (
                            <span>
                              { pickupAddress.city }
                              ,
                              { ' ' }
                              { pickupAddress.state }
                            </span>
                          )}
                          onChange={(e) => handleZipUpdate(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={{ offset: 1, span: 11 }}>
                      <Form.Item
                        name="deliveryZip"
                        initialValue={
                          deliveryAddress.zip
                        }
                        rules={[
                          { required: true, message: 'Zip Code is required' },
                          { pattern: ZIP_CODE_REGEX, message: 'Incorrect Zip Code' },
                          { validator: validateDeliveryZip }
                        ]}
                        shouldUpdate={(prevValues, curValues) => prevValues.deliveryZip !== curValues.deliveryZip}
                      >
                        <Input
                          className="shipment-page-quote__form__zip-input"
                          maxLength={5}
                          placeholder="Destination Zip code"
                          inputMode={'numeric'}
                          disabled={isQuoting}
                          suffix={deliveryAddress.city && deliveryAddress.state && (
                            <span>
                              { deliveryAddress.city }
                              ,
                              { ' ' }
                              { deliveryAddress.state }
                            </span>
                          )}
                          onChange={(e) => handleZipUpdate(e.target.value, false)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="shippingDate"
                        initialValue={shippingDate}
                        rules={[
                          { required: true, message: 'Shipping Date is required' },
                          { validator: validateShippingDate }
                        ]}
                        shouldUpdate={(
                          prevValues,
                          curValues
                        ) => prevValues.shippingDate !== curValues.shippingDate}
                      >
                        <DatePicker
                          onChange={(d) => {
                            setShippingDate(d?.toDate())
                          }}
                          placeholder="Shipping Date"
                          disabled={isQuoting}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={{ offset: 1, span: 11 }}>
                      <div className="shipment-page-quote__form__switch" onClick={onEnclosedTrailerSwitchChange}>
                        <Switch
                          className="shipment-page-quote__form__switch-btn"
                          checked={enclosedTrailer}
                          disabled={isQuoting}
                          onChange={onEnclosedTrailerSwitchChange}
                        />
                        <Text className="shipment-page-quote__form__switch-label">
                          Enclosed trailer
                        </Text>
                      </div>
                    </Col>
                  </Row>

                  <Spacer className="flex-grow-1" space={16} />

                </>

              ) : (
                <div className="shipment-page-quote__form__car-info">
                  <div>
                    <Text className="shipment-page-quote__form__car-info-label">
                      VIN:
                      { ' ' }
                    </Text>
                    <Text className="shipment-page-quote__form__car-info-value">
                      { vehicleInfo?.vin }
                    </Text>
                  </div>
                  <div>
                    <Text className="shipment-page-quote__form__car-info-label">
                      Year:
                      { ' ' }
                    </Text>
                    <Text className="shipment-page-quote__form__car-info-value">
                      { vehicleInfo?.year }
                    </Text>
                  </div>
                  <div>
                    <Text className="shipment-page-quote__form__car-info-label">
                      Make:
                      { ' ' }
                    </Text>
                    <Text className="shipment-page-quote__form__car-info-value">
                      { vehicleInfo?.make }
                    </Text>
                  </div>
                  <div>
                    <Text className="shipment-page-quote__form__car-info-label">
                      Model:
                      { ' ' }
                    </Text>
                    <Text className="shipment-page-quote__form__car-info-value">
                      { vehicleInfo?.model }
                    </Text>
                  </div>
                  <div>
                    <Text className="shipment-page-quote__form__car-info-label">
                      Vehicle type:
                      { ' ' }
                    </Text>
                    <Text className="shipment-page-quote__form__car-info-value">
                      { vehicleInfo?.bodyType }
                    </Text>
                  </div>
                  <Spacer className="flex-grow-1" space={16} />

                  <div>
                    <Text className="shipment-page-quote__form__car-info-label">
                      Shipping information:
                      { ' ' }
                      { renderEditBtn() }
                    </Text>
                  </div>
                  <Spacer className="flex-grow-1" space={4} />
                  <div>
                    <Text className="shipment-page-quote__form__car-info-label">
                      Pickup Zip code:
                    </Text>
                    { pickupAddress.zip && (
                      <Text className="shipment-page-quote__form__car-info-value">
                        { ' ' }
                        { `${pickupAddress.zip} ${pickupAddress.city}, ${pickupAddress.state}` }
                      </Text>
                    ) }
                  </div>
                  <Spacer className="flex-grow-1" space={4} />
                  <div>
                    <Text className="shipment-page-quote__form__car-info-label">
                      Drop off Zip code:
                    </Text>
                    { deliveryAddress.zip && (
                      <Text className="shipment-page-quote__form__car-info-value">
                        { ' ' }
                        { `${deliveryAddress.zip} ${deliveryAddress.city}, ${deliveryAddress.state}` }
                      </Text>
                    ) }
                  </div>
                  <Spacer className="flex-grow-1" space={4} />
                  <div>
                    <Text className="shipment-page-quote__form__car-info-label">
                      Shipping date:
                      { ' ' }
                    </Text>
                    { shippingDate && (
                      <Text className="shipment-page-quote__form__car-info-value">
                        { shippingDate.toLocaleDateString('en-GB') }
                      </Text>
                    ) }
                  </div>
                  <Spacer className="flex-grow-1" space={4} />
                  <div>
                    <Text className="shipment-page-quote__form__car-info-label">
                      Enclosed trailer:
                      { ' ' }
                    </Text>
                    <Text className="shipment-page-quote__form__car-info-value">
                      { enclosedTrailer ? 'Yes' : 'No' }
                    </Text>
                  </div>

                  <Spacer className="flex-grow-1" space={16} />
                  { !!rates?.length && (
                    <div>
                      <Text>Please select one of following price options:</Text>
                      <Spacer className="flex-grow-1" space={4} />
                      { renderShipmentFeeCards() }
                    </div>
                  ) }
                </div>
              ) }
            </>
          ) }
        </Form>
        <Spacer className="flex-grow-1" space={16} />
        <Row className="d-flex flex-col align-center">
          { step === PublicQuoteStep.QuoteForm ? (
            <Button
              className="shipment-quote-page__btn"
              type="primary"
              color="primary"
              onClick={() => form.submit()}
              disabled={isQuoting}
              loading={isQuoting}
            >
              Get Quote
            </Button>
          ) : isRedirect ? (
            <Text><span className="text--primary">You will be redirected to Shipping app shortly...</span></Text>
          ) : (
            <Button
              className="shipment-quote-page__btn"
              type="primary"
              color="primary"
              onClick={handleContinueBtnClick}
              disabled={!selectedRateId}
            >
              Continue
            </Button>
          )}
        </Row>
      </Col>
    </Row>
  )
}
