import React, {
  useContext, useEffect, useMemo
} from 'react'
import { Col, Row, Typography } from 'antd'

import { Button } from 'src/components/Button'
import { ShipmentDetail } from 'src/components/Shipment/ShipmentDetail'
import { Spacer } from 'src/components/Spacer'
import { useNewShipment } from 'src/hooks/useNewShipment'
import { EnumNewShipmentStep } from 'src/router/types'
import { store } from 'src/store/store'
import { ShipmentInfo } from 'src/types/shipments'

import './NewShipmentOrderConfirm.scss'

export const NewShipmentOrderConfirm = () => {
  const { resetNewShipmentState, navigateToStep } = useNewShipment()
  const globalStore = useContext(store)

  const {
    createShipment
  } = globalStore.state

  useEffect(() => {
    if (!createShipment.complete) {
      navigateToStep({ step: EnumNewShipmentStep.MainInfo })
    }

    return () => {
      resetNewShipmentState()
    }
  }, [])
  const shipment = useMemo(() => ({
    shipmentId: createShipment.shipmentId,
    shippingDate: createShipment.shippingDate,
    pickupAddress: createShipment.pickupAddress,
    pickupContact: createShipment.pickupContact,
    deliveryAddress: createShipment.deliveryAddress,
    deliveryContact: createShipment.deliveryContact,
    vehicles: createShipment.vehicles,
    price: createShipment.price,
    creationDate: new Date(),
    complete: createShipment.complete
  } as unknown as ShipmentInfo), [createShipment])

  useEffect(() => {
    initialize()
  }, [])

  const initialize = async () => {

  }

  const handleClickNewQuote = () => {
    resetNewShipmentState()
    navigateToStep({ step: EnumNewShipmentStep.MainInfo })
  }

  return (
    <Row className="new-shipment-order-confirm-page">
      <Col className="new-shipment-order-confirm-page__content-wrapper" span={20} offset={2}>
        <Typography.Title level={4} className="new-shipment-order-confirm-page__title">
          <div>Your shipping request</div>
          <div>is being processed</div>
        </Typography.Title>
        { shipment.complete && <ShipmentDetail shipment={shipment} /> }
        <Spacer space={24} />
        <div className="d-flex flex-col align-center new-shipment-order-confirm-page__actions">
          <Button
            type="primary"
            color="info"
            className="start-new-quote-btn"
            onClick={handleClickNewQuote}
          >
            Start a new quote
          </Button>
        </div>
      </Col>
    </Row>
  )
}
