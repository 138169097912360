import React, {
  useCallback, useContext, useEffect, useMemo, useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CameraOutlined, DeleteOutlined, MoreOutlined, QuestionCircleOutlined
} from '@ant-design/icons'
import { Dropdown, MenuProps, Space } from 'antd'

import { HeaderLayout } from 'src/components/HeaderLayout'
import { BarcodeModal, InfoModal } from 'src/components/Modal'
import ConfirmModal from 'src/components/Modal/ConfirmModal'
import { useAuthen } from 'src/hooks/useAuthen'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams, EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'
import { DEEP_LINK_URL } from 'src/utils/constants'
import { EnumShareStep, EnumUserRole, EnumUserType } from 'src/utils/types'

import { useDisplay } from '../../hooks/useDisplay'

import NewTransactionComplete from './new-transaction-complete/NewTransactionComplete'
import NewTransactionFeeInfo from './new-transaction-fee-information/NewTransactionFeeInfo'
import NewTransactionKnownIssuesAgreement
  from './new-transaction-known-issues-agreement/NewTransactionKnownIssuesAgreement'
import NewTransactionKnownIssuesDisclosure
  from './new-transaction-known-issues-disclosure/NewTransactionKnownIssuesDisclosure'
import NewTransactionKyc from './new-transaction-kyc/NewTransactionKyc'
import NewTransactionMainVehicleInfo from './new-transaction-main-vehicle-information/NewTransactionMainVehicleInfo'
import NewTransactionNeededInfo from './new-transaction-needed-information/NewTransactionNeededInfo'
import NewTransactionNextUser from './new-transaction-next-user/NewTransactionNextUser'
import NewTransactionOrderConfirmation from './new-transaction-order-confirmation/NewTransactionOrderConfirmation'
import NewTransactionPaymentProtectionDisclosure
  from './new-transaction-payment-protection-disclosure/NewTransactionPaymentProtectionDisclosure'
import NewTransactionServicesSelection
  from './new-transaction-services-selection/NewTransactionServicesSelection'
import NewTransactionShareConfirmation from './new-transaction-share-confirmation/NewTransactionShareConfirmation'
import NewTransactionShippingInfo from './new-transaction-shipping-information/NewTransactionShippingInfo'
import NewTransactionSignature from './new-transaction-signature/NewTransactionSignature'
import NewTransactionSignatureAppInfo from './new-transaction-signature-app-information/NewTransactionSignatureAppInfo'
import NewTransactionSignatureOdometerInfo
  from './new-transaction-signature-odometer-information/NewTransactionSignatureOdometerInfo'
import NewTransactionSpecificVehicleInfo
  from './new-transaction-specific-vehicle-information/NewTransactionSpecificVehicleInfo'
import NewTransactionUserInformation from './new-transaction-user-information/NewTransactionUserInformation'
import NewTransactionUserRoles from './new-transaction-user-roles/NewTransactionUserRoles'

import './NewTransaction.scss'

export default function NewTransaction() {
  const {
    step,
    userType,
    userRole,
    hasBeenShared,
    shareUrl,
    shareStep,
    shareTransactionId,
    goPrevStep,
    isFirstSharedPage,
    navigateToStep
  } = useNewTransaction()
  const { isMobileDevice, windowWidth } = useDisplay()
  const navigate = useNavigate()
  const globalStore = useContext(store)
  const { createTransaction } = globalStore.state
  const { paymentTransfer } = createTransaction
  const { email } = useAuthen()
  const [forwardedStep, setForwardedStep] = useState<string|null>(null)
  const [showBarcodeModal, setShowBarcodeModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showStartOverModal, setShowStartOverModal] = useState(false)

  const infoModalWidth = useMemo(() => (
    isMobileDevice
      ? windowWidth - 48
      : step === EnumNewTransactionParams.ServicesSelection
        ? 700
        : 400
  ), [isMobileDevice, windowWidth, step])

  const isRightItemDropdown = useMemo(
    () => (
      step === EnumNewTransactionParams.OrderConfirm
      || step === EnumNewTransactionParams.Complete
      || step === EnumNewTransactionParams.ShareConfirmation
      || isFirstSharedPage
    ),
    [step, isFirstSharedPage]
  )

  const hasGoBack = useMemo(
    () => !!step
      && !isFirstSharedPage,
    [step, userType, hasBeenShared, userRole, shareStep, paymentTransfer]
  )

  useEffect(() => {
    if (!step) {
      checkAndNavigateToLastStep()
    }
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    if (!step) {
      if (forwardedStep === EnumNewTransactionParams.UserInformation && userType === EnumUserType.Main) {
        // do nothing
        setForwardedStep(null)
      } else {
        checkAndNavigateToLastStep()
      }
    } else if (step === EnumNewTransactionParams.UserInformation
      && userType === EnumUserType.Main
    ) {
      if (!createTransaction.mainUser.email) {
        globalStore.dispatch({
          type: 'UPDATE_MAIN_USER_EMAIL',
          email
        })
      }
    }
  }, [step])

  const checkAndNavigateToLastStep = () => {
    if (createTransaction.complete) {
      globalStore.dispatch({
        type: 'RESET_TRANSACTION_STATE'
      })
      return
    }
    if (createTransaction.lastParams?.step) {
      navigateToStep(
        {
          step: createTransaction.lastParams.step,
          userType: createTransaction.lastParams.userType ?? EnumUserType.Main,
          hasBeenShared: createTransaction.lastParams.hasBeenShared,
          shareTransactionId: createTransaction.lastParams.shareTransactionId
        }
      )
    }
  }

  const handleClickStartOver = () => {
    if (step === EnumNewTransactionParams.OrderConfirm || step === EnumNewTransactionParams.ShareConfirmation) {
      handleStartOver()
    } else {
      setShowStartOverModal(true)
    }
  }

  const handleGoBack = () => {
    setForwardedStep(step)
    goPrevStep()
  }

  const items = useMemo(() => [
    {
      key: '1',
      label: (
        <div
          className="new-transaction__dropdown-menu-item"
          onClick={() => setShowInfoModal(true)}
        >
          <QuestionCircleOutlined className="new-transaction__dropdown-menu-item-icon" />
          <span className="new-transaction__dropdown-menu-item-text">Info</span>
        </div>
      )
    },
    {
      type: 'divider'
    },
    {
      key: '2',
      label: (
        <div
          className="new-transaction__dropdown-menu-item"
          onClick={handleClickStartOver}
        >
          <DeleteOutlined className="new-transaction__dropdown-menu-item-icon" />
          <span className="new-transaction__dropdown-menu-item-text">
            {
              (step === EnumNewTransactionParams.OrderConfirm || step === EnumNewTransactionParams.Complete)
                ? 'Start over'
                : 'Exit'
            }
          </span>
        </div>
      )
    }
  ], [step])

  const renderRightItems = useCallback(() => (
    <>
      {
        !step && (
          <CameraOutlined onClick={() => setShowBarcodeModal(true)} />
        )
      }
      {
        isRightItemDropdown ? (
          <Dropdown
            menu={{ items } as MenuProps}
            trigger={['click']}
            placement="bottomRight"
          >
            <span onClick={(e) => e.preventDefault()}>
              <Space>
                <MoreOutlined />
              </Space>
            </span>
          </Dropdown>
        ) : step !== EnumNewTransactionParams.Kyc && (
          <QuestionCircleOutlined onClick={() => setShowInfoModal(true)} />
        )
      }
    </>
  ), [step, isRightItemDropdown])

  const infoText = useMemo(() => {
    if (step === EnumNewTransactionParams.UserRoles) {
      return 'Please select your role:\n\n'
        + '• Buyer — You are buying the vehicle,\n'
        + '• Seller — You are selling the vehicle'
        // + '• Self-Transfer — You are transferring a car you own to a VT registration,'
        // + ' OR you have a bill of sale and the seller is not present'
    }
    let text = 'Enter your information to get\nthe transaction process started! 💪'
    switch (step) {
    case EnumNewTransactionParams.MainVehicleInfo:
      text = 'Enter your information to get\nthe transaction process started! 💪\n\n'
            + 'The state entered here will be the state in which the vehicle is currently registered.'
      break
    case EnumNewTransactionParams.ShareConfirmation:
      text = 'Congrats on completing your part of the paperwork!'
        + ' You\'ll have to wait for the other person to complete their portion before you can pay.'
      break
    case EnumNewTransactionParams.KnownIssuesDisclosure:
      text = 'Please enter, in detail, all known issues to disclose to the buyer. Any issues not mentioned that appear during the vehicle delivery will be able to be disputed by the buyer. Shipping carrier-related issues will be covered by Pacer.'
      break
    case EnumNewTransactionParams.OrderConfirm:
      text = 'Congrats on your purchase! View your PDF below or start a new transaction.'
      break
    case EnumNewTransactionParams.ServicesSelection:
      // text = 'Kuaay\'s payment protection protects buyers in the event of an undisclosed issue.\n\n'
      //   + 'When a payment is sent to the seller. Kuaay holds the funds until the vehicle is delivered. '
      //   + 'If the vehicle arrives as described, the funds are released by Kuaay to the seller.\n\n'
      //   + 'In the event of an undisclosed issue, Kuaay will work with both buyer and seller to resolve any disputes.'
      text = [
        'Please select the service(s) you would like to use.',
        '',
        'Please click on the question icon beside each service to get an explanation of that service.'
      ].join('\n')
      break
    default:
      break
    }
    return text
  }, [step])

  const handleStartOver = () => {
    globalStore.dispatch({
      type: 'RESET_TRANSACTION_STATE'
    })
    navigate(EnumRoute.NewTransaction)
    setShowStartOverModal(false)
  }

  const handleQrCode = async (err: any, result: any) => {
    if (result?.getText()) {
      const url = result.getText().replace(`${DEEP_LINK_URL}/`, '')
      const splitURL = url.split('/')
      if (splitURL.length === 0) {
        return
      }
      if (splitURL[0] === 'link') {
        splitURL.shift()
      }
      switch (splitURL[0]?.toLowerCase()) {
      case 'shared-transactions':
        navigate(`/shared-transactions/${splitURL[1]}`)
        break

      case 'createtransaction':
      case 'create':
        navigate(EnumRoute.NewTransaction)
        break
      default:
        break
      }
    }
  }

  const title = useMemo(() => {
    switch (step) {
    // @TODO: REMOVE THIS WHEN VT PAPERWORK IS FIGURED OUT!
    case undefined:
    case null:
    case EnumNewTransactionParams.TempStop:
      return 'Notice'
    case EnumNewTransactionParams.FeeInfo:
      return 'Fee Information'
    case EnumNewTransactionParams.ServicesSelection:
    case EnumNewTransactionParams.Kyc:
      return 'New Transaction'
    default:
      return 'Create'
    }
  }, [step])

  const subtitle = useMemo(() => {
    switch (step) {
    // @TODO: REMOVE THIS WHEN VT PAPERWORK IS FIGURED OUT!
    case EnumNewTransactionParams.TempStop:
      return 'Temporary Stop'
    // @TODO: REMOVE THIS WHEN VT PAPERWORK IS FIGURED OUT!
    case EnumNewTransactionParams.UserRoles:
      return 'Transaction paperwork'
    case EnumNewTransactionParams.SignatureApplication:
      return 'Application Disclosure'
    case EnumNewTransactionParams.SignatureOdometer:
      return 'Odometer Disclosure'
    case EnumNewTransactionParams.FeeInfo:
      return 'Transaction state fees'
    case EnumNewTransactionParams.ServicesSelection:
      return 'Choose your services'
    case EnumNewTransactionParams.PaymentProtectionDisclosure:
      return 'Payment Protection Disclosure'
    case EnumNewTransactionParams.Kyc:
      return 'Verify Seller Identity'
    case EnumNewTransactionParams.KnownIssuesDisclosure:
      return 'Known Issues Disclosure'
    case EnumNewTransactionParams.KnownIssuesAgreement:
      return 'Known Issues Agreement'
    case EnumNewTransactionParams.OrderConfirm:
    case EnumNewTransactionParams.NextUser:
    case EnumNewTransactionParams.UserInformation:
    case EnumNewTransactionParams.MainVehicleInfo:
    case EnumNewTransactionParams.NeededInfo:
    case EnumNewTransactionParams.SpecificVehicleInfo:
    case EnumNewTransactionParams.Signature:
    case EnumNewTransactionParams.ShareConfirmation:
    case EnumNewTransactionParams.Complete:
    default:
      // @TODO: RETURN THIS TO NORMAL (`Transaction paperwork`) ONCE VT IS FIGURED OUT!
      return 'Transaction paperwork'
    }
  }, [step])

  const component = useMemo(() => {
    switch (step) {
    // case EnumNewTransactionParams.TempStop:
    //   // @TODO: REMOVE THIS WHEN VT PAPERWORK IS FIGURED OUT!
    //   return <TempStopTransactions />
    case EnumNewTransactionParams.UserRoles:
      return <NewTransactionUserRoles />
    case EnumNewTransactionParams.ServicesSelection:
      return <NewTransactionServicesSelection />
    case EnumNewTransactionParams.UserInformation:
      return <NewTransactionUserInformation userType={userType} />
    case EnumNewTransactionParams.Kyc:
      return <NewTransactionKyc userType={userType} />
    case EnumNewTransactionParams.MainVehicleInfo:
      return <NewTransactionMainVehicleInfo userType={userType} />
    case EnumNewTransactionParams.NeededInfo:
      return <NewTransactionNeededInfo userType={userType} />
    case EnumNewTransactionParams.KnownIssuesDisclosure:
      return <NewTransactionKnownIssuesDisclosure />
    case EnumNewTransactionParams.KnownIssuesAgreement:
      return <NewTransactionKnownIssuesAgreement />
    case EnumNewTransactionParams.PaymentProtectionDisclosure:
      return <NewTransactionPaymentProtectionDisclosure userType={userType} />
    case EnumNewTransactionParams.SpecificVehicleInfo:
      return <NewTransactionSpecificVehicleInfo userType={userType} />
    case EnumNewTransactionParams.Signature:
      return (
        <NewTransactionSignature
          userType={userType}
          shareTransactionId={shareTransactionId}
          hasBeenShared={!hasBeenShared}
        />
      )
    case EnumNewTransactionParams.SignatureApplication:
      return (
        <NewTransactionSignatureAppInfo
          userType={userType}
        />
      )
    case EnumNewTransactionParams.SignatureOdometer:
      return (
        <NewTransactionSignatureOdometerInfo
          userType={userType}
          shareTransactionId={shareTransactionId}
          hasBeenShared={!hasBeenShared}
        />
      )
    case EnumNewTransactionParams.NextUser:
      return (
        <NewTransactionNextUser
          userType={userType}
        />
      )
    case EnumNewTransactionParams.ShippingInfo:
      return (
        <NewTransactionShippingInfo />
      )
    case EnumNewTransactionParams.FeeInfo:
      return (
        <NewTransactionFeeInfo
          userType={userType}
          shareTransactionId={shareTransactionId}
          hasBeenShared={!hasBeenShared}
        />
      )
    case EnumNewTransactionParams.ShareConfirmation:
      return (
        <NewTransactionShareConfirmation
          userType={userType}
          shareTransactionId={shareTransactionId}
          hasBeenShared={!hasBeenShared}
          shareUrl={shareUrl}
        />
      )
    case EnumNewTransactionParams.Complete:
      return (
        <NewTransactionComplete
          userType={userType}
        />
      )
    case EnumNewTransactionParams.OrderConfirm:
      return (
        <NewTransactionOrderConfirmation
          userType={userType}
        />
      )
    default:
      // // @TODO: RETURN THIS TO NORMAL (`<NewTransactionUserRoles />`) ONCE VT IS FIGURED OUT!
      // return <TempStopTransactions />
      return <NewTransactionUserRoles />
    }
  }, [userType, step, shareTransactionId, hasBeenShared, shareUrl])

  const startOverTitle = useMemo(() => {
    switch (step) {
    case EnumNewTransactionParams.Complete:
      return 'Are you sure?'
    default:
      return 'Are you sure you want to stop filling out this transaction?'
    }
  }, [step])

  return (
    <>
      <HeaderLayout
        title={title}
        hasGoBack={hasGoBack}
        subtitle={subtitle}
        rightItems={renderRightItems}
        onGoBack={handleGoBack}
      >
        {component}
      </HeaderLayout>
      <ConfirmModal
        open={showStartOverModal}
        title={startOverTitle}
        okText={step === EnumNewTransactionParams.Complete ? 'Start Over' : 'Exit'}
        onOk={handleStartOver}
        onCancel={() => setShowStartOverModal(false)}
      />
      <BarcodeModal
        title="Scan the QR code below."
        open={showBarcodeModal}
        handleBarcodeScanned={handleQrCode}
        onClose={() => setShowBarcodeModal(false)}
      />
      <InfoModal
        text={infoText}
        open={showInfoModal}
        width={infoModalWidth}
        onClose={() => setShowInfoModal(false)}
      />
    </>
  )
}
