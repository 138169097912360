import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Modal } from 'antd'

import { Button } from 'src/components/Button'
import { TextArea } from 'src/components/Input'
import { useDisplay } from 'src/hooks/useDisplay'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { EnumRoute } from 'src/router/types'
import { ShipmentInfo, ShipmentStatus } from 'src/types/shipments'
import { formatMoney } from 'src/utils/common'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { getShipmentStatusDescription } from 'src/utils/shipment'

import { Spacer } from '../Spacer'

import './ShipmentDetail.scss'

interface ShipmentDetailProps {
  shipment: ShipmentInfo
}

export const ShipmentDetail = ({ shipment }: ShipmentDetailProps) => {
  const [showModificationModal, setShowModificationModal] = useState(false)
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { openMessage } = useMessage()
  const { isMobileDevice } = useDisplay()
  const [isLoading, setIsLoading] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)

  const vehicleList = useMemo(() => shipment?.vehicles?.map((vehicle, idx) => (
    <li key={idx} className="shipment-card__vehicle-info-item">
      <div className="shipment-card__vehicle-info-container">
        <div className="shipment-card__vehicle-info__detail">
          {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
          &nbsp;
        </div>
        <div className="shipment-card__vehicle-info__vin">
          (
          {vehicle.vin}
          )
        </div>
      </div>
    </li>
  )), [shipment])

  const handleClickModificationBtn = () => {
    setShowModificationModal(true)
  }

  const handleClickViewDocumentDetails = () => {
    navigate(`${EnumRoute.Transactions}/${shipment.transactionId}`)
  }

  const handleSubmitModificationRequest = async ({ message }: { message: string }) => {
    try {
      await (
        await authApiAxiosWithRefresh()
      ).post('/shipment/help', {
        shipmentId: shipment?.shipmentId,
        message
      })
      openMessage({ type: EnumMessageType.SUCCESS, text1: 'Thank you. We will process your request and contact you via your email.' })
      handleCloseModificationModal()
    } catch (e) {
      openMessage({ type: EnumMessageType.ERROR, text1: 'Failed to send the request. Please try again later.' })
    }
  }

  const handleCloseModificationModal = () => {
    setShowModificationModal(false)
    form.resetFields()
  }

  const renderModificationModal = () => (
    <Modal
      className="shipment-modification-modal"
      open={showModificationModal}
      centered
      width={isMobileDevice ? '90%' : '700px'}
      closable={false}
      footer={null}
    >
      {
        showModificationModal && (

          <Form
            className="shipment-modification-modal__form"
            initialValues={{ email: '' }}
            form={form}
            onFinish={handleSubmitModificationRequest}
          >
            <div className="shipment-modification-modal__label">Please let us know what can we help you:</div>
            <Spacer space={10} />
            <Form.Item
              name="message"
              rules={[{ required: true, message: 'Please input your message.' }]}
            >
              <TextArea
                placeholder="ex. Please help me to change the Shipping date to MM/DD/YYYY etc."
                rows={10}
                disabled={isRequesting}
              />
            </Form.Item>
            <div className="shipment-modification-modal__label">
              * Changes may result in price increases.
            </div>
            <Spacer space={20} />
            <div className="shipment-modification-modal__form-actions">
              <Button
                type="primary"
                color="danger"
                disabled={isRequesting}
                onClick={handleCloseModificationModal}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                color="primary"
                disabled={isRequesting}
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        )
      }
    </Modal>
  )

  return (
    <>
      {
        renderModificationModal()
      }
      <div className="shipment-detail">
        <div className="shipment-detail__section shipment-detail__vehicles-section">
          <div className="shipment-detail__section__label">
            Vehicles:
          </div>
          <div className="shipment-detail__section__content">
            <ul className="shipment-detail__vehicle-list">
              {vehicleList}
            </ul>
          </div>
        </div>
        <div className="shipment-detail__section shipment-detail__shipping-date-section">
          <div className="shipment-detail__section__label">
            Estimated pickup date:
          </div>
          <div className="shipment-detail__section__content">
            {shipment.shippingDate.toLocaleDateString()}
          </div>
        </div>
        <div className="shipment-detail__section shipment-detail__shipping-cost-section">
          <div className="shipment-detail__section__label">
            Shipping cost:
          </div>
          <div className="shipment-detail__section__content">
            {formatMoney(shipment.price)}
          </div>
        </div>
        <div className="shipment-detail__section shipment-detail__delivery-address-section">
          <div className="shipment-detail__section__label">
            Delivery to:
          </div>
          <div className="shipment-detail__section__content">
            <div>
              {shipment.deliveryContact.name}
            </div>
            <div>
              {shipment.deliveryAddress.address}
            </div>
            <div>
              {shipment.deliveryAddress.city}
              {', '}
              {shipment.deliveryAddress.state}
              {' '}
              {shipment.deliveryAddress.zip}
            </div>
          </div>
        </div>
        <div className="shipment-detail__section shipment-detail__status-section">
          <div className="shipment-detail__section__label">
            Status:
          </div>
          <div className="shipment-detail__section__content">
            {getShipmentStatusDescription(shipment)}
          </div>
          <div className="shipment-detail__section__content">
            {
              [
                ShipmentStatus.CREATED,
                ShipmentStatus.COMPLETED,
                ShipmentStatus.PICKED_UP,
                ShipmentStatus.DRIVER_ASSIGNED,
                ShipmentStatus.CANCELED
              ].some((it) => it === shipment.status) && (
                <div>
                  on
                  {' '}
                  { shipment.lastStatusUpdatedDate?.toLocaleDateString()}
                </div>
              )
            }
          </div>
        </div>
        <div className="shipment-detail__section shipment-detail__link-section">
          <div className="shipment-detail__section__label">
            Tracking link:
          </div>
          <div className="shipment-detail__section__content">
            {
              shipment.publicLink ? (
                <a target="__blank" href={shipment.publicLink}>{shipment.publicLink}</a>
              ) : (
                <span>Shipping link is being generated. Check back here to see it once it is ready.</span>
              )
            }
          </div>
        </div>
        <div className="shipment-detail__section shipment-detail__actions-section">
          {
            shipment.status !== ShipmentStatus.COMPLETED && (
              <Button
                type="primary"
                color="primary"
                className="shipment-detail__actions__btn"
                onClick={handleClickModificationBtn}
              >
                Request a change
              </Button>
            )
          }
          {
            shipment.transactionId && (
              <Button
                type="primary"
                color="info"
                className="shipment-detail__actions__btn"
                onClick={handleClickViewDocumentDetails}
              >
                View transaction details
              </Button>
            )
          }
        </div>
      </div>
    </>
  )
}
