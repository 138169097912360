import React, { useMemo, useState } from 'react'
import {
  Col, Form, Modal, Row, Typography
} from 'antd'

import { Button } from 'src/components/Button'
import { Input, TextArea } from 'src/components/Input'
import { Spacer } from 'src/components/Spacer'
import { useAuthen } from 'src/hooks/useAuthen'
import { useDisplay } from 'src/hooks/useDisplay'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'

import { EnumUserRole } from '../../utils/types'

import './TransactionReportIssueModal.scss'

const { Text } = Typography

interface FormValues {
  email: string
  message: string
}

interface TransactionReportIssueModalProps {
  open: boolean
  userRole: EnumUserRole
  onClose: () => void
  transactionId: string
}

export default function TransactionReportIssueModal({
  open, onClose, transactionId, userRole
}: TransactionReportIssueModalProps) {
  const { currentUser } = useAuthen()
  const { isMobileDevice, windowWidth } = useDisplay()
  const { openMessage } = useMessage()
  const [alreadyContacted, setAlreadyContacted] = useState<boolean|undefined>(undefined)
  const targetUserRole = useMemo(
    () => (userRole === EnumUserRole.Buyer ? EnumUserRole.Seller : EnumUserRole.Buyer),
    [userRole]
  )

  const modalWidth = useMemo(
    () => (isMobileDevice ? windowWidth - 48 : (alreadyContacted === true ? 800 : 400)),
    [isMobileDevice, windowWidth, alreadyContacted]
  )

  const [form] = Form.useForm()

  const handleReportIssue = async (values: FormValues) => {
    const { email, message } = values
    try {
      await (await authApiAxiosWithRefresh()).post(`/payment-transfer/${transactionId}/report-issue`, {
        message
      })
      if (onClose) {
        form.resetFields()
        onClose()
      }
      openMessage({
        text1: 'We have received your report.',
        text2: 'Someone from our team will reach out to you.',
        type: EnumMessageType.SUCCESS
      })
    } catch (error: any) {
      openMessage({
        text1: 'There was an error while sending the report.',
        text2: error.message,
        type: EnumMessageType.ERROR
      })
    }
  }

  const onCancel = () => {
    setAlreadyContacted(undefined)
    form.resetFields()
    if (onClose) {
      onClose()
    }
  }

  const handleContactedAnswerNo = () => {
    setAlreadyContacted(false)
  }

  const handleContactedAnswerYes = () => {
    setAlreadyContacted(true)
  }

  return (
    <Modal
      title="Report an issue"
      className="transaction-report-issue-modal"
      width={modalWidth}
      centered
      closable={true}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <div className="transaction-report-issue-modal__content">
        {
          alreadyContacted === undefined ? (
            <div>
              <div>
                Have you contacted the
                {' '}
                {targetUserRole}
                {' '}
                and attempted a resolution?
              </div>
              <Spacer space={24} />
              <div className="d-flex justify-space-between">
                <Button size="small" type="primary" color="secondary" onClick={handleContactedAnswerNo}>
                  No
                </Button>
                <Button size="small" type="primary" color="primary" onClick={handleContactedAnswerYes}>
                  Yes
                </Button>
              </div>
            </div>
          ) : (alreadyContacted === true ? (
            <Form
              className="transaction-report-issue-modal__info-form"
              onFinish={handleReportIssue}
              form={form}
            >
              <Col xs={12}>
                <Form.Item
                  name="email"
                  initialValue={currentUser?.email}
                >
                  <Input
                    placeholder="Email"
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Spacer space={24} />
              <Text>Let us know how we can help:</Text>
              <Spacer space={8} />
              <Form.Item
                name="message"
                rules={[{ required: true, message: 'Please input your description about the issue.' }]}
              >
                <TextArea
                  placeholder="Describe your issue here"
                  rows={10}
                />
              </Form.Item>
              <div className="transaction-report-issue-modal__btn">
                <Button
                  className="transaction-report-issue-modal__submit-btn"
                  type="primary"
                  color="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <div>
                Please contact the
                {' '}
                {targetUserRole}
                {' '}
                first and allow them to propose a solution.
              </div>
              <Spacer space={24} />
              <div className="d-flex flex-col justify-center">
                <Button
                  size="small"
                  type="primary"
                  color="primary"
                  onClick={onCancel}
                >
                  Ok
                </Button>
              </div>
            </div>
          ))
        }
      </div>
    </Modal>
  )
}
