import React from 'react'

import { Option, Select } from 'src/components/Select'
import { VEHICLE_BODY_TYPES } from 'src/utils/constants'

export default function VehicleBodyType(props: any) {
  const renderVehicleBodyTypes = () => VEHICLE_BODY_TYPES.map((vehicleBodyType) => (
    <Option key={`vehicleBodyType.${vehicleBodyType}`} value={vehicleBodyType}>{vehicleBodyType}</Option>
  ))
  return (
    <Select {...props}>
      {renderVehicleBodyTypes()}
    </Select>
  )
}
