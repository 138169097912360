import React, { useContext, useMemo } from 'react'
import { Col, Form, Row } from 'antd'

import { Button } from 'src/components/Button'
import { Checkbox } from 'src/components/Checkbox'
import { Input } from 'src/components/Input'
import { Option, Select } from 'src/components/Select'
import { Spacer } from 'src/components/Spacer'
import { USStateSelect } from 'src/components/USStateSelect'
import { store } from 'src/store/store'
import { ZIP_CODE_REGEX } from 'src/utils/constants'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'
import { EntityType, EnumMailingAddress } from 'src/views/new-transaction/types'

import ContactMailing from './MailingContact'
import MailingDifference from './MailingDifference'
import MailingOption from './MailingOption'
import { useHandleSubmit } from './NewTransactionUserInformation.hooks'

interface BusinessProps {
  userType: EnumUserType
}

export default function Business({ userType }: BusinessProps) {
  const { handleSubmitForm, handleZipUpdate } = useHandleSubmit(userType)
  const globalStore = useContext(store)
  const { diffMailingAddress, role: userRole } = transactionUserInfo(userType, globalStore)
  const [form] = Form.useForm()

  const {
    isInternationalAddress,
    mainUser,
    secondaryUser
  } = globalStore.state.createTransaction

  const mailingType = useMemo(
    () => (diffMailingAddress ? EnumMailingAddress.DifferenceMailing : EnumMailingAddress.SameMailing),
    [diffMailingAddress]
  )

  const renderDifferenceMailingInfo = () => {
    if (userRole !== EnumUserRole.Seller
      && mailingType === EnumMailingAddress.DifferenceMailing) {
      return <MailingDifference entityType={EntityType.Individual} form={form} />
    }
    return null
  }

  const toggleIsInternationalAddress = () => {
    // Always clear the zip and state so that we have it ready for proper info
    globalStore.dispatch({
      type:
        userType === EnumUserType.Main
          ? 'UPDATE_MAIN_USER_STATE'
          : 'UPDATE_SECONDARY_USER_STATE',
      state: null
    })

    globalStore.dispatch({
      type:
        userType === EnumUserType.Main
          ? 'UPDATE_MAIN_USER_ZIP'
          : 'UPDATE_SECONDARY_USER_ZIP',
      zip: ''
    })

    globalStore.dispatch({
      type: 'UPDATE_IS_INTERNATIONAL_ADDRESS',
      isInternationalAddress: !isInternationalAddress
    })

    form.setFieldsValue({
      zip: '',
      state: null
    })
  }

  const handleCountryUpdate = (e: any) => {
    const { value } = e.target
    globalStore.dispatch({
      type:
        userType === EnumUserType.Main
          ? 'UPDATE_MAIN_USER_ZIP'
          : 'UPDATE_SECONDARY_USER_ZIP',
      zip: value
    })
  }

  const zipOrCountry = useMemo(() => {
    if (isInternationalAddress && userRole === EnumUserRole.Seller) {
      return (
        <Form.Item
          name="zip"
          rules={[
            { required: true, message: 'Country is required' }
          ]}
          initialValue={
            userType === EnumUserType.Main
              ? mainUser.zip
              : secondaryUser.zip
          }
          shouldUpdate={(prevValues, curValues) => prevValues.zip !== curValues.zip}
        >
          <Input
            placeholder={'Country'}
            onChange={handleCountryUpdate}
          />
        </Form.Item>
      )
    }
    return (
      <Form.Item
        name="zip"
        rules={[
          { required: true, message: 'Zip Code is required' },
          { pattern: ZIP_CODE_REGEX, message: 'Incorrect Zip Code' }
        ]}
        initialValue={
          userType === EnumUserType.Main
            ? mainUser.zip
            : secondaryUser.zip
        }
        shouldUpdate={(prevValues, curValues) => prevValues.zip !== curValues.zip}
      >
        <Input
          maxLength={5}
          placeholder="Zip"
          inputMode={'numeric'}
          onChange={(e) => handleZipUpdate(e.target.value, form)}
        />
      </Form.Item>
    )
  }, [isInternationalAddress, userRole])

  return (
    <Form
      className="kuaay-form new-transaction-page__buyer-infor-form transaction-form-wrapper"
      form={form}
      onFinish={handleSubmitForm}
    >
      <Form.Item
        name="license"
        rules={[
          { required: true, message: 'Employer Identification Number is required' }
        ]}
        initialValue={
          userType === EnumUserType.Main
            ? mainUser.license
            : secondaryUser.license
        }
        shouldUpdate={(prevValues, curValues) => prevValues.license !== curValues.license}
      >
        <Input
          placeholder="Employer Identification Number (EIN)"
          onChange={(e) => {
            globalStore.dispatch({
              type:
                userType === EnumUserType.Main
                  ? 'UPDATE_MAIN_USER_LICENSE'
                  : 'UPDATE_SECONDARY_USER_LICENSE',
              license: e.target.value
            })
          }}
        />
      </Form.Item>
      <Spacer space={24} />
      <Col xs={24}>
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: 'Business Name is required' }]}
          initialValue={
            userType === EnumUserType.Main
              ? mainUser.firstName
              : secondaryUser.firstName
          }
          shouldUpdate={(prevValues, curValues) => prevValues.firstName !== curValues.firstName}
        >
          <Input
            placeholder="Business Name"
            onChange={(e) => {
              globalStore.dispatch({
                type:
                  userType === EnumUserType.Main
                    ? 'UPDATE_MAIN_USER_FIRST_NAME'
                    : 'UPDATE_SECONDARY_USER_FIRST_NAME',
                firstName: e.target.value
              })
            }}
          />
        </Form.Item>
      </Col>
      <Form.Item
        name="address"
        rules={[{ required: true, message: 'Business Address is required' }]}
        initialValue={
          userType === EnumUserType.Main
            ? mainUser.address
            : secondaryUser.address
        }
        shouldUpdate={(prevValues, curValues) => prevValues.address !== curValues.address}
      >
        <Input
          placeholder="Business Address"
          onChange={(e) => {
            globalStore.dispatch({
              type:
                userType === EnumUserType.Main
                  ? 'UPDATE_MAIN_USER_ADDRESS'
                  : 'UPDATE_SECONDARY_USER_ADDRESS',
              address: e.target.value
            })
          }}
        />
      </Form.Item>
      <Form.Item
        name="address2"
        initialValue={
          userType === EnumUserType.Main
            ? mainUser.address2
            : secondaryUser.address2
        }
        shouldUpdate={(prevValues, curValues) => prevValues.address2 !== curValues.address2}
      >
        <Input
          placeholder="Business Apartment, suite, unit, etc."
          onChange={(e) => {
            globalStore.dispatch({
              type:
                userType === EnumUserType.Main
                  ? 'UPDATE_MAIN_USER_ADDRESS2'
                  : 'UPDATE_SECONDARY_USER_ADDRESS2',
              address2: e.target.value
            })
          }}
        />
      </Form.Item>
      <Row>
        <Col xs={8}>
          {
            zipOrCountry
          }
        </Col>
        <Col offset={1} xs={15}>
          <Form.Item
            name="city"
            rules={[{ required: true, message: 'Business City is required' }]}
            initialValue={
              userType === EnumUserType.Main
                ? mainUser.city
                : secondaryUser.city
            }
            shouldUpdate={(prevValues, curValues) => prevValues.city !== curValues.city}
          >
            <Input
              placeholder={isInternationalAddress && userRole === EnumUserRole.Seller ? 'Business City, State/Province' : 'Business City'}
              onChange={(e) => {
                globalStore.dispatch({
                  type:
                    userType === EnumUserType.Main
                      ? 'UPDATE_MAIN_USER_CITY'
                      : 'UPDATE_SECONDARY_USER_CITY',
                  city: e.target.value
                })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      {
        (!isInternationalAddress || userRole !== EnumUserRole.Seller) && (
          <Form.Item
            name="state"
            rules={[{ required: true, message: 'Business State is required' }]}
            initialValue={
              userType === EnumUserType.Main
                ? mainUser.state
                : secondaryUser.state
            }
            shouldUpdate={(prevValues, curValues) => prevValues.state !== curValues.state}
          >
            <USStateSelect
              placeholder="Business State"
              onChange={(data) => {
                globalStore.dispatch({
                  type: userType === EnumUserType.Main ? 'UPDATE_MAIN_USER_STATE' : 'UPDATE_SECONDARY_USER_STATE',
                  state: data
                })
              }}
            />
          </Form.Item>
        )
      }
      {
        userRole === EnumUserRole.Seller && (
          <>
            <Row>
              <Checkbox checked={isInternationalAddress} onChange={toggleIsInternationalAddress}>
                This is an international address
              </Checkbox>
            </Row>
            <Spacer space={40} />
          </>
        )
      }
      {
        <MailingOption userType={userType} />
      }
      {
        renderDifferenceMailingInfo()
      }
      <ContactMailing
        userRole={userRole}
        userType={userType}
        entityType={EntityType.Business}
        form={form}
      />
      <Spacer space={16} />
      <Button
        className="new-transaction-page__continue-button"
        type="primary"
        htmlType="submit"
      >
        Continue
      </Button>
    </Form>
  )
}
