import { useMemo } from 'react'
import { Col, Row, Typography } from 'antd'
import { QRCodeSVG } from 'qrcode.react'

import logo from 'src/assets/favicon.png'
import { Button } from 'src/components/Button'
import { KModal } from 'src/components/Modal'
import { DEEP_LINK_URL } from 'src/utils/constants'

import './TransactionQrModal.scss'

interface TransactionQrModalProps {
  open: boolean
  onClose: () => void
  sharingId: string
}

export default function TransactionQrModal({ open, onClose, sharingId }: TransactionQrModalProps) {
  const shareLink = useMemo(() => `${DEEP_LINK_URL}/shared-transactions/${sharingId}`, [sharingId])
  return (
    <KModal
      title="QR Code"
      subtitle="Viewer"
      className="transaction-qr-modal"
      open={open}
      onClose={onClose}
    >
      <Row>
        <Col span={20} offset={2} className="transaction-qr-modal__content">
          <Typography.Title level={3}>Share transaction</Typography.Title>
          <div className="transaction-qr-modal__description">
            Have the other party scan this QR code to pick up and finish the transaction
          </div>
          <QRCodeSVG
            size={300}
            className="transaction-qr-modal__qr-code"
            value={shareLink}
            imageSettings={{
              src: logo,
              x: undefined,
              y: undefined,
              height: 28,
              width: 28,
              excavate: true
            }}
          />
          <p>Sharing the following link:</p>
          <a
            href={shareLink}
            target="_blank"
            rel="noreferrer"
            className="transaction-qr-modal__link"
          >
            {shareLink}
          </a>
          <Button
            type="primary"
            color="primary"
            className="transaction-qr-modal__continue-btn"
            onClick={onClose}
          >
            Close
          </Button>
        </Col>
      </Row>
    </KModal>
  )
}
