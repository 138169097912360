import React, {
  useContext, useEffect, useState
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Col,
  Form, Row
} from 'antd'

import { HeaderLayout } from 'src/components/HeaderLayout'
import { ShipmentDetail } from 'src/components/Shipment/ShipmentDetail'
import { useAuthen } from 'src/hooks/useAuthen'
import { useDisplay } from 'src/hooks/useDisplay'
import { useMessage } from 'src/hooks/useMessage'
import { store } from 'src/store/store'
import { EnumTransactionAction } from 'src/store/types'
import { ShipmentInfo } from 'src/types/shipments'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { shipmentRawToShipmentInfo } from 'src/utils/shipment'

import './ShipmentDetailPage.scss'

export const ShipmentDetailPage = () => {
  const { id } = useParams()
  const { openMessage } = useMessage()
  const { isMobileDevice } = useDisplay()
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [shipment, setShipment] = useState<ShipmentInfo|undefined>(undefined)
  const [showModificationModal, setShowModificationModal] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    authApiAxiosWithRefresh()
      .then((axiosInst) => axiosInst.get(
        `/shipment/retrieve/${id}`
      ))
      .then((res) => {
        setShipment(shipmentRawToShipmentInfo(res.data as ShipmentInfo))
        setIsLoading(false)
      })
  }, [id])

  const globalStore = useContext(store)
  const {
    isAuthenLoaded
  } = useAuthen()

  const onRefresh = React.useCallback(() => {
    if (isAuthenLoaded) {
      globalStore.dispatch({
        type: EnumTransactionAction.FetchShipments
      })
    }
  }, [isAuthenLoaded])

  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  return (
    <HeaderLayout hasGoBack title="Shipping Details">
      <Row className="shipment-detail-page">
        <Col span={20} offset={2}>
          {
            shipment && (
              <ShipmentDetail shipment={shipment} />
            )
          }
        </Col>
      </Row>
    </HeaderLayout>
  )
}
