import React from 'react'
import {
  Button as AntButton,
  ButtonProps
} from 'antd'
import classNames from 'classnames'

import './styles.scss'

export default function Button(props: ButtonProps) {
  const { className, ...restProps } = props
  return (
    <AntButton
      className={classNames('kuaay-btn', className)}
      {...restProps}
    />
  )
}
