export const formatPhoneNumber = (
  phoneNumberString: string,
  signUpForm: boolean = false
) => {
  let cleaned = (`${phoneNumberString}`).replace(/\D/g, '')
  cleaned = cleaned.substring(0, 11)
  if (!signUpForm) {
    // Try a normal match
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})(\d)?$/)
    if (match) {
      const intlCode = match[1] ? '+1 ' : ''
      return [intlCode, match[2], '-', match[3], '-', match[4]].join('')
    }
  } else {
    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') // Format the phone number
  }

  return null
}
