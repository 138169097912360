import { RefObject, useRef, useState } from 'react'

export function useStateRef<T>(defaultValue: T):[T, (val: T) => void, RefObject<T>] {
  const [val, setValState] = useState<T>(defaultValue)
  const ref = useRef<T>(val)
  const setVal = (newVal: T) => {
    setValState(newVal)
    ref.current = newVal
  }
  return [val, setVal, ref]
}
