import React from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'

import { StateProvider } from './store/store'
import App from './App'

import './styles/common.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <HashRouter>
    <StateProvider>
      <App />
    </StateProvider>
  </HashRouter>
)
