import secureLocalStorage from 'react-secure-storage'

function getStorageItemAsObject<T>(key: string, defaultValue: T): T {
  const it = secureLocalStorage.getItem(key)
  if (!it) {
    return defaultValue
  }
  if (typeof it === 'string') {
    try {
      return JSON.parse(it) as T
    } catch {
      return defaultValue
    }
  }
  return it as unknown as T
}

export { getStorageItemAsObject }
