import React, { useEffect, useMemo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Col, Row, Typography } from 'antd'
import { AxiosResponse } from 'axios'

import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { VehicleInfo } from 'src/types/transactions'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { FeeInfos } from 'src/utils/types'

import './TransactionFeeDetail.scss'

interface TransactionFeeDetailProps {
  vehicleInfo: VehicleInfo
  showDocumentMailingNote?: boolean
}

export default function TransactionFeeDetail({ vehicleInfo }: TransactionFeeDetailProps) {
  const [isLoading, setIsLoading] = useState(true)
  const { openMessage } = useMessage()
  const [regFee, setRegFee] = useState(0)
  const [taxFee, setTaxFee] = useState(0)
  const [titleFee, setTitleFee] = useState(0)
  const [warrantyFee, setWarrantyFee] = useState(0)
  const [totalFee, setTotalFee] = useState(0)

  useEffect(() => {
    const bootstrapAsync = async () => {
      if (vehicleInfo) {
        setIsLoading(true)
        try {
          const feeInfos = (await (
            await authApiAxiosWithRefresh()
          ).post('/transaction/fees', vehicleInfo)) as AxiosResponse<FeeInfos>
          setRegFee(feeInfos.data.regFee)
          setTaxFee(feeInfos.data.taxFee)
          setTitleFee(feeInfos.data.titleFee)
          setWarrantyFee(feeInfos.data.warrantyFee)
          setTotalFee(feeInfos.data.totalFee)
        } catch (err) {
          openMessage({
            text1: 'Failed to calculate fees',
            text2: 'Please try again in a few minutes',
            type: EnumMessageType.ERROR
          })
        }
        setIsLoading(false)
      }
    }
    bootstrapAsync()
  }, [vehicleInfo])

  const feeItems = useMemo(() => {
    const res = [
      {
        title: 'Registration fee:',
        amount: isLoading
          ? <LoadingOutlined className="loading-modal__icon" />
          : `$${regFee.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || 'Unknown'}`
      },
      {
        title: '6% sales tax:',
        amount: isLoading
          ? <LoadingOutlined className="loading-modal__icon" />
          : `$${taxFee.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || 'Unknown'}`
      },
      {
        title: 'Title fee:',
        amount: isLoading
          ? <LoadingOutlined className="loading-icon" />
          : `$${titleFee.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || 'Unknown'}`
      }
    ]

    if (vehicleInfo?.condition === 'New') {
      res.push({
        title: 'Warranty fee:',
        amount: isLoading
          ? <LoadingOutlined className="loading-icon" />
          : `$${warrantyFee.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || 'Unknown'}`
      })
    }
    return res
  }, [isLoading])

  return (
    <Col span={20} offset={2} className="transaction-fee-info-wrapper">
      <Row className="transaction-fee-info__section">
        <Typography.Title level={5}>
          Your total due to the Vermont DMV is:
        </Typography.Title>
        {
          feeItems.map((it, idx) => (
            <div className="transaction-fee-line-item" key={idx}>
              <div className="transaction-fee-line-item__icon" />
              <div className="transaction-fee-line-item__title">
                {it.title}
              </div>
              <div className="transaction-fee-line-item__amount">
                {
                  it.amount
                }
              </div>

            </div>
          ))
        }

        <div className="transaction-fee__total-fee-wrapper">
          <div className="transaction-fee__total-fee__title">
            Total:
          </div>
          <div className="transaction-fee__total-fee__amount">
            {isLoading ? (
              <LoadingOutlined className="loading-icon" />
            ) : (
              `$${totalFee.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || 'Unknown'}`
            )}
          </div>

        </div>
        <div className="transaction-fee__note-wrapper">
          The check or money order should be made out to:
          {' '}
          <b>Vermont DMV</b>
        </div>

      </Row>
      <hr className="transaction-fee__divider" />
      <Row className="transaction-fee-info__section transaction-fee-info__mail-section">
        <Typography.Title level={5}>
          Mail your check and documents to:
        </Typography.Title>
        <Col span={24} className="transaction-fee-info__mail-address">
          <div>Vermont DMV</div>
          <div>120 State Street</div>
          <div>Montpelier, Vermont 05603-0001</div>
        </Col>
      </Row>
      <hr className="transaction-fee__divider" />
      <Row className="transaction-fee-info__section transaction-fee-info__note-section">
        <Col span={24}>
          <div className="transaction-fee-info__note-item">
            <b>NOTE:</b>
            <span>&nbsp;The buyer</span>
            &nbsp;
            <b><span className="text-underline">must sign the ink</span></b>
            <span>&nbsp;at the bottom of the Vermont Registration form (VD-119).</span>
          </div>
          <div className="transaction-fee-info__note-item">
            <b>NOTE:</b>
            <span>&nbsp;With your check or money order make sure you mail in</span>
            &nbsp;
            <b><span className="text-underline">two copies of the applications, odometer statement, and bill of sale.</span></b>
          </div>
          <div className="transaction-fee-info__note-item">
            <b>NOTE:</b>
            <span>&nbsp;You</span>
            &nbsp;
            <b><span className="text-underline">must</span></b>
            <span>
                    &nbsp;mail in a copy of your current registration with this application.
            </span>
          </div>
        </Col>
      </Row>

    </Col>
  )
}
