import React, {
  ChangeEvent, useContext, useEffect, useMemo, useState
} from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import {
  Col, Form, Row, Typography
} from 'antd'
import dayjs from 'dayjs'

import { Button } from 'src/components/Button'
import { Checkbox } from 'src/components/Checkbox'
import { DatePicker } from 'src/components/DatePicker'
import { Input, TextArea } from 'src/components/Input'
import { ShipmentRateCard } from 'src/components/Shipment/ShipmentRateCard'
import { Spacer } from 'src/components/Spacer'
import { useDisplay } from 'src/hooks/useDisplay'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useNewShipment } from 'src/hooks/useNewShipment'
import { useQuery } from 'src/hooks/useQuery'
import { EnumNewShipmentStep } from 'src/router/types'
import { store } from 'src/store/store'
import {
  CreateShipmentVehicle,
  OperableType, ShipmentInfo,
  ShipmentRate
} from 'src/types/shipments'
import {
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX,
  ZIP_CODE_REGEX
} from 'src/utils/constants'
import { apiAxios, authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { EvenBus, EvenBusType } from 'src/utils/evenBus'
import { resolveAddressFromZip } from 'src/utils/locationHelper'
import { formatPhoneNumber } from 'src/utils/phoneHelpers'

import { VehicleForm } from '../VehicleForm'

import './NewShipmentMainInfo.scss'

const { Text } = Typography

export const NewShipmentMainInfo = () => {
  const { isPhoneDevice } = useDisplay()
  const globalStore = useContext(store)
  const [quoteForm] = Form.useForm()
  const [additionalForm] = Form.useForm()
  const [isQuoting, setIsQuoting] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { openMessage } = useMessage()
  const { navigateToStep, resetNewShipmentState } = useNewShipment()
  const query = useQuery()
  const shipmentIdQ = useMemo(() => query.get('shipmentId'), [query])

  const {
    vehicles,
    shippingDate,
    pickupAddress,
    pickupContact,
    deliveryAddress,
    deliveryContact,
    shipmentId,
    enclosedTrailer,
    price,
    complete,
    transaction,
    notesToDriver,
    rates,
    selectedRateId
  } = globalStore.state.createShipment

  useEffect(() => {
    EvenBus.on(EvenBusType.RESET_NEW_SHIPMENT_STATE, resetForm)

    return () => {
      EvenBus.off(EvenBusType.RESET_NEW_SHIPMENT_STATE, resetForm)
    }
  }, [])

  useEffect(() => {
    const loadShipmentById = async (shipmentId: string) => {
      try {
        const res = await (
          await authApiAxiosWithRefresh()
        ).get(`/shipment/retrieve/${shipmentId}`)
        const {
          pickupAddress,
          pickupContact,
          deliveryAddress,
          shippingDate,
          rates,
          selectedRateId,
          enclosedTrailer,
          vehicles,
          complete,
          stripeTransactionId
        } = res.data

        if (complete || stripeTransactionId) {
          openMessage({
            duration: 15,
            type: EnumMessageType.ERROR,
            text1: 'This shipping request already completed.'
          })
          return
        }

        quoteForm.setFieldsValue({
          pickupZip: pickupAddress.zip,
          deliveryZip: deliveryAddress.zip,
          enclosedTrailer,
          shippingDate: dayjs(new Date(shippingDate)?.toLocaleDateString('en-GB'), 'DD/MM/YYYY')
        })
        additionalForm.resetFields()
        additionalForm.setFieldsValue({
          pickupEmail: pickupContact.email,
          pickupAddress: pickupAddress.address,
          pickupName: pickupContact.name,
          pickupPhone: pickupContact.phone
        })
        globalStore.dispatch({
          type: 'UPDATE_SHIPMENT_DATA_FROM_QUERY',
          shippingDate: new Date(shippingDate),
          pickupAddress,
          pickupContact,
          deliveryAddress,
          enclosedTrailer,
          vehicles,
          rates,
          selectedRateId,
          shipmentId
        })
      } catch (e) {
        resetNewShipmentState()
        resetForm()
      }
    }
    if (shipmentIdQ) {
      resetNewShipmentState()
      resetForm()
      loadShipmentById(shipmentIdQ)
    }
  }, [shipmentIdQ])

  const resetForm = () => {
    quoteForm.resetFields()
    additionalForm.resetFields()
  }

  const initShippingDateValue = useMemo(() => {
    if (!!shippingDate) {
      return dayjs(shippingDate?.toLocaleDateString('en-GB'), 'DD/MM/YYYY')
    }
    return null
  }, [shippingDate])

  const handleGetQuote = async () => {
    try {
      setIsQuoting(true)
      const res: any = await (
        await authApiAxiosWithRefresh()
      ).post(
        '/shipment/quote',
        {
          vehicles,
          pickupAddress,
          deliveryAddress,
          enclosedTrailer,
          shippingDate,
          shipmentId,
          transactionId: transaction?.transactionId
        },
        {
          headers: { 'content-type': 'application/json' }
        }
      )
      globalStore.dispatch({
        type: 'UPDATE_SHIPMENT_PRICE',
        price: res.data.price
      })
      globalStore.dispatch({
        type: 'UPDATE_SHIPMENT_ID',
        shipmentId: res.data.shipmentId
      })
      globalStore.dispatch({
        type: 'UPDATE_SHIPMENT_SELECTED_RATE_ID',
        selectedRateId: null
      })
      globalStore.dispatch({
        type: 'UPDATE_SHIPMENT_RATES',
        rates: res.data.rates
      })
    } catch (err: any) {
      openMessage({
        duration: 15,
        type: EnumMessageType.ERROR,
        text1: err?.response?.data?.messages?.join('\n') ?? 'Failed to get quote. Please try again later.'
        // text2: err.message
      })
    }
    setIsQuoting(false)
  }

  const resetShipmentPrice = () => {
    globalStore.dispatch({
      type: 'RESET_QUOTE'
    })
  }

  const validateShippingDate = (rule: any, value: any) => {
    if (value) {
      const selectedDate = dayjs(value?.toDate())
      const next3Days = dayjs(new Date()).add(3, 'days')

      if (selectedDate && next3Days.diff(selectedDate, 'days') > 0) {
        return Promise.reject(new Error('Shipping Date must be at least three days in advance'))
      }
    }
    return Promise.resolve()
  }

  const validatePickupZip = async (rule: any, value: any) => {
    if (pickupAddress.zip && value.length === 5) {
      const zipResponse = await resolveAddressFromZip(value)
      if (!zipResponse) {
        return Promise.reject(new Error('Invalid Zip Code'))
      }
    }
    return Promise.resolve()
  }

  const validateDeliveryZip = async (rule: any, value: any) => {
    if (deliveryAddress.zip && value.length === 5) {
      const zipResponse = await resolveAddressFromZip(value)
      if (!zipResponse) {
        return Promise.reject(new Error('Invalid Zip Code'))
      }
    }
    return Promise.resolve()
  }

  const handleZipUpdate = async (text: string, pickup: boolean = true) => {
    quoteForm.setFieldValue(pickup ? 'pickupZip' : 'deliveryZip', text)
    globalStore.dispatch({
      type: pickup ? 'UPDATE_SHIPMENT_PICKUP_ZIP' : 'UPDATE_SHIPMENT_DELIVERY_ZIP',
      zip: text
    })
    const zipResponse = await resolveAddressFromZip(text)
    globalStore.dispatch({
      type: pickup ? 'UPDATE_SHIPMENT_PICKUP_CITY' : 'UPDATE_SHIPMENT_DELIVERY_CITY',
      city: zipResponse?.city
    })
    globalStore.dispatch({
      type: pickup ? 'UPDATE_SHIPMENT_PICKUP_STATE' : 'UPDATE_SHIPMENT_DELIVERY_STATE',
      state: zipResponse?.state
    })
    resetShipmentPrice()
  }

  const reformatPhoneNumber = (e: ChangeEvent<HTMLInputElement>, phoneField: string) => {
    const rawText = e.target.value
    if (!!rawText) {
      const formattedPhoneNumber = formatPhoneNumber(rawText)
      if (!!formattedPhoneNumber) {
        additionalForm.setFieldValue(phoneField, formattedPhoneNumber)
        additionalForm.validateFields([phoneField])
        globalStore.dispatch({
          type: phoneField === 'pickupPhone' ? 'UPDATE_SHIPMENT_PICKUP_PHONE' : 'UPDATE_SHIPMENT_DELIVERY_PHONE',
          phone: formattedPhoneNumber
        })
      }
    } else {
      globalStore.dispatch({
        type: phoneField === 'pickupPhone' ? 'UPDATE_SHIPMENT_PICKUP_PHONE' : 'UPDATE_SHIPMENT_DELIVERY_PHONE',
        phone: null
      })
    }
  }

  const resetVehicle = (index: number) => {
    quoteForm.setFieldValue(`vin${index}`, '')
    quoteForm.setFieldValue(`year${index}`, null)
    quoteForm.setFieldValue(`make${index}`, '')
    quoteForm.setFieldValue(`model${index}`, '')
    quoteForm.setFieldValue(`bodyType${index}`, null)
    quoteForm.setFieldValue(`operable${index}`, OperableType.OPERABLE)
  }

  const validateFullName = (rule: any, value: any) => {
    if (!!value && value?.trim()?.split(' ')?.length <= 1) {
      return Promise.reject(new Error('Full name is required and must contain at least 2 words.'))
    }
    return Promise.resolve()
  }

  const handleChangeVehicle = (val: CreateShipmentVehicle, idx: number) => {
    globalStore.dispatch({
      type: 'UPDATE_SHIPMENT_VEHICLE',
      idx,
      vehicle: val
    })
  }

  const handleRemoveVehicle = (index: number) => {
    if (isQuoting) {
      return
    }
    if (vehicles.length > 1) {
      globalStore.dispatch({
        type: 'UPDATE_SHIPMENT_REMOVE_VEHICLE',
        idx: index
      })
      const updatedVehicles = vehicles.filter((_, idx) => idx !== index)
      resetVehicle(vehicles.length - 1)
      updatedVehicles.forEach((vehicle, idx) => {
        quoteForm.setFieldValue(`vin${idx}`, vehicle.vin)
        quoteForm.setFieldValue(`year${idx}`, vehicle.year)
        quoteForm.setFieldValue(`make${idx}`, vehicle.make)
        quoteForm.setFieldValue(`model${idx}`, vehicle.model)
        quoteForm.setFieldValue(`bodyType${idx}`, vehicle.bodyType)
        quoteForm.setFieldValue(`operable${idx}`, vehicle.operable)
      })
    } else {
      globalStore.dispatch({
        type: 'UPDATE_SHIPMENT_RESET_VEHICLE'
      })
      resetVehicle(0)
    }
    resetShipmentPrice()
  }

  const handleClickContinue = () => {
    if (!rates?.length) {
      openMessage({
        duration: 30,
        type: EnumMessageType.ERROR,
        text1: 'Please get quote first!'
      })
      return
    }
    if (!selectedRateId) {
      openMessage({
        duration: 30,
        type: EnumMessageType.ERROR,
        text1: 'Please select a price option!'
      })
      return
    }
    additionalForm.submit()
  }

  const handleSubmit = async () => {
    setIsSaving(true)
    try {
      const res: any = await (
        await authApiAxiosWithRefresh()
      ).post(
        '/shipment/update',
        {
          shipmentId,
          vehicles,
          pickupAddress,
          pickupContact,
          deliveryAddress,
          deliveryContact,
          enclosedTrailer,
          shippingDate,
          notesToDriver,
          selectedRateId
        },
        {
          headers: { 'content-type': 'application/json' }
        }
      )
      navigateToStep({ step: EnumNewShipmentStep.Payment })
    } catch (err: any) {
      openMessage({
        type: EnumMessageType.ERROR,
        text1: err?.response?.data?.messages?.join('\n') ?? 'Error. Please try again later.',
        text2: err.message
      })
    }
    setIsSaving(false)
  }

  const renderRateCard = (item: ShipmentRate, index: number) => {
    const className = isPhoneDevice
      ? ''
      : index % 2 === 0
        ? 'new-shipment-main-info-page__shipment-card-left'
        : 'new-shipment-main-info-page__shipment-card-right'
    return (
      <Col md={12} xs={16} className={className}>
        <ShipmentRateCard
          item={item}
          isSelected={item.identity === selectedRateId}
          onClick={() => {
            globalStore.dispatch({
              type: 'UPDATE_SHIPMENT_SELECTED_RATE_ID',
              selectedRateId: item.identity
            })
          }}
        />
      </Col>
    )
  }

  const renderRateCards = () => {
    return rates?.map((item: ShipmentRate, index: number) => {
      if (isPhoneDevice) {
        return (
          <Row key={index} className="new-shipment-main-info-page__shipment-card">
            {renderRateCard(item, index)}
          </Row>
        )
      }
      if (index % 2 === 0) {
        const nextItem = rates[index + 1]
        return (
          <Row key={index} className="new-shipment-main-info-page__shipment-card">
            {renderRateCard(item, index)}
            {nextItem && (
              renderRateCard(nextItem, index + 1)
            )}
          </Row>
        )
      }
      return null
    })
  }

  const renderAdditionalForm = () => (
    <Col className="new-shipment-main-info-page__additional-form-wrapper flex-col" span={20} offset={2}>
      <Form
        className="new-shipment-main-info-page-form kuaay-form"
        onFinish={handleSubmit}
        form={additionalForm}
      >
        <Spacer className="flex-grow-1" space={48} />
        <Text className="new-shipment-main-info-page-form__label">
          Pick-up Details:
        </Text>
        <Spacer className="flex-grow-1" space={8} />

        <Row>
          <Col xs={24} md={12}>
            <Form.Item
              name="pickupName"
              className={!isPhoneDevice ? 'new-shipment-main-info-page-form__left-item' : ''}
              initialValue={
                globalStore.state.createShipment.pickupContact.name
              }
              rules={[
                { required: true, message: 'Full name is required' },
                { validator: validateFullName }
              ]}
            >
              <Input
                placeholder="Full name"
                disabled={isQuoting}
                onChange={(e) => {
                  globalStore.dispatch({
                    type: 'UPDATE_SHIPMENT_PICKUP_NAME',
                    name: e.target.value
                  })
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>

            <Form.Item
              name="pickupPhone"
              className={!isPhoneDevice ? 'new-shipment-main-info-page-form__right-item' : ''}
              initialValue={
                globalStore.state.createShipment.pickupContact.phone
              }
              rules={[
                { required: true, message: 'Phone Number is required' },
                { pattern: PHONE_NUMBER_REGEX, message: 'Incorrect Phone Number' }
              ]}
            >
              <Input
                placeholder="Phone Number"
                disabled={isQuoting}
                inputMode={'tel'}
                onChange={(e) => reformatPhoneNumber(e, 'pickupPhone')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={12}>
            <Form.Item
              name="pickupEmail"
              className={!isPhoneDevice ? 'new-shipment-main-info-page-form__left-item' : ''}
              initialValue={
                globalStore.state.createShipment.pickupContact.email
              }
              rules={[
                { required: true, message: 'Email is required' },
                { pattern: EMAIL_REGEX, message: 'Incorrect email format' }
              ]}
            >
              <Input
                placeholder="Email"
                disabled={isQuoting}
                onChange={(e) => {
                  globalStore.dispatch({
                    type: 'UPDATE_SHIPMENT_PICKUP_EMAIL',
                    email: e.target.value
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              name="pickupAddress"
              initialValue={
                globalStore.state.createShipment.pickupAddress.address
              }
              rules={[
                { required: true, message: 'Street address is required' }
              ]}
            >
              <Input
                placeholder="Street address"
                disabled={isQuoting}
                onChange={(e) => {
                  globalStore.dispatch({
                    type: 'UPDATE_SHIPMENT_PICKUP_ADDRESS',
                    address: e.target.value
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Spacer className="flex-grow-1" space={32} />
        <Text className="new-shipment-main-info-page-form__label">
          Drop-off Details:
        </Text>
        <Spacer className="flex-grow-1" space={8} />

        <Row>
          <Col xs={24} md={12}>
            <Form.Item
              name="deliveryName"
              className={!isPhoneDevice ? 'new-shipment-main-info-page-form__left-item' : ''}
              initialValue={
                globalStore.state.createShipment.deliveryContact.name
              }
              rules={[
                { required: true, message: 'Full name is required' },
                { validator: validateFullName }
              ]}
            >
              <Input
                placeholder="Full name"
                disabled={isQuoting}
                onChange={(e) => {
                  globalStore.dispatch({
                    type: 'UPDATE_SHIPMENT_DELIVERY_NAME',
                    name: e.target.value
                  })
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>

            <Form.Item
              name="deliveryPhone"
              className={!isPhoneDevice ? 'new-shipment-main-info-page-form__right-item' : ''}
              initialValue={
                globalStore.state.createShipment.deliveryContact.phone
              }
              rules={[
                { required: true, message: 'Phone Number is required' },
                { pattern: PHONE_NUMBER_REGEX, message: 'Incorrect Phone Number' }
              ]}
            >
              <Input
                placeholder="Phone Number"
                disabled={isQuoting}
                inputMode={'tel'}
                onChange={(e) => reformatPhoneNumber(e, 'deliveryPhone')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={12}>
            <Form.Item
              name="deliveryEmail"
              className={!isPhoneDevice ? 'new-shipment-main-info-page-form__left-item' : ''}
              initialValue={
                globalStore.state.createShipment.deliveryContact.email
              }
              rules={[
                { required: true, message: 'Email is required' },
                { pattern: EMAIL_REGEX, message: 'Incorrect email format' }
              ]}
            >
              <Input
                placeholder="Email"
                disabled={isQuoting}
                onChange={(e) => {
                  globalStore.dispatch({
                    type: 'UPDATE_SHIPMENT_DELIVERY_EMAIL',
                    email: e.target.value
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              name="deliveryAddress"
              initialValue={
                globalStore.state.createShipment.deliveryAddress.address
              }
              rules={[
                { required: true, message: 'Street address is required' }
              ]}
            >
              <Input
                placeholder="Street address"
                disabled={isQuoting}
                onChange={(e) => {
                  globalStore.dispatch({
                    type: 'UPDATE_SHIPMENT_DELIVERY_ADDRESS',
                    address: e.target.value
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Spacer space={16} />
        <Text className="new-shipment-main-info-page-form__label">
          Include ANY modifications or issues that would affect shipping
        </Text>
        <Spacer space={8} />
        <Row>
          <Col xs={24} sm={24}>
            <Form.Item
              name="notesToDriver"
              initialValue={
                globalStore.state.createShipment.notesToDriver
              }
            >
              <TextArea
                rows={6}
                placeholder="ex. dually, lift kits, lowering kits, body kits, flat tires, missing keys, not running, shell/no wheels, etc."
                disabled={isQuoting}
                onChange={(e) => {
                  globalStore.dispatch({
                    type: 'UPDATE_SHIPMENT_NOTES_TO_DRIVER',
                    notesToDriver: e.target.value
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Spacer space={24} />
      <Row className="d-flex flex-col align-center">
        <Button
          className="new-shipment-main-info-page-form__btn"
          type="primary"
          color="primary"
          onClick={handleClickContinue}
          disabled={isQuoting}
          loading={isSaving}
        >
          Continue
        </Button>
      </Row>
      <Spacer space={24} />
    </Col>
  )

  const renderQuoteForm = () => (
    <Col className="new-shipment-main-info-page__quote-form-wrapper flex-col" span={20} offset={2}>
      <Spacer space={12} />
      <Form
        className="new-shipment-main-info-page-form kuaay-form"
        onFinish={handleGetQuote}
        form={quoteForm}
      >
        { (
          <>
            <Spacer className="flex-grow-1" space={36} />
            <Text className="new-shipment-main-info-page-form__label">
              Main information and Quote:
            </Text>
            <Spacer className="flex-grow-1" space={8} />

            <Row>
              <Col xs={24} md={12}>
                <Form.Item
                  name="pickupZip"
                  className={!isPhoneDevice ? 'new-shipment-main-info-page-form__left-item' : ''}
                  initialValue={
                    globalStore.state.createShipment.pickupAddress.zip
                  }
                  rules={[
                    { required: true, message: 'Zip Code is required' },
                    { pattern: ZIP_CODE_REGEX, message: 'Incorrect Zip Code' },
                    { validator: validatePickupZip }
                  ]}
                  shouldUpdate={(prevValues, curValues) => prevValues.pickupZip !== curValues.pickupZip}
                >
                  <Input
                    className="new-shipment-main-info-page__form__zip-input"
                    maxLength={5}
                    placeholder="Pickup Zip"
                    inputMode={'numeric'}
                    disabled={isQuoting}
                    suffix={pickupAddress.city && pickupAddress.state && (
                      <span>
                        {pickupAddress.city}
                        ,
                        {' '}
                        {pickupAddress.state}
                      </span>
                    )}
                    onChange={(e) => handleZipUpdate(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="deliveryZip"
                  className={!isPhoneDevice ? 'new-shipment-main-info-page-form__right-item' : ''}
                  initialValue={
                    globalStore.state.createShipment.deliveryAddress.zip
                  }
                  rules={[
                    { required: true, message: 'Zip Code is required' },
                    { pattern: ZIP_CODE_REGEX, message: 'Incorrect Zip Code' },
                    { validator: validateDeliveryZip }
                  ]}
                  shouldUpdate={(prevValues, curValues) => prevValues.deliveryZip !== curValues.deliveryZip}
                >
                  <Input
                    className="new-shipment-main-info-page__form__zip-input"
                    maxLength={5}
                    placeholder="Drop off Zip"
                    inputMode={'numeric'}
                    disabled={isQuoting}
                    suffix={deliveryAddress.city && deliveryAddress.state && (
                      <span>
                        {deliveryAddress.city}
                        ,
                        {' '}
                        {deliveryAddress.state}
                      </span>
                    )}
                    onChange={(e) => handleZipUpdate(e.target.value, false)}
                  />
                </Form.Item>
              </Col>
            </Row>

            {!isPhoneDevice && (
              <Spacer className="flex-grow-1" space={20} />
            )}
            <Row>
              <Col xs={24} md={12}>
                <Form.Item
                  name="shippingDate"
                  className={!isPhoneDevice ? 'new-shipment-main-info-page-form__left-item' : ''}
                  initialValue={initShippingDateValue}
                  rules={[
                    { required: true, message: 'Shipping Date is required' },
                    { validator: validateShippingDate }
                  ]}
                  shouldUpdate={(
                    prevValues,
                    curValues
                  ) => prevValues.shippingDate !== curValues.shippingDate}
                >
                  <DatePicker
                    onChange={(d) => {
                      globalStore.dispatch({
                        type: 'UPDATE_SHIPMENT_SHIPPING_DATE',
                        shippingDate: d?.toDate()
                      })
                      resetShipmentPrice()
                    }}
                    placeholder="Shipping Date"
                    disabled={isQuoting}
                  />
                </Form.Item>
                <Text className="new-shipment-main-info-page-form__shipping-date-description">
                  This is an estimated date. Our carrier will do their best to pickup by the selected date.
                </Text>
                <Spacer className="flex-grow-1" space={12} />
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  valuePropName="checked"
                  name="enclosedTrailer"
                  initialValue={enclosedTrailer}
                  className={`new-shipment-main-info-page-form__checkbox-field ${!isPhoneDevice ? 'new-shipment-main-info-page-form__right-item' : ''}`}
                >
                  <Checkbox
                    disabled={isQuoting}
                    onChange={() => {
                      globalStore.dispatch({
                        type: 'UPDATE_SHIPMENT_ENCLOSED_TRAILER',
                        enclosedTrailer: !enclosedTrailer
                      })
                      resetShipmentPrice()
                    }}
                  >
                    Enclosed trailer
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Spacer className="flex-grow-1" space={28} />
            {vehicles.map((vehicle, idx) => (
              <div key={idx}>
                <Text className="new-shipment-main-info-page-form__label">
                  Vehicle
                  {' '}
                  {idx + 1}
                </Text>
                <CloseCircleOutlined disabled={isQuoting} className="new-shipment-main-info-page-form__remove-btn" onClick={() => handleRemoveVehicle(idx)} />
                <Spacer className="flex-grow-1" space={8} />
                <VehicleForm
                  form={quoteForm}
                  vehicleInfo={vehicle}
                  idx={idx}
                  loading={isQuoting}
                  onChange={(val) => handleChangeVehicle(val, idx)}
                />
                <Spacer className="flex-grow-1" space={8} />
              </div>
            ))}
            <Spacer className="flex-grow-1" space={24} />
            <Row>
              <Col xs={8}>
                <Button
                  className="new-shipment-main-info-page-form__add-vehicle-btn"
                  onClick={() => {
                    globalStore.dispatch({
                      type: 'UPDATE_SHIPMENT_ADD_VEHICLE'
                    })
                  }}
                  disabled={isQuoting}
                >
                  <span className="new-shipment-main-info-page-form__add-vehicle-text">+ Add Vehicle</span>
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
      <Spacer className="flex-grow-1" space={24} />
      <Row className="d-flex flex-col align-center">
        <Button
          className="new-shipment-main-info-page-form__btn"
          type="primary"
          color="primary"
          onClick={() => quoteForm.submit()}
          disabled={isSaving}
          loading={isQuoting}
        >
          Get Quote
        </Button>
      </Row>
      <Spacer className="flex-grow-1" space={32} />
      {rates && renderRateCards()}
      <Spacer className="flex-grow-1" space={24} />
      {/* {price && ( */}
      {/*  <div className="new-shipment-main-info-page__price"> */}
      {/*    <Text className="new-shipment-main-info-page__price-label"> */}
      {/*      Shipping Fee: */}
      {/*    </Text> */}
      {/*    <Text className="new-shipment-main-info-page__price-value"> */}
      {/*      $ */}
      {/*      {price.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} */}
      {/*    </Text> */}
      {/*  </div> */}
      {/* )} */}
    </Col>
  )

  return (
    <Row className="new-shipment-main-info-page">
      {
        renderQuoteForm()
      }
      {
        renderAdditionalForm()
      }
    </Row>
  )
}
