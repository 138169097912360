import axios from 'axios'

import { FuelType, VehicleBodyType } from 'src/types/transactions'

import { VINInfo } from './types'

export const determineFuelType = (
  vinPriaryFuelType: string,
  vinSecondaryFuelType: string
): FuelType => {
  switch (vinPriaryFuelType) {
  case 'Gasoline':
    if (vinSecondaryFuelType === 'Electric') {
      return 'Hybrid'
    }
    return 'Gas'
  case 'Diesel':
    return 'Diesel'
  case 'Electric':
    return 'Electric'
  case 'Propane':
    return 'Propane'
  default:
    return 'Other'
  }
}

export const determineBodyType = (
  bodyClass: string
): VehicleBodyType | undefined => {
  // SUV
  if (bodyClass.indexOf('SUV') > -1) {
    return 'SUV'
  }
  // Pickup
  if (bodyClass === 'Pickup') {
    return 'Pickup'
  }
  // Sedan
  if (bodyClass.indexOf('Sedan') > -1) {
    return 'Sedan'
  }
  // Coupe
  if (bodyClass === 'Coupe') {
    return 'Coupe'
  }
  // Convertible
  if (bodyClass.indexOf('Convertible') > -1) {
    return 'Convertible'
  }
  // Van
  if (bodyClass.indexOf('Van') > -1) {
    return 'Van'
  }
  // Hatchback
  if (bodyClass.indexOf('Hatchback') > -1) {
    return 'Hatchback'
  }
  // Wagon
  if (bodyClass.indexOf('Wagon') > -1) {
    return 'Wagon'
  }
  // If not found then just return nada
  return undefined
}

export const decodeVinWithRetry = async (text: string, vehicleYear: number | null, ignoreYear?: boolean): Promise<VINInfo | any> => {
  const url = `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvaluesextended/${
    text
  }?format=json&modelyear=${
    ignoreYear ? '' : vehicleYear
  }`
  try {
    const vinInfo = await axios.get(url)
    const vinData = vinInfo.data as VINInfo
    if (vinData.Results.length > 0 && vinData.Results[0].ErrorCode === '0') {
      return vinData.Results[0]
    }
    if (
      vinData.Results.length > 0
      && vinData.Results[0].ErrorCode.split(',').indexOf('12') > -1
    ) {
      return await decodeVinWithRetry(text, vehicleYear, true)
    }
  } catch (err) { /* empty */ }
  return null
}
