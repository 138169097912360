import React, { useEffect, useMemo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import {
  Col, Modal, Row, Typography
} from 'antd'
import { AxiosResponse } from 'axios'

import { Button } from 'src/components/Button'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { FeeInfos } from 'src/utils/types'

import { TransactionFeeDetail } from './index'
import { TransactionFeeInfoModalProps } from './types'

import './TransactionFeeInfoModal.scss'

export default function TransactionFeeInfoModal(
  {
    open,
    transaction,
    onClose
  }: TransactionFeeInfoModalProps
) {
  return (
    <Modal
      className="transaction-fee-info-modal"
      closable={false}
      open={open}
      footer={null}
    >
      <HeaderLayout
        title="Fee Information"
        subtitle="To Be Submitted"
        hasGoBack
        onGoBack={onClose}
      >
        <Row className="transaction-fee-info-modal__content">
          <TransactionFeeDetail vehicleInfo={transaction?.vehicle} />
          <Col span={20} offset={2}>
            <Row className="transaction-fee-info__actions">
              <Button type="primary" color="primary" onClick={onClose}>Done</Button>
            </Row>
          </Col>
        </Row>
      </HeaderLayout>
    </Modal>
  )
}
