import React, { useEffect, useState } from 'react'
import {
  Modal, Typography
} from 'antd'

import { BarcodeScanner } from 'src/components/BarcodeScanner'
import { Button } from 'src/components/Button'

import { BarcodeModalProps } from './Modal.types'

import './BarcodeModal.scss'

const { Text } = Typography

export default function BarcodeModal({
  title, open, onClose, handleBarcodeScanned, ...rest
}: BarcodeModalProps) {
  const [stopStream, setStopStream] = useState(false)
  const onHandleBarcodeScanned = (err: any, result: any) => {
    if (result) {
      setStopStream(true)
      setTimeout(() => handleBarcodeScanned(err, result), 1)
    }
  }
  const handleClose = async () => {
    if (onClose) {
      setStopStream(true)
      setTimeout(() => onClose(), 1)
    }
  }

  useEffect(() => {
    if (open) {
      setStopStream(false)

      // for testing only
      // handleBarcodeScanned(null, { getText: () => 'https://links.kuaay.com/link/Share/4b932c16-a111-4358-b317-1b31b0cacbfe' })
    }
  }, [open])

  return (
    <Modal className="barcode-modal" open={open} closable={false} footer={null} {...rest}>
      {title && (
        <Text className="barcode-modal__barcode-model-text">
          {title}
        </Text>
      )}
      {!stopStream && (
        <BarcodeScanner handleBarcodeScanned={onHandleBarcodeScanned} stopStream={stopStream} />
      )}
      <Button className="barcode-modal__barcode-model-cancel-btn" color="secondary" onClick={handleClose}>Cancel</Button>
    </Modal>
  )
}
