import React from 'react'
import { Modal } from 'antd'
import classNames from 'classnames'

import { HeaderLayout } from 'src/components/HeaderLayout'

import { KModalProps } from './Modal.types'

import './KModal.scss'

export default function KModal({
  title, subtitle, open, onClose, children, className, ...rest
}: KModalProps) {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }
  return (
    <Modal
      className={classNames('k-modal', className)}
      closable={false}
      open={open}
      footer={null}
      {...rest}
    >
      <HeaderLayout title={title} subtitle={subtitle} hasGoBack onGoBack={handleClose}>
        {children}
      </HeaderLayout>
    </Modal>
  )
}
