import React, { useContext, useEffect, useMemo } from 'react'
import {
  Col, Form, Row, Typography
} from 'antd'

import { Button } from 'src/components/Button'
import { Input } from 'src/components/Input'
import { KModal } from 'src/components/Modal'
import { Spacer } from 'src/components/Spacer'
import { store } from 'src/store/store'
import { NUMBER_REGEX, ODOMETER_TYPES, ODOMETER_TYPES_ABBR } from 'src/utils/constants'

import './NadaSearch.scss'

interface NadaSearchProps {
  open: boolean,
  vehicleSeries: string | undefined
  onClose: () => void
}

const { Text } = Typography

export default function NadaSearch({
  open,
  vehicleSeries,
  onClose
}: NadaSearchProps) {
  const [form] = Form.useForm()

  const globalStore = useContext(store)
  const {
    make,
    model,
    year,
    odometerReading,
    odometerUnit,
    nadaValue
  } = globalStore.state.createTransaction.vehicle

  useEffect(() => {
    if (open) {
      form.setFieldValue('nadaValue', nadaValue)
    }
  }, [nadaValue, open])

  const hintVehicleInfo = useMemo(() => [
    year?.toString() || '',
    make,
    model,
    vehicleSeries || '',
    !!odometerReading
      ? `${odometerReading} ${
        ODOMETER_TYPES_ABBR[
          ODOMETER_TYPES.indexOf(odometerUnit)
        ]
      }`
      : ''
  ].filter((x) => x.length > 0).join(' - '), [year, make, model, vehicleSeries, odometerReading, odometerUnit])

  const nadaUrl = useMemo(() => ['https://www.nadaguides.com/cars', year, make, model].join('/'), [year, make, model])

  const handleSubmit = () => {
    const nadaValue = form.getFieldValue('nadaValue')
    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_NADA_VALUE',
      nadaValue: Number(nadaValue)
    })
    onClose()
  }

  return (
    <KModal
      title="NADA Value"
      subtitle="Search"
      className="nada-search-modal"
      open={open}
      onClose={onClose}
    >
      <Row>
        <Col span={20} offset={2} className="nada-search-modal__content">
          <Spacer space={24} />
          <Text className="nada-search-modal__content__text">
            Use the link below to search for a NADA (Clean Trade In/lowest) value
            and enter it into the box.
          </Text>
          <Spacer space={16} />
          <Text className="nada-search-modal__content__hint">
            Information that may help you below:
          </Text>
          <Spacer space={8} />
          <Text className="nada-search-modal__content__hint-vehicle-info">
            {hintVehicleInfo}
          </Text>
          <Spacer space={16} />
          <a href={nadaUrl} target="_blank" rel="noreferrer">{nadaUrl}</a>
          <Spacer space={16} />
          <Form
            className="nada-search-modal__content__from"
            form={form}
            onFinish={handleSubmit}
          >
            <Form.Item
              initialValue={nadaValue}
              name="nadaValue"
              rules={[
                { required: true, message: 'NADA Value is required' },
                { pattern: NUMBER_REGEX, message: 'Inccorect number' }
              ]}
              className="nada-search-modal__content__from__nada-input"
            >
              <Input
                type="number"
                min="1"
                prefix={
                  <span>$</span>
                }
                placeholder="NADA Value"
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="nada-search-modal__content__from__done-button"
            >
              Done
            </Button>
          </Form>
        </Col>
      </Row>
    </KModal>
  )
}
