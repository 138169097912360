import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FilePdfOutlined } from '@ant-design/icons'
import type { TableProps } from 'antd'
import {
  Col, Row, Table, Tag, Typography
} from 'antd'

import { HeaderLayout } from 'src/components/HeaderLayout'
import { Spacer } from 'src/components/Spacer'
import { useDisplay } from 'src/hooks/useDisplay'
import { EnumRoute } from 'src/router/types'
import { VerificationReport } from 'src/types/identity'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { retrieveVerifPdf } from 'src/utils/identityHelpers'

import './styles.scss'

const { Title, Paragraph, Text } = Typography

  interface VerificationHistoryProps {
    orgName: string;
    dealerId: string;
    onDetailsClick: (to: string) => void;
  }

const VerificationHistoryTable: React.FC<VerificationHistoryProps> = ({ dealerId, onDetailsClick, orgName }) => {
  const [sessions, setSessions] = useState<VerificationReport[]>([])
  const [seshError, setSeshError] = useState<string | null>(null)

  const pullDealershipVerifHistory = async (dealerId: string | null) => {
    try {
      const sessions = await (await authApiAxiosWithRefresh()).get(`stripe-identity/list_verification_session/${dealerId}`)
      return sessions.data
    } catch (error) {
      setSeshError('Error retrieving session history')
      return null
    }
  }

  useEffect(() => {
    if (dealerId) {
      pullDealershipVerifHistory(dealerId).then((data: VerificationReport[]) => {
        setSessions(data)
      })
    }
  }, [dealerId])

  // catch errors in the api pull
  if (seshError) {
    return (
      <>
        <Title>Error Retrieving Verification History!</Title>
        <Paragraph>{seshError}</Paragraph>
      </>
    )
  }

    // ant design table columns and data
    interface DataType {
      key: string;
      name: string | null;
      created: number | null;
      status: string | null;
      id: string | null;

    }

    const columns: TableProps<DataType>['columns'] = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Timestamp',
        dataIndex: 'created',
        key: 'created',
        render: (created) => {
          return (
            <span>
              {created ? new Date(created * 1000).toLocaleString() : 'N/A'}
            </span>
          )
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          let color = 'volcano'
          if (status === 'verified') {
            color = 'green'
          }
          return (
            <Tag color={color}>
              {status.toUpperCase()}
            </Tag>
          )
        }
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',

        //  navigating to verification details page and downloading pdf
        render: (id) => (
          <span>
            <button
              type="button"
              className="button-no-style button-black"
              onClick={() => {
                onDetailsClick(EnumRoute.VerifyIdentityDetails.replace(':orgName', orgName).replace(':verificationId', id))
                return null
              }}
            >
              View Details
            </button>
            <span> / </span>
            <button
              type="button"
              className="button-no-style button-red"
              onClick={() => {
                const session = sessions.find((session) => session.verification_session === id)
                if (session) {
                  retrieveVerifPdf(orgName, dealerId, id).catch((error) => {
                    console.error('Error retrieving PDF')
                    alert('Error retrieving PDF')
                    return null
                  })
                } else {
                  alert('Session not found')
                  return null
                }
                return null
              }}
            >
              <FilePdfOutlined />
            </button>
          </span>
        )
      }

    ]

    const data: DataType[] = sessions.map((session) => ({
      key: session.verification_session,
      name: session.document.first_name ? `${session.document.first_name} ${session.document.last_name}` : 'N/A',
      created: session.created ? session.created : null,
      status: session.document.status ? session.document.status : 'N/A',
      id: session.verification_session ? session.verification_session : 'N/A'
    }))

    return (
      <Row className="main-verification-container" justify="center">
        <Col xs={23} sm={24} className="verification-details">
          <Table
            size="small"
            columns={columns}
            dataSource={data}
          />
        </Col>
      </Row>
    )
}

const IdentityHistoryPage: React.FC = () => {
  const { orgName } = useParams<{ orgName: string;}>()
  const nav = useNavigate()
  const { isMobileDevice } = useDisplay()
  const [dealerId, setDealerId] = useState<string | null>(null)

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await (await authApiAxiosWithRefresh()).get('user/orgData')
        setDealerId(response.data.id)
      } catch (error) {
        const err = error as Error
        console.error('Error fetching dealerId:', err)
      }
    }

    fetchOrgData()
  }, [])

  const title = 'Verification History'
  const subtitle = 'View previous verifications and download reports'
  const renderRightItems = () => {
    if (!isMobileDevice) {
      return (
        <Text>
          Dealer:
          {orgName}
        </Text>
      )
    }
    return null
  }
  return (
    <>
      <Spacer space={15} />
      <HeaderLayout
        title={title}
        hasGoBack={true}
        subtitle={subtitle}
        rightItems={renderRightItems}
        onGoBack={() => nav(-1)}
      >
        {dealerId && orgName && (
          <div>
            <VerificationHistoryTable dealerId={dealerId} onDetailsClick={nav} orgName={orgName} />
          </div>
        )}
      </HeaderLayout>
    </>
  )
}

export default IdentityHistoryPage
